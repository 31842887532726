import { Component, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { Store } from '@ngrx/store';
import * as fromAuth from './store';
import * as Action from "./store/actions";
import {SingleSignOn} from "./store/actions";

@Component({
  templateUrl: './sso.component.html',
  styleUrls: ['./sso.component.scss'],
})
export class SSOComponent {

  public pincode: string = "";

  public status: string = "info";

  public token: string = "";
  public routeSubscription : any;

  public activateStatus$= this.store.select(fromAuth.getActivateStatus);
  public activateStatus;
  public activateStatusSubscription;

  public activatePending$= this.store.select(fromAuth.getActivatePending);
  public activatePending;
  public activatePendingSubscription;

  public occupied: Array<boolean>;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private builder: FormBuilder,
              private store: Store<fromAuth.State>,
              private cdr: ChangeDetectorRef) {

    this.occupied = [false, false, false, false, false];
  }

  public ngOnInit(){
    this.activateStatusSubscription = this.activateStatus$.subscribe(status => {
      this.activateStatus = status;
    });

    this.activatePendingSubscription = this.activatePending$.subscribe(pending => {
      this.activatePending = pending;
      this.cdr.detectChanges();
    });
  }

  public ngAfterViewInit() {
    this.routeSubscription = this.route.params.subscribe(params => {
      this.token = params['key'];
      this.store.dispatch(new SingleSignOn(this.token))
    });
  }


  public ngOnDestroy() {
    this.routeSubscription.unsubscribe();
    this.activateStatusSubscription.unsubscribe();
    this.activatePendingSubscription.unsubscribe();
  }
}
