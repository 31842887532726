import {Component, OnInit, OnDestroy, Input} from '@angular/core';

@Component({
  selector: "CameraBadge",
  templateUrl: './camerabadge.component.html',
  styleUrls: ['./camerabadge.component.scss'],
})
export class CameraBadge implements OnInit, OnDestroy  {

  @Input("color") color: string;

  constructor() {}

  ngOnInit() {

  }

  ngAfterViewInit(){
  }


  ngOnDestroy(){
  }

}
