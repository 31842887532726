import * as videowall from './actions';

export interface State {
  videowall: any;
}

export const initialState: State = {
  videowall: null,
};

export function reducer(state = initialState, action: videowall.Actions): State {
  switch (action.type) {

    case videowall.GET_VIDEOWALL_SUCCESS: {
      const { videowall } = action.payload;
      return {
        ...state,
        videowall,
      };
    }

    case videowall.RESET: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}

export const getVideowallState = (state: State) => state;
export const getVideowall = (state: State) => state.videowall;
