import {Component, OnInit, OnDestroy, Input} from '@angular/core';

@Component({
  selector: "Breadcrumb",
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class Breadcrumb implements OnInit, OnDestroy  {

  @Input("activeLevel") activeLevel: string;
  @Input("icon") icon: string;
  @Input("level1") level1: string;
  @Input("level1link") level1link: string;
  @Input("level2") level2: string;
  @Input("level2link") level2link: string;
  @Input("level3") level3: string;
  @Input("level3link") level3link: string;
  @Input("level4") level4: string;
  @Input("level4link") level4link: string;

  constructor() {}

  ngOnInit() {
  }

  ngAfterViewInit(){
  }


  ngOnDestroy(){
  }
}
