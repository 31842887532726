import { map, take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '../shared/api.service';

@Injectable()
export class HomeService {

  constructor(private http: HttpClient,
              private apiService: ApiService) {}

  public getNotifications() : Observable<any> {
    return this.http.get(this.apiService.getNotifications()).pipe(take(1),map(value => {
      return value;
    }),);
  }

  public markReadNotification(notification) : Observable<any> {
    return this.http.post(this.apiService.markReadUserNotification(), {notification}).pipe(take(1),map(value => {
      return value;
    }),);
  }

  public markReadAllNotification() : Observable<any> {
    return this.http.post(this.apiService.markReadAllUserNotification(), {}).pipe(take(1),map(value => {
      return value;
    }),);
  }

  public sendFeedback(feedback) : Observable<any> {
    return this.http.post(this.apiService.sendFeedback(), {feedback}).pipe(take(1),map(value => {
      return value;
    }),);
  }

  public getReleases() : Observable<any> {
    return this.http.get(this.apiService.getReleases()).pipe(take(1),map(value => {
      return value;
    }),);
  }
}
