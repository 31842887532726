import {Component, Input} from '@angular/core';
import * as svg from "../../../shared/svg.inject";

@Component({
  selector: "SearchField",
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchField {

  @Input("action") action: any;
  @Input("placeholder") placeholder: any;
  @Input("change") change: any;
  @Input("value") value: string = "";
  @Input("disabled") disabled: boolean = false;
  
  public state = {
    'active': false
  };

  onChange() {
    this.change(this.value);
  }

  reset() {
    this.value = "";
    this.onChange();
  }

  onFocus(){
    this.state.active = true;
  }

  onFocusOut(){
    this.state.active = false;
  }
}
