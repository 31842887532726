import { Action } from '@ngrx/store';

export const RESET = '[Livestream] Reset Livestream properties';
export const REQUEST_LIVESTREAM = '[Livestream] Requesting Livestream properties';
export const REQUEST_LIVESTREAM_SUCCESS = '[Livestream] Requesting Livestream properties Success';
export const REQUEST_LIVESTREAM_FAILURE = '[Livestream] Requesting Livestream properties Failure';
export const TOGGLE_SPEECH = '[Livestream] Toggle Speech';
export const TOGGLE_SPEECH_SUCCESS = '[Livestream] Toggle Speech Success';
export const TOGGLE_SPEECH_FAILURE = '[Livestream] Toggle Speechs Failure';

export class Reset implements Action {
  readonly type = RESET;

  constructor() {}
}

export class RequestLivestream implements Action {
  readonly type = REQUEST_LIVESTREAM;

  constructor() {}
}

export class RequestLivestreamSuccess implements Action {
  readonly type = REQUEST_LIVESTREAM_SUCCESS;

  constructor(public payload: any) {}
}

export class RequestLivestreamFailure implements Action {
  readonly type = REQUEST_LIVESTREAM_FAILURE;

  constructor(public payload?: any) {}
}

export class ToggleSpeech implements Action {
  readonly type = TOGGLE_SPEECH;

  constructor(public payload: any) {}
}

export class ToggleSpeechSuccess implements Action {
  readonly type = TOGGLE_SPEECH_SUCCESS;

  constructor(public payload: any) {}
}

export class ToggleSpeechFailure implements Action {
  readonly type = TOGGLE_SPEECH_FAILURE;

  constructor(public payload?: any) {}
}

export type Actions =
  | Reset
  | RequestLivestream
  | RequestLivestreamSuccess
  | RequestLivestreamFailure
  | ToggleSpeech
  | ToggleSpeechSuccess
  | ToggleSpeechFailure;
