import {Component, Input} from '@angular/core';

@Component({
  selector: "Placeholder",
  templateUrl: './placeholder.component.html',
  styleUrls: ['./placeholder.component.scss'],
})
export class Placeholder {

  @Input() title: string;
  @Input() description: string;
  @Input() icon: string = "";
  @Input() button: string = "";
  @Input() action: any;
  @Input() error: boolean = false;

  callAction(){
    if(this.action) {
      this.action();
    }
  }
}
