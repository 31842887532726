import { Action } from '@ngrx/store';

export const RESET = '[IOs] Reset';
export const RESET_MESSAGE = '[IOs] Hide success';
export const REQUEST_IOS = '[IOs] Requesting ios';
export const REQUEST_IOS_SUCCESS = '[IOs] Requesting ios Success';
export const REQUEST_IOS_FAILURE = '[IOs] Requesting ios Failure';
export const FILTER_IOS = '[IOs] Filter ios';
export const FILTER_IOS_SUCCESS = '[IOs] Filter ios Success';
export const FILTER_IOS_FAILURE = '[IOs] Filter ios Failure';
export const DELETE_IO = '[IOs] Delete io';
export const DELETE_IO_SUCCESS = '[IOs] Delete io Success';
export const DELETE_IO_FAILURE = '[IOs] Delete io Failure';

export class Reset implements Action {
  readonly type = RESET;

  constructor() {}
}

export class ResetMessage implements Action {
  readonly type = RESET_MESSAGE;

  constructor() {}
}

export class RequestIOs implements Action {
  readonly type = REQUEST_IOS;

  constructor(public payload: any) {}
}

export class RequestIOsSuccess implements Action {
  readonly type = REQUEST_IOS_SUCCESS;

  constructor(public payload: any) {}
}

export class RequestIOsFailure implements Action {
  readonly type = REQUEST_IOS_FAILURE;

  constructor(public payload?: any) {}
}

export class FilterIOs implements Action {
  readonly type = FILTER_IOS;

  constructor(public payload: any) {}
}

export class FilterIOsSuccess implements Action {
  readonly type = FILTER_IOS_SUCCESS;

  constructor(public payload: any) {}
}

export class FilterIOsFailure implements Action {
  readonly type = FILTER_IOS_FAILURE;

  constructor(public payload?: any) {}
}

export class DeleteIO implements Action {
  readonly type = DELETE_IO;

  constructor(public payload: any) {}
}

export class DeleteIOSuccess implements Action {
  readonly type = DELETE_IO_SUCCESS;

  constructor(public payload: any) {}
}

export class DeleteIOFailure implements Action {
  readonly type = DELETE_IO_FAILURE;

  constructor(public payload?: any) {}
}

export type Actions =
  | Reset
  | ResetMessage
  | RequestIOs
  | RequestIOsSuccess
  | RequestIOsFailure
  | FilterIOs
  | FilterIOsSuccess
  | FilterIOsFailure
  | DeleteIO
  | DeleteIOSuccess
  | DeleteIOFailure;
