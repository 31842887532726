import {Component, Inject, ViewChild, } from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {environment, kerberosConfig} from '../../environments/environment';
import {Store} from '@ngrx/store';
import {Authenticate} from './models/user';
import {MqttService as NgxMqtt} from 'ngx-mqtt';
import {MqttService} from '../shared/mqtt.service';
import * as SubscriptionInfo from '../shared/subscription.info';
import * as fromVideowall from './store';
import * as Videowall from './store/actions';
import * as fromSubscription from '../home/subscription/store';
import * as Devices from '../home/devices/store/actions';
import {HttpClient} from '@angular/common/http';

@Component({
  templateUrl: './wall.component.html',
  styleUrls: ['./wall.component.scss'],
})
export class WallComponent {


  @ViewChild('passcode', {static: false}) passcode;

  public config: any = null;
  public passcodeForm: FormGroup;
  public subscription$ = this.store.select(fromSubscription.getSubscription);
  public subscriptionSubscription: any;
  public credentials: any;
  public plan: string = "";
  public cloudKey: string = "";
  public timeout;
  public levels = SubscriptionInfo.Levels;

  public wallFingerprint;
  public routeSubscription;

  public numbers = Array(10).fill(0).map((x,i)=>i+1); // for 1 to 10
  public rows = 5; // default number of rows
  public columns = 5; // default number of columns

  public cameras: any = [];
  public camerasFilteredBySearch: any = [];
  public user: string = "";
  public profile: any = null;
  public generalObservable;
  public isProtected: boolean = true;
  public decryptionStatus: string = "wrong-passphrase";
  public searchValue: string = "";
  public instances: Array<any> = [];
  public instancesFiltered: Array<any> = [];
  public selectedInstances = [];
  public selectedSites = [];
  public sites: Array<any> = [];

  public columnsList = [
    {
      text: "1 column",
      value: 1
    },
    {
      text: "2 columns",
      value: 2
    },
    {
      text: "3 columns",
      value: 3
    },
    {
      text: "4 columns",
      value: 4
    },
    {
      text: "5 columns",
      value: 5
    }
  ]

  public rowsList = [
    {
      text: "1 row",
      value: 1
    },
    {
      text: "2 rows",
      value: 2
    },
    {
      text: "3 rows",
      value: 3
    },
    {
      text: "4 rows",
      value: 4
    },
    {
      text: "5 rows",
      value: 5
    }
  ]


  public videowall$ = this.store.select(fromVideowall.getVideowall);
  public videowallSubscription: any;
  public videowall: any = null;

  constructor(
              @Inject('mqttNew') private _mqttService: NgxMqtt,
              private mqttService: MqttService,
              private route: ActivatedRoute,
              private store: Store<fromVideowall.State>,
              private fb: FormBuilder) {

    this.config = kerberosConfig;
    this.passcodeForm = this.fb.group({
      passcode: new FormControl('', Validators.required)
    });

    this.onChangeColumns = this.onChangeColumns.bind(this);
    this.onChangeRows = this.onChangeRows.bind(this);
    this.verifyFingerprint = this.verifyFingerprint.bind(this);
    this.verifyPasscode = this.verifyPasscode.bind(this);
    this.search = this.search.bind(this);
    this.onChangeSites = this.onChangeSites.bind(this);
    this.onChangeCameras = this.onChangeCameras.bind(this);

    this.routeSubscription = this.route.params.subscribe(params => {
      this.wallFingerprint = params['id'];
      if(this.wallFingerprint != "") {

				// We will dissassemble the fingerprint
				// [A,B,C..] version
				// [0 or 1] if passcode is set
				// [videowall Id = 24chars] the id of the videowall
				// [encrypted fingerprint] rsa encrypted passphrase

        const fingerprint = this.wallFingerprint;
        const version = fingerprint[0];
        const passcode = fingerprint[1];
        const id = fingerprint.substring(2, 26);
        const encrypted = fingerprint.substring(26);

        if(passcode === "1") {
          this.isProtected = true;
        } else {
          this.isProtected = false;
          this.store.dispatch(new Videowall.GetVideowall({
            fingerprint,
          }));
        }
      }
    });

    this.videowallSubscription = this.videowall$.subscribe((videowall) => {
      if(videowall) {
        this.videowall = videowall;
        this.plan = videowall.plan;
        this.credentials = videowall.public_key;
        this.cloudKey = videowall.public_key;
        this.profile = {
          user: {
            amazon_secret_access_key: videowall.private_key,
          }
        };

        this.cameras = videowall.cameras;
        // We might override the cameras permissions with the videowall permissions.
        if(videowall.ptz > 0) {
          this.cameras = this.cameras.map(c => {
            return {
              ...c,
             featurePermissions: {
                ...c.featurePermissions,
                ptz: videowall.ptz
             }
            }
          });
        }
        if(videowall.liveview > 0) {
          this.cameras = this.cameras.map(c => {
            return {
              ...c,
             featurePermissions: {
                ...c.featurePermissions,
                liveview: videowall.liveview
             }
            }
          });
        }
        if(videowall.io > 0) {
          this.cameras = this.cameras.map(c => {
            return {
              ...c,
             featurePermissions: {
                ...c.featurePermissions,
                io: videowall.io
             }
            }
          });
        }

        this.search();
        this.startMqttListener();

        // We'll parse the cameras value, to show up in the multi select.
        this.instances = this.cameras.map(c => {
          const analytics = c.analytics[0];
          return {
            value: c.key,
            text: analytics.cameraname,
            version: analytics.version,
            icon: "camera"
          }
       });

       // This will redo the filtering based on the current
       // selected sites.
       this.instancesFiltered = this.instances;

       // Setting the sites

      if (videowall.sites){
        this.sites = videowall.sites.map(s => {
          return {
            value: s.id,
            text: s.name,
            details: s,
            icon: "sites",
          }
        });
      } else {
        this.sites = [];
      }
    }
    });
  }

  ngAfterViewInit() {
    // We will focus in the passphrase field.
    setTimeout(() => {
      if(this.passcode && this.passcode.nativeElement) {
        this.passcode.nativeElement.focus();
      }
    }, 250);
  }

  verifyFingerprint() {
    const pass_code = this.passcodeForm.value.passcode;
    if(!this.passcodeForm.invalid) {
      this.store.dispatch(new Videowall.GetVideowall({
        fingerprint: this.wallFingerprint,
        pass_code,
      }));
    }
  }

  verifyPasscode() {
    this.verifyFingerprint();
  }

  search(value = "") {
    this.searchValue = value;
    this.camerasFilteredBySearch = this.cameras.filter(x => {
      const { cameraname } = x.analytics[0]
      return !this.searchValue || this.searchValue === "" || cameraname.includes(this.searchValue) || cameraname.toLowerCase().includes(this.searchValue.toLowerCase())
    });
  }

  onChangeSites(sites){
    this.selectedSites = sites;
    this.instancesFiltered = this.instances;
    if (this.selectedSites.length == 0){
      this.selectedInstances = [];
    }
    const filteredSites = this.sites.filter(s => this.selectedSites.indexOf(s.value) > -1);
    if(filteredSites && filteredSites.length) {
      const instancesFiltered = filteredSites.reduce((acc, s) => {
        return [...acc, ...s.details.all_devices]
      }, []);
      this.selectedInstances = this.instances.filter(i => instancesFiltered.indexOf(i.value) > -1).map(i => i.value);
    }
  }

  onChangeCameras(cameras){
    this.selectedInstances = cameras;

    this.camerasFilteredBySearch = this.cameras.filter(x => {
      const { cameraname } = x.analytics[0]
      return !this.searchValue || this.searchValue === "" || cameraname.includes(this.searchValue) || cameraname.toLowerCase().includes(this.searchValue.toLowerCase())
    });

    if (this.selectedInstances.length > 0){
      this.camerasFilteredBySearch = this.camerasFilteredBySearch.filter(x => this.selectedInstances.indexOf(x.key) > -1);
    }
  }

  startMqttListener() {
    const protocol: 'wss' | 'ws' = <'wss' | 'ws'> kerberosConfig.mqttProtocol;
    // Connect to new MQTT
    this._mqttService.connect({
      protocol,
      hostname:  kerberosConfig.mqttServer,
      port:  kerberosConfig.mqttPort,
      username: kerberosConfig.mqttUsername,
      password: kerberosConfig.mqttPassword,
      clientId: this.cloudKey + this.makeid(5),
      path: '/mqtt'
    });


    // We'll move forward with a single subscription for everything.
    // Use the internal mqtt service to subscribe to general topics.
    const generalSubscription = this.mqttService.subscribe('kerberos/hub/' + this.cloudKey, (message) => {
      // We might want to consolidate all messages
      //this.store.dispatch(new Home.AddMessage(message));
      // Depending on the message we might do other things as well..
      const payload = message.payload;

      // When we receive a motion request, we'll show a notification and blink the title.
      // On top of that we'll mark the required camera as "alert notified".
      if(payload.action === "receive-hd-candidates") {
        this.store.dispatch(new Devices.ReceiveEvent(payload));
      } else if(payload.action === "receive-hd-answer") {
        this.store.dispatch(new Devices.ReceiveEvent(payload));
      } else if(payload.action === "receive-sd-stream") {
        this.store.dispatch(new Devices.ReceiveEvent(payload));
      } else if(payload.action === "receive-config") {
        const { value } = payload;
        this.store.dispatch(new Devices.LoadConfig(value));
      } else if(payload.action === "acknowledge-update-config") {
        // We'll delay it a bit..
        setTimeout(() => {
          this.store.dispatch(new Devices.UpdateConfigSuccess());
        }, 1000);
      }
    });
    this.generalObservable = generalSubscription;
  }

  stopMqttListener() {
    if(this._mqttService) {
      this._mqttService.disconnect();
    }
  }

  makeid(length) {
    let result           = [];
    let characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let charactersLength = characters.length;
    for ( let i = 0; i < length; i++ ) {
      result.push(characters.charAt(Math.floor(Math.random() *
        charactersLength)));
    }
    return result.join('');
  }

  generateGrid(num: number): string {
    return `repeat(${num}, 1fr)`;
  }

  public onChangeColumns(cols){
    this.columns = cols;
  }

  public onChangeRows(rows){
    this.rows = rows;
  }

  private Trim(str, ch) {
    var start = 0,
        end = str.length;
    while(start < end && str[start] === ch)
        ++start;
    while(end > start && str[end - 1] === ch)
        --end;
    return (start > 0 || end < str.length) ? str.substring(start, end) : str;
  }

  public ngOnDestroy() {
    this.routeSubscription.unsubscribe();
    this.subscriptionSubscription.unsubscribe();
    this.videowallSubscription.unsubscribe();
    if(this.generalObservable) {
      this.generalObservable.unsubscribe();
    }
  }
}
