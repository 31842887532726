import {Component, OnInit, OnDestroy, Input} from '@angular/core';

@Component({
  selector: "Badge",
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.scss'],
})
export class Badge implements OnInit, OnDestroy  {

  @Input() type: string;
  @Input() title: string;
  @Input() number: number;
  @Input() divider: number;
  @Input() mobile: boolean = false;
  
  constructor() {}

  ngOnInit(){

  }

  ngAfterViewInit(){
  }


  ngOnDestroy(){
  }

}
