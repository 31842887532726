import {Component, Input} from '@angular/core';
import * as SubscriptionInfo from "../../subscription.info";

@Component({
  selector: "DevicesList",
  templateUrl: './devices-list.component.html',
  styleUrls: ['./devices-list.component.scss'],
})
export class DevicesList  {

  @Input() devices: any;
  @Input() cloudKey: any;
  @Input() plan: any;
  @Input() user: any;
  @Input() removeDevice: any;
  @Input() hideSites: boolean = false;
  @Input() layout: string = 'list';
  public levels = SubscriptionInfo.Levels;
  public showStream: boolean = false;
}
