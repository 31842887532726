import { createSelector, createFeatureSelector } from '@ngrx/store';
import * as fromRoot from '../../app.reducer';
import * as fromVideowall from './videowall';
export * from './effects';

export interface VideowallState {
  wall: fromVideowall.State;
}

export interface State extends fromRoot.State {
  wall: VideowallState;
}

export const Reducers = {
  wall: fromVideowall.reducer,
};

export const selectState = createFeatureSelector<VideowallState>('wall');
export const selectVideowallState = createSelector(selectState, (state: VideowallState) => state.wall);
export const getVideowall = createSelector(selectVideowallState, fromVideowall.getVideowall);
