import { Action } from '@ngrx/store';

export const RESET = '[Groups] Reset';
export const REQUEST_GROUPS = '[Groups] Requesting groups';
export const REQUEST_GROUPS_SUCCESS = '[Groups] Requesting groups Success';
export const REQUEST_GROUPS_FAILURE = '[Groups] Requesting groups Failure';
export const FIND_GROUP = '[Groups] Find group';
export const FIND_GROUP_SUCCESS = '[Groups] Find group Success';
export const FIND_GROUP_FAILURE = '[Groups] Find group Failure';
export const FIND_GROUP_DEVICES = '[Groups] Find devices of group';
export const FIND_GROUP_DEVICES_SUCCESS = '[Groups] Find devices of group Success';
export const FIND_GROUP_DEVICES_FAILURE = '[Groups] Find devices of group Failure';
export const ADD_GROUP = '[Groups] Add Group';
export const ADD_GROUP_SUCCESS = '[Groups] Add group Success';
export const ADD_GROUP_FAILURE = '[Groups] Add group Failure';
export const EDIT_GROUP = '[Groups] Edit group';
export const EDIT_GROUP_SUCCESS = '[Groups] Edit group Success';
export const EDIT_GROUP_FAILURE = '[Groups] Edit group Failure';
export const DELETE_GROUP = '[Groups] Delete group';
export const DELETE_GROUP_SUCCESS = '[Groups] Delete group Success';
export const DELETE_GROUP_FAILURE = '[Groups] Delete group Failure';
export const ASSIGN_DEVICES = '[Groups] Assign devices';
export const ASSIGN_DEVICES_SUCCESS = '[Groups] Assign devices Success';
export const ASSIGN_DEVICES_FAILURE = '[Groups] Assign devices Failure';

export class Reset implements Action {
  readonly type = RESET;

  constructor() {}
}

export class RequestGroups implements Action {
  readonly type = REQUEST_GROUPS;

  constructor() {}
}

export class RequestGroupsSuccess implements Action {
  readonly type = REQUEST_GROUPS_SUCCESS;

  constructor(public payload: any) {}
}

export class RequestGroupsFailure implements Action {
  readonly type = REQUEST_GROUPS_FAILURE;

  constructor(public payload?: any) {}
}

export class FindGroup implements Action {
  readonly type = FIND_GROUP;

  constructor(public payload: any) {}
}

export class FindGroupSuccess implements Action {
  readonly type = FIND_GROUP_SUCCESS;

  constructor(public payload: any) {}
}

export class FindGroupFailure implements Action {
  readonly type = FIND_GROUP_FAILURE;

  constructor(public payload?: any) {}
}

export class FindDevicesOfGroup implements Action {
  readonly type = FIND_GROUP_DEVICES;

  constructor(public payload: any) {}
}

export class FindDevicesOfGroupSuccess implements Action {
  readonly type = FIND_GROUP_DEVICES_SUCCESS;

  constructor(public payload: any) {}
}

export class FindDevicesOfGroupFailure implements Action {
  readonly type = FIND_GROUP_DEVICES_FAILURE;

  constructor(public payload?: any) {}
}

export class AddGroup implements Action {
  readonly type = ADD_GROUP;

  constructor(public payload: any) {}
}

export class AddGroupSuccess implements Action {
  readonly type = ADD_GROUP_SUCCESS;

  constructor(public payload: any) {}
}

export class AddGroupFailure implements Action {
  readonly type = ADD_GROUP_FAILURE;

  constructor(public payload?: any) {}
}

export class EditGroup implements Action {
  readonly type = EDIT_GROUP;

  constructor(public id: any, public payload: any) {}
}

export class EditGroupSuccess implements Action {
  readonly type = EDIT_GROUP_SUCCESS;

  constructor(public id: any, public payload: any) {}
}

export class EditGroupFailure implements Action {
  readonly type = EDIT_GROUP_FAILURE;

  constructor(public payload?: any) {}
}

export class DeleteGroup implements Action {
  readonly type = DELETE_GROUP;

  constructor(public id: any) {}
}

export class DeleteGroupSuccess implements Action {
  readonly type = DELETE_GROUP_SUCCESS;

  constructor(public payload: any) {}
}

export class DeleteGroupFailure implements Action {
  readonly type = DELETE_GROUP_FAILURE;

  constructor(public payload?: any) {}
}

export class AssignDevices implements Action {
  readonly type = ASSIGN_DEVICES;

  constructor(public id: any, public payload: any) {}
}

export class AssignDevicesSuccess implements Action {
  readonly type = ASSIGN_DEVICES_SUCCESS;

  constructor(public payload: any) {}
}

export class AssignDevicesFailure implements Action {
  readonly type = ASSIGN_DEVICES_FAILURE;

  constructor(public payload?: any) {}
}

export type Actions =
  | Reset
  | RequestGroups
  | RequestGroupsSuccess
  | RequestGroupsFailure
  | FindGroup
  | FindGroupSuccess
  | FindGroupFailure
  | FindDevicesOfGroup
  | FindDevicesOfGroupSuccess
  | FindDevicesOfGroupFailure
  | AddGroup
  | AddGroupSuccess
  | AddGroupFailure
  | EditGroup
  | EditGroupSuccess
  | EditGroupFailure
  | DeleteGroup
  | DeleteGroupSuccess
  | DeleteGroupFailure
  | AssignDevices
  | AssignDevicesSuccess
  | AssignDevicesFailure;
