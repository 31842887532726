import { createSelector, createFeatureSelector } from '@ngrx/store';
import * as fromRoot from '../../../app.reducer';
import * as fromNotifications from './notifications';

export * from './effects';

export interface NotificationsState {
  notifications: fromNotifications.State;
}

export interface State extends fromRoot.State {
  notifications: NotificationsState;
}
export const Reducers = {
  notifications: fromNotifications.reducer
};

export const selectNotificationsState = createFeatureSelector<NotificationsState>('notifications');
export const selectNotificationsNotificationsState = createSelector(selectNotificationsState, (state: NotificationsState) => state.notifications);
export const getNotifications = createSelector(selectNotificationsNotificationsState, fromNotifications.getNotifications);
export const getDetections = createSelector(selectNotificationsNotificationsState, fromNotifications.getDetections);
export const getDevices = createSelector(selectNotificationsNotificationsState, fromNotifications.getDevices);
export const getHighUpload = createSelector(selectNotificationsNotificationsState, fromNotifications.getHighUpload);
export const getNotificationsUpdating = createSelector(selectNotificationsNotificationsState, fromNotifications.getNotificationsUpdating);
export const getCustomAlerts = createSelector(selectNotificationsNotificationsState, fromNotifications.getCustomAlerts);
