import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'pagenotfound',
  templateUrl: './pagenotfound.component.html',
})
export class PageNotFoundComponent {
  constructor(private router: Router){
    // Navigate to route
    this.router.navigate(['/']); // replace '/desired-route' with your actual route
  }
}
