import { Action } from '@ngrx/store';

export const REQUEST_NOTIFICATIONS_SETTINGS = '[Notifications] Request notifications settings';
export const REQUEST_NOTIFICATIONS_SETTINGS_SUCCESS = '[Notifications] Request notifications settings Success';
export const REQUEST_NOTIFICATIONS_SETTINGS_FAILURE = '[Notifications] Request notifications settings Failure';
export const UPDATE_NOTIFICATIONS_SETTINGS = '[Notifications] Update notifications settings';
export const UPDATE_NOTIFICATIONS_SETTINGS_SUCCESS = '[Notifications] Update notifications settings Success';
export const UPDATE_NOTIFICATIONS_SETTINGS_FAILURE = '[Notifications] Update notifications settings Failure';
export const REQUEST_CUSTOMALERT = '[Notifications] Request custom alert';
export const REQUEST_CUSTOMALERT_SUCCESS = '[Notifications] Request custom alert Success';
export const REQUEST_CUSTOMALERT_FAILURE = '[Notifications] Request custom alert Failure';
export const CREATE_CUSTOMALERT = '[Notifications] Create custom alert';
export const CREATE_CUSTOMALERT_SUCCESS = '[Notifications] Create custom alert Success';
export const CREATE_CUSTOMALERT_FAILURE = '[Notifications] Create custom alert Failure';
export const UPDATE_CUSTOMALERT = '[Notifications] Update custom alert';
export const UPDATE_CUSTOMALERT_SUCCESS = '[Notifications] Update custom alert Success';
export const UPDATE_CUSTOMALERT_FAILURE = '[Notifications] Update custom alert Failure';
export const REMOVE_CUSTOMALERT = '[Notifications] Remove custom alert';
export const REMOVE_CUSTOMALERT_SUCCESS = '[Notifications] Remove custom alert Success';
export const REMOVE_CUSTOMALERT_FAILURE = '[Notifications] Remove custom alert Failure';
export const TOGGLE_CUSTOMALERT = '[Notifications] Toggle custom alert';
export const TOGGLE_CUSTOMALERT_SUCCESS = '[Notifications] Toggle custom alert Success';
export const TOGGLE_CUSTOMALERT_FAILURE = '[Notifications] Toggle custom alert Failure';
export const RESET = '[Notifications] Reset';

export class RequestNotificationsSettings implements Action {
  readonly type = REQUEST_NOTIFICATIONS_SETTINGS;

  constructor() {}
}

export class RequestNotificationsSettingsSuccess implements Action {
  readonly type = REQUEST_NOTIFICATIONS_SETTINGS_SUCCESS;

  constructor(public payload: any) {}
}

export class RequestNotificationsSettingsFailure implements Action {
  readonly type = REQUEST_NOTIFICATIONS_SETTINGS_FAILURE;

  constructor(public payload?: any) {}
}

export class UpdateNotificationsSettings implements Action {
  readonly type = UPDATE_NOTIFICATIONS_SETTINGS;

  constructor(public payload: any) {}
}

export class UpdateNotificationsSettingsSuccess implements Action {
  readonly type = UPDATE_NOTIFICATIONS_SETTINGS_SUCCESS;

  constructor(public payload: any) {}
}

export class UpdateNotificationsSettingsFailure implements Action {
  readonly type = UPDATE_NOTIFICATIONS_SETTINGS_FAILURE;

  constructor(public payload?: any) {}
}

export class RequestCustomAlert implements Action {
  readonly type = REQUEST_CUSTOMALERT;

  constructor() {}
}

export class RequestCustomAlertSuccess implements Action {
  readonly type = REQUEST_CUSTOMALERT_SUCCESS;

  constructor(public payload: any) {}
}

export class RequestCustomAlertFailure implements Action {
  readonly type = REQUEST_CUSTOMALERT_FAILURE;

  constructor(public payload?: any) {}
}


export class CreateCustomAlert implements Action {
  readonly type = CREATE_CUSTOMALERT;

  constructor(public payload: any) {}
}

export class CreateCustomAlertSuccess implements Action {
  readonly type = CREATE_CUSTOMALERT_SUCCESS;

  constructor(public payload: any) {}
}

export class CreateCustomAlertFailure implements Action {
  readonly type = CREATE_CUSTOMALERT_FAILURE;

  constructor(public payload?: any) {}
}

export class UpdateCustomAlert implements Action {
  readonly type = UPDATE_CUSTOMALERT;

  constructor(public payload: any, public alertId: string) {}
}

export class UpdateCustomAlertSuccess implements Action {
  readonly type = UPDATE_CUSTOMALERT_SUCCESS;

  constructor(public payload: any) {}
}

export class UpdateCustomAlertFailure implements Action {
  readonly type = UPDATE_CUSTOMALERT_FAILURE;

  constructor(public payload?: any) {}
}

export class RemoveCustomAlert implements Action {
  readonly type = REMOVE_CUSTOMALERT;

  constructor(public alertId: string) {}
}

export class RemoveCustomAlertSuccess implements Action {
  readonly type = REMOVE_CUSTOMALERT_SUCCESS;

  constructor(public payload: any) {}
}

export class RemoveCustomAlertFailure implements Action {
  readonly type = REMOVE_CUSTOMALERT_FAILURE;

  constructor(public payload?: any) {}
}
export class ToggleCustomAlert implements Action {
  readonly type = TOGGLE_CUSTOMALERT;

  constructor(public alertId: string, public enabled: any) {}
}

export class ToggleCustomAlertSuccess implements Action {
  readonly type = TOGGLE_CUSTOMALERT_SUCCESS;

  constructor(public payload: any) {}
}

export class ToggleCustomAlertFailure implements Action {
  readonly type = TOGGLE_CUSTOMALERT_FAILURE;

  constructor(public payload?: any) {}
}

export class Reset implements Action {
  readonly type = RESET;

  constructor() {}
}

export type Actions =
  | RequestNotificationsSettings
  | RequestNotificationsSettingsSuccess
  | RequestNotificationsSettingsFailure
  | UpdateNotificationsSettings
  | UpdateNotificationsSettingsSuccess
  | UpdateNotificationsSettingsFailure
  | RequestCustomAlert
  | RequestCustomAlertSuccess
  | RequestCustomAlertFailure
  | CreateCustomAlert
  | CreateCustomAlertSuccess
  | CreateCustomAlertFailure
  | UpdateCustomAlert
  | UpdateCustomAlertSuccess
  | UpdateCustomAlertFailure
  | RemoveCustomAlert
  | RemoveCustomAlertSuccess
  | RemoveCustomAlertFailure
  | ToggleCustomAlert
  | ToggleCustomAlertSuccess
  | ToggleCustomAlertFailure
  | Reset;
