import { createSelector, createFeatureSelector } from '@ngrx/store';
import * as fromRoot from '../../../app.reducer';
import * as fromSubscription from './subscription';

export * from './effects';

export interface SubscriptionState {
  subscription: fromSubscription.State;
}

export interface State extends fromRoot.State {
  subscription: SubscriptionState;
}
export const Reducers = {
  subscription: fromSubscription.reducer
};

export const selectSubscriptionState = createFeatureSelector<SubscriptionState>('subscription');
export const selectSubscriptionSubscriptionState = createSelector(selectSubscriptionState, (state: SubscriptionState) => state.subscription);
export const getSubscription = createSelector(selectSubscriptionSubscriptionState, fromSubscription.getSubscription);
export const getPlan = createSelector(selectSubscriptionSubscriptionState, fromSubscription.getPlan);
export const getActivity = createSelector(selectSubscriptionSubscriptionState, fromSubscription.getActivity);
export const getSettings = createSelector(selectSubscriptionSubscriptionState, fromSubscription.getSettings);
export const getCredentials = createSelector(selectSubscriptionSubscriptionState, fromSubscription.getCredentials);
