import { Component, OnInit, Input } from '@angular/core';
import { kerberosConfig } from '../../../../environments/environment';

@Component({
  selector: 'sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  @Input() public closeMenu: Function;

  public logoName;

  constructor() {
    this.logoName = kerberosConfig.logo;
  }

  ngOnInit() {}
}
