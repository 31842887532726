import {Component, OnInit, OnDestroy, Input} from '@angular/core';

@Component({
  selector: "BlockHeader",
  templateUrl: './block-header.component.html',
  styleUrls: ['./block-header.component.scss'],
})
export class BlockHeader implements OnInit, OnDestroy  {

  ngOnInit() {
  }

  ngAfterViewInit(){
  }


  ngOnDestroy(){
  }
}
