export * from './mixpanel.config';
export * from './posthog.config';
export * from './stripe.config';
export * from './sentry.config';
export * from './kerberos.config';
export * from './googlemaps.config';
export * from './zendesk.config';
export const environment = {
  production: (window["env"]["production"] === "true") ? true : false,
  demo: (window["env"]["demo"] === "true") ? true : false
};
