import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KeysPipe } from './keys.pipe';

@NgModule({
  imports: [ CommonModule ],
  declarations: [ KeysPipe ],
  exports: [ KeysPipe ]
})
export class PipeModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: PipeRootModule,
      providers: [ ],
    };
  }
}

@NgModule({
  imports: [
    PipeModule
  ],
})
export class PipeRootModule {}
