import {of as observableOf,  Observable } from 'rxjs';
import {catchError, map, switchMap, withLatestFrom} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import * as Action from '../store/actions';
import { DayService } from '../day.service';
import * as fromDay from './index';
import * as _ from 'lodash';

@Injectable()
export class Effects {

    /* Get a day */
    @Effect()
    getDays: Observable<Action.GetDaysFailure | Action.GetDaysSuccess> = this.actions$.pipe(
        ofType(Action.GET_DAYS),
        switchMap((action: Action.GetDays) => {
            const filter = action.payload;
            return this.dayService.getHLS(filter).pipe(
            map((value) => {
                return new Action.GetDaysSuccess(value.data);
            }),
            catchError((error) => {
                return observableOf(new Action.GetDaysFailure(error));
            })
            );
        })
    );

    
    constructor(private dayService: DayService,
                private store: Store<fromDay.State>,
                private actions$: Actions,) {}
}
