import {
  Component,
  ElementRef, HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: "RegionSelect",
  templateUrl: './regionselect.component.html',
  styleUrls: ['./regionselect.component.scss'],
})
export class RegionSelect implements OnInit, OnDestroy, OnChanges  {

  @Input() icon: string;
  @Input() text: string;
  @Input() cloudKey: string;
  @Input() error: boolean = false;
  @Input() disabled: boolean = false;
  @Input() items: any; // [{text: ..., value: ...}, ...]
  @Input() selected: any = []; // [{text: ..., value: ...}, ...]
  @Input() placeholder: string = "Search..";
  @Input() onChange: any;
  public open: boolean = false;
  public search: string = "";
  public filteredItems: any[] = [];
  public selectedItems: any[] = [];
  public regions = [];

  constructor(private eRef: ElementRef) {
    this.toggleDropdown = this.toggleDropdown.bind(this);
    this.onAddRegion = this.onAddRegion.bind(this);
    this.onDeleteRegion = this.onDeleteRegion.bind(this);
    this.onUpdateRegion = this.onUpdateRegion.bind(this);
  }

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if(!this.eRef.nativeElement.contains(event.target)) {
      this.open = false;
    }
  }

  ngOnInit(){
  }

  ngAfterViewInit(){
  }

  ngOnDestroy(){
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.items && changes.items.currentValue){
      this.filteredItems = changes.items.currentValue;
    }
    if(changes.selected && changes.selected.currentValue) {
      this.selectedItems = changes.selected.currentValue;
    }
  }

  toggleDropdown(){
    this.open = !this.open;
  }

  identify(index, item){
    return item.value;
  }

  onAddRegion(type, device, regionId, region, width, height){
    if(!this.selectedItems) this.selectedItems = [];

    const coordinates = region.regionPoints;

    if(this.selectedItems.findIndex(item => item.id == regionId) == -1){
      this.selectedItems.push({
        id: regionId,
        device,
        width,
        height,
        regionPoints: coordinates
      });
    } else {
      const index = this.selectedItems.findIndex(item => item.id == regionId);
      this.selectedItems[index].push({
        id: regionId,
        device,
        width,
        height,
        regionPoints: coordinates
      });
    }
    this.onChange(this.selectedItems);
  }

  onDeleteRegion(type, device, regionId, region){
    if(this.selectedItems && this.selectedItems.length > 0){
      const index = this.selectedItems.findIndex(item => item.id == regionId);
      if(index > -1){
        this.selectedItems.splice(index, 1);
      }
    }
    this.onChange(this.selectedItems);
  }

  onUpdateRegion(type, device, regionId, region, width, height){
    if(this.selectedItems && this.selectedItems.length > 0){
      const index = this.selectedItems.findIndex(item => item.id == regionId);
      if(index > -1){
        const coordinates = region.regionPoints
        this.selectedItems[index].regionPoints = coordinates;
      }
    }
    this.onChange(this.selectedItems);
  }

}
