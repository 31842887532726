import { createSelector, createFeatureSelector } from '@ngrx/store';
import * as fromRoot from '../../../app.reducer';
import * as fromDay from './day';

export * from './effects';

export interface DayState {
  day: fromDay.State;
}

export interface State extends fromRoot.State {
  day: DayState;
}
export const Reducers = {
  day: fromDay.reducer,
};

export const selectDayState = createFeatureSelector<DayState>('day');
export const selectDayDayState = createSelector(selectDayState, (state: DayState) => state.day);
export const getError = createSelector(selectDayDayState, fromDay.getError);
export const getFilter = createSelector(selectDayDayState, fromDay.getFilter);
export const getDays = createSelector(selectDayDayState, fromDay.getDays);
export const isLoading = createSelector(selectDayDayState, fromDay.isLoading);