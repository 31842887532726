import {Component, OnInit, OnDestroy, Input} from '@angular/core';

@Component({
  selector: "BlockFooter",
  templateUrl: './block-footer.component.html',
  styleUrls: ['./block-footer.component.scss'],
})
export class BlockFooter implements OnInit, OnDestroy  {

  ngOnInit() {
  }

  ngAfterViewInit(){
  }


  ngOnDestroy(){
  }
}
