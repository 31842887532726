import { Action } from '@ngrx/store';

export const REQUEST_CHANNELS = '[Channels] Requesting channels';
export const REQUEST_CHANNELS_SUCCESS = '[Channels] Requesting channels Success';
export const REQUEST_CHANNELS_FAILURE = '[Channels] Requesting channels Failure';
export const UPDATE_CHANNEL = '[Channels] Updating channels';
export const UPDATE_CHANNEL_SUCCESS = '[Channels] Updating channels Success';
export const UPDATE_CHANNEL_FAILURE = '[Channels] Updating channels Failure';
export const TEST_CHANNEL = '[Channels] Testing channel';
export const TEST_CHANNEL_SUCCESS = '[Channels] Testing channel Success';
export const TEST_CHANNEL_FAILURE = '[Channels] Testing channel Failure';

export class RequestChannels implements Action {
  readonly type = REQUEST_CHANNELS;

  constructor() {}
}

export class RequestChannelsSuccess implements Action {
  readonly type = REQUEST_CHANNELS_SUCCESS;

  constructor(public payload: any) {}
}

export class RequestChannelsFailure implements Action {
  readonly type = REQUEST_CHANNELS_FAILURE;

  constructor(public payload?: any) {}
}

export class UpdateChannel implements Action {
  readonly type = UPDATE_CHANNEL;

  constructor(public payload: any) {}
}

export class UpdateChannelSuccess implements Action {
  readonly type = UPDATE_CHANNEL_SUCCESS;

  constructor(public payload: any) {}
}

export class UpdateChannelFailure implements Action {
  readonly type = UPDATE_CHANNEL_FAILURE;

  constructor(public payload?: any) {}
}

export class TestChannel implements Action {
  readonly type = TEST_CHANNEL;

  constructor(public payload: any) {}
}

export class TestChannelSuccess implements Action {
  readonly type = TEST_CHANNEL_SUCCESS;

  constructor(public payload: any) {}
}

export class TestChannelFailure implements Action {
  readonly type = TEST_CHANNEL_FAILURE;

  constructor(public payload?: any) {}
}

export type Actions =
  | RequestChannels
  | RequestChannelsSuccess
  | RequestChannelsFailure
  | UpdateChannel
  | UpdateChannelSuccess
  | UpdateChannelFailure
  | TestChannel
  | TestChannelSuccess
  | TestChannelFailure;
