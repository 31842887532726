import {Component, OnInit, OnDestroy} from '@angular/core';

@Component({
  selector: "BlinkingDot",
  templateUrl: './blinkingdot.component.html',
  styleUrls: ['./blinkingdot.component.scss'],
})
export class BlinkingDot implements OnInit, OnDestroy  {

  constructor() {}

  ngOnInit(){

  }

  ngAfterViewInit(){
  }

  ngOnDestroy(){
  }
}
