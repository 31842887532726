import {Component, OnInit, OnDestroy, Input, OnChanges} from '@angular/core';

@Component({
  selector: "ProgressBar",
  templateUrl: './progressbar.component.html',
  styleUrls: ['./progressbar.component.scss'],
})
export class ProgressBar implements OnInit, OnDestroy, OnChanges  {

  @Input("progress") progress: string;

  constructor() {}

  ngOnInit() {

  }

  ngAfterViewInit(){
  }

  ngOnChanges(changes) {

  }

  ngOnDestroy(){
  }
}
