
import {of as observableOf,  Observable } from 'rxjs';
import {catchError, switchMap, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as Action from '../store/actions';
import { SubscriptionService } from '../subscription.service';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class Effects {

    /* Purchase a plan */

    @Effect()
    purchasePlan: Observable<Action.PurchasePlanFailure | Action.PurchasePlanSuccess> = this.actions$.pipe(
    ofType(Action.PURCHASE_PLAN),
      switchMap((action: Action.PurchasePlan) => {
        return this.subscriptionService.purchasePlan(action.payload, action.coupon).pipe(map((subscription) => {
          //mixpanel.track("purchasePlan", {plan: action.payload});
          return new Action.PurchasePlanSuccess(subscription);
        }),
        catchError((e: HttpErrorResponse) => {
          //mixpanel.track("purchasePlanFailed", {plan: action.payload, error: e});
          return observableOf(new Action.PurchasePlanFailure(e.error.error));
        }),);
      }));

    /* Get storage */

    @Effect()
    requestStorage: Observable<Action.RequestStorageFailure | Action.RequestStorageSuccess> = this.actions$.pipe(
      ofType(Action.REQUEST_STORAGE),
      switchMap((action: Action.RequestStorage) => {
        return this.subscriptionService.getStorage().pipe(map((storage) => {
          return new Action.RequestStorageSuccess(storage.data);
        }),
        catchError(e => {
          return observableOf(new Action.RequestStorageFailure(e.error.error));
        })
      );
    }));

    /* Update Storage */

    @Effect()
    updateStorage: Observable<Action.UpdateStorageFailure | Action.UpdateStorageSuccess> = this.actions$.pipe(
      ofType(Action.UPDATE_STORAGE),
      switchMap((action: Action.UpdateStorage) => {
        return this.subscriptionService.updateStorage(action.payload).pipe(map((subscription) => {
          //mixpanel.track("updateStorage", subscription);
          return new Action.UpdateStorageSuccess(subscription.data);
        }),
        catchError((e: HttpErrorResponse) => {
          //mixpanel.track("updateStorageFailed", {storage: action.payload, error: e});
          return observableOf(new Action.UpdateStorageFailure(e.error.data));
        }),);
      }));

    /* Change Region */

    @Effect()
    changeRegion: Observable<Action.ChangeRegionFailure | Action.ChangeRegionSuccess> = this.actions$.pipe(
      ofType(Action.CHANGE_REGION),
      switchMap((action: Action.ChangeRegion) => {
        return this.subscriptionService.changeRegion(action.payload).pipe(map((subscription) => {
          //mixpanel.track("changeRegion", {region: action.payload});
          return new Action.ChangeRegionSuccess(subscription);
        }),
        catchError((e: HttpErrorResponse) => {
          //mixpanel.track("changeRegionFailed", {region: action.payload, error: e});
          return observableOf(new Action.ChangeRegionFailure(e.error.error));
        }),);
      }));

    /* Delete current plan */

    @Effect()
    deletePlan: Observable<Action.DeletePlanFailure | Action.DeletePlanSuccess> = this.actions$.pipe(
    ofType(Action.DELETE_PLAN),
      switchMap((action: Action.DeletePlan) => {
        return this.subscriptionService.deletePlan({feedback: action.payload}).pipe(map((subscription) => {
          //mixpanel.track("deletePlan");
          return new Action.DeletePlanSuccess(subscription);
        }),
        catchError((e: HttpErrorResponse) => {
          //mixpanel.track("deletePlanFailed", {error: e});
          return observableOf(new Action.DeletePlanFailure(e.error.error));
        }),);
      }));

    /* Cancel current plan */

    @Effect()
    cancelPlan: Observable<Action.CancelPlanFailure | Action.CancelPlanSuccess> = this.actions$.pipe(
      ofType(Action.CANCEL_PLAN),
      switchMap((action: Action.CancelPlan) => {
        return this.subscriptionService.cancelPlan({feedback: action.payload}).pipe(map((subscription) => {
          //mixpanel.track("cancelPlan");
          return new Action.CancelPlanSuccess(subscription);
        }),
        catchError(e => {
          //mixpanel.track("cancelPlanFailed", {error: e});
          return observableOf(new Action.CancelPlanFailure(e.error.error));
        }),);
      }));

    /* Resume current plan */

    @Effect()
    resumePlan: Observable<Action.ResumePlanFailure | Action.ResumePlanSuccess> = this.actions$.pipe(
      ofType(Action.RESUME_PLAN),
      switchMap((action: Action.ResumePlan) => {
        return this.subscriptionService.resumePlan().pipe(map((subscription) => {
          //mixpanel.track("resumePlan");
          return new Action.ResumePlanSuccess(subscription);
        }),
        catchError(e => {
          //mixpanel.track("resumePlanFailed", {error: e});
          return observableOf(new Action.ResumePlanFailure(e.error.error));
        }),);
      }));

    /* Get current plan */

    @Effect()
    requestPlan: Observable<Action.RequestPlanFailure | Action.RequestPlanSuccess> = this.actions$.pipe(
      ofType(Action.REQUEST_PLAN),
      switchMap((action: Action.RequestPlan) => {
        return this.subscriptionService.getPlan().pipe(map((plan) => {
          return new Action.RequestPlanSuccess(plan.data);
        }),
        catchError(e => {
          return observableOf(new Action.RequestPlanFailure(e.error.error));
        }),);
      }));

    /* Get activity */

    @Effect()
    requestActivity: Observable<Action.RequestActivityFailure | Action.RequestActivitySuccess> = this.actions$.pipe(
      ofType(Action.REQUEST_ACTIVITY),
      switchMap((action: Action.RequestActivity) => {
        return this.subscriptionService.getActivity().pipe(map((activity) => {
          return new Action.RequestActivitySuccess(activity.data);
        }),
        catchError(e => {
          return observableOf(new Action.RequestActivityFailure(e.error.error));
        }),);
      }));

    /* Get settings */

    @Effect()
    requestSettings: Observable<Action.RequestSettingsFailure | Action.RequestSettingsSuccess> = this.actions$.pipe(
      ofType(Action.REQUEST_SETTINGS),
      switchMap((action: Action.RequestSettings) => {
        return this.subscriptionService.getSettings().pipe(map((settings) => {
          return new Action.RequestSettingsSuccess(settings.data.map);
        }),
        catchError(e => {
          return observableOf(new Action.RequestSettingsFailure(e.error.error));
        }),);
      }));


    /* Validate Coupon */

    @Effect()
    validateCoupon: Observable<Action.ValidateCouponFailure | Action.ValidateCouponSuccess> = this.actions$.pipe(
      ofType(Action.VALIDATE_COUPON),
      switchMap((action: Action.ValidateCoupon) => {
        return this.subscriptionService.validateCoupon(action.payload).pipe(map((coupon) => {
          return new Action.ValidateCouponSuccess(coupon);
        }),
        catchError((e: HttpErrorResponse) => {
          if(e.error) {
            const {data} = e.error
            // If data has message key
            if (data.message) {
              const {message} = data
              return observableOf(new Action.ValidateCouponFailure(message));
            } else {
              return observableOf(new Action.ValidateCouponFailure(data));
            }
          } else {
            return observableOf(new Action.ValidateCouponFailure("something went wrong"));
          }
        })
      );
    }));

    constructor(private subscriptionService: SubscriptionService,
                private actions$: Actions) {}
}
