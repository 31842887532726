import * as livestream from './actions';
//import { User } from '../models/user';

export interface State {
  speech: boolean;
}

export const initialState: State = {
  speech: true
};

export function reducer(state = initialState, action: livestream.Actions): State {
  switch (action.type) {

    case livestream.TOGGLE_SPEECH_SUCCESS: {
      const speech = action.payload;
      return {
        ...state,
        speech
      };
    }

    case livestream.REQUEST_LIVESTREAM_SUCCESS: {
      const properties = action.payload;
      return {
        ...state,
        ...properties
      };
    }

    case livestream.RESET: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}

export const getLivestream = (state: State) => state;
