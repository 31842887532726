import {map, take} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { ApiService } from '../shared/api.service';

@Injectable()
export class LoginService {

  constructor(private http: HttpClient,
              private apiService: ApiService) {}

                // Get a token from login credentials.
  public getSSODomains() : Observable<any> {
    return this.http.get(this.apiService.getSSODomains()).pipe(take(1),map(domains => {
        return domains;
    }),);
  }

  // Get a token from login credentials.
  public login(data) : Observable<any> {
    return this.http.post(this.apiService.login(), data).pipe(take(1),map(token => {
        return token;
    }),);
  }

  public logout() {}

  // Register a user.
  public register(payload) : Observable<any> {
    return this.http.post(this.apiService.register(), payload).pipe(take(1),map(register => {
      return register;
    }),);
  }

  // Register a user.
  public forgot(payload) : Observable<any> {
    return this.http.post(this.apiService.forgot(), {
      usernameOrEmail: payload.usernameOrEmail,
      domain: payload.domain,
    }).pipe(take(1),map(forgot => {
      return forgot;
    }),);
  }

  // Activate user using token.
  public activate(token) : Observable<any> {
    return this.http.post(this.apiService.activate(), {token}).pipe(take(1),map(token => {
      return token;
    }),);
  }

  // Validate MFA token
  public validateMFA(payload) : Observable<any> {
    return this.http.post(this.apiService.validateMFACode(), payload).pipe(take(1),map(payload => {
      return payload;
    }),);
  }

  // Get user from Kerberos.io API and keep accesstoken in memory.
  public withAPI(token) : Observable<any> {
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    return this.http.get(this.apiService.getProfile(), {headers}).pipe(take(1),map(user => {
      // assign access token
      let profile = user['user'];
      profile = Object.assign(profile, {accesstoken: token});
      return profile;
    }),);
  }
}
