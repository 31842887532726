import * as devices from './actions';
//import { User } from '../models/user';

export interface State {
  cameras: Array<any>;
  currentDevice: any;
  analytics: any;
  activity: any;
  version: any;
  devicesAdded: boolean;
}

export const initialState: State = {
  cameras: [],
  currentDevice: null,
  analytics: {},
  activity: [],
  version: null,
  devicesAdded: true
};

export function reducer(state = initialState, action: devices.Actions): State {
  switch (action.type) {

    case devices.UPDATE_DEVICE_ACTIVITY: {
      return {
        ...state,
        cameras: state.cameras.map((camera, i) => camera.key === action.payload.key ? {...camera, active: action.payload.active} : camera)
      };
    }

    case devices.REMOVE_DEVICE: {
      let cameraIndex = state.cameras.findIndex(camera => camera.key === action.payload);
      let acitvityIndex = state.activity.findIndex(camera => camera.key === action.payload);
      return {
        ...state,
        cameras: [
          ...state.cameras.slice(0, cameraIndex),
          ...state.cameras.slice(cameraIndex + 1),
        ],
        activity: [
          ...state.activity.slice(0, acitvityIndex),
          ...state.activity.slice(acitvityIndex + 1),
        ]
      };
    }

    case devices.REQUEST_LATEST_VERSION_SUCCESS: {
      const version = action.payload;
      return {
        ...state,
        version
      };
    }

    case devices.REQUEST_DEVICES_SUCCESS: {
      const payload = action.payload;
      if(!payload) {
        return state;
      }

      // Get all cameras
      let cameras = state.cameras;
      payload.map((item) => {
        // Add or update camera's
        let foundCamera = cameras.find(camera => camera.key === item.key)
        const mostRecentInfo = item.analytics[item.analytics.length-1];
        if(mostRecentInfo) {
          if(foundCamera){
            foundCamera.name = mostRecentInfo.cameraname;
            if(item.location) {
              const { lng, lat } = item.location.geometry.location;
              const location =  { lng, lat };
              foundCamera.location = location;
            }
          }
          else {
            let location = null;
            if(item.location) {
              const { lng, lat } = item.location.geometry.location;
              location = { lng, lat };
            }
            cameras = [
              ...cameras,
              {
                key: item.key,
                name:  mostRecentInfo.cameraname,
                location,
                version:  mostRecentInfo.version,
                active: false
              }
            ]
          }
        }
      });

      let devicesAdded = true;
      if(!payload || payload.length == 0) {
        devicesAdded = false;
      }

      // Get all latest activity
      let activity = state.activity;
      payload.map((item, index) => {
        // Add or update camera's
        let foundCamera = activity.find(camera => camera.key === item.key)
        const mostRecentInfo = item.analytics[item.analytics.length-1];

        if(mostRecentInfo) {
          if(foundCamera){
            foundCamera.name = mostRecentInfo.cameraname;
            if(
              (!foundCamera.activity && item.latestMedia) ||
              (foundCamera.activity && item.latestMedia && foundCamera.activity.key != item.latestMedia.key)
            ){
              activity = [
                ...activity.slice(0, index),
                {
                  key: item.key,
                  name:  mostRecentInfo.cameraname,
                  activity: item.latestMedia
                },
                ...activity.slice(index + 1),
              ]
            }
          }
          else {
            activity = [
              ...activity,
              {
                key: item.key,
                name:  mostRecentInfo.cameraname,
                activity: item.latestMedia
              }
            ]
          }
        }
      });

      // Update analytics
      /*let analytics = {...state.analytics};

      for(let i = 0; i < payload.length; i++) {
        const cameraKey = payload[i].key;
        if (!analytics.hasOwnProperty(cameraKey)) {
          analytics[cameraKey] = payload[i].analytics;
        }
        else {
          // Check if different, if so we'll append it.
          const lastAnalytic = analytics[cameraKey][analytics[cameraKey].length-1]
          const newAnalytic = payload[i].analytics[payload[i].analytics.length-1];

          if(lastAnalytic && newAnalytic && lastAnalytic.timestamp != newAnalytic.timestamp) {
            analytics[cameraKey].shift();
            analytics[cameraKey] = [
              ...analytics[cameraKey],
              newAnalytic
            ]
          }
        }
      }*/

      return {
        ...state,
        cameras,
        //analytics,
        activity,
        devicesAdded
      };
    }

    case devices.FIND_DEVICE_SUCCESS: {
      const device = action.payload;
      return {
        ...state,
        currentDevice: device
      };
    }

    case devices.MUTE_DEVICE_SUCCESS: {
      const { mute } = action.payload;
      return {
        ...state,
        currentDevice: {
          ...state.currentDevice,
          mute
        }
      };
    }

    case devices.UPDATE_DEVICE_SUCCESS: {
      const payload = action.payload;
      return {
        ...state,
        currentDevice: {
          ...state.currentDevice,
          ...payload
        }
      };
    }

    case devices.RESET: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}

export const getDevices = (state: State) => state;
export const getCurrentDevice = (state: State) => state.currentDevice;
export const getCameras = (state: State) => state.cameras;
export const getAnalytics = (state: State) => state.analytics;
export const getLatestVersion = (state: State) => state.version;
export const getLatestMedia = (state: State) => state.activity;
export const getAnalyticsFromCamera = (state: State, key: string) => {
  return state[key];
}
