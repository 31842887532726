import * as day from './actions';
import * as _ from "lodash";

export interface State {
  error: any;
  loading: boolean;
  days: Array<any>;
  filter: any;
}

export const initialState: State = {
  error: null,
  loading: false,
  days: [],
  filter: {
    lastMedia: null,
    globalSearch: false,
    dates: [],
    instances: [],
    classifications: [],
    sort: 'desc',
    favourite: false,
    hasLabel: false,
    hourRange: {
      start: 0,
      end: 86400
    }
  }
};

export function reducer(state = initialState, action: day.Actions): State {
  switch (action.type) {

    case day.SET_FILTER: {
      return {
        ...state,
        filter: Object.assign({}, state.filter, action.payload)
      }
    }
    case day.GET_DAYS: {
      return {
        ...state,
        loading: true,
        days: action.payload
      }
    }
    case day.GET_DAYS_SUCCESS: {
      return {
        ...state,
        loading: false,
        days: action.payload
      }
    }
    case day.GET_DAYS_FAILURE: {
      return {
        ...state,
        loading: false,
      }
    }
    case day.RESET: {
      return {
        ...state,
        days: []
      }
    }
    default: {
      return state;
    }
  }
}

export const getError = (state: State) => state.error;
export const getFilter = (state: State) => state.filter;
export const getDays = (state: State) => state.days;
export const isLoading = (state: State) => state.loading;
