
import {map, take} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { ApiService } from '../../shared/api.service';

@Injectable()
export class AccountsService {

  constructor(private http: HttpClient,
              private apiService: ApiService) {}

  public getAccounts() : Observable<any> {
    return this.http.get(this.apiService.getAccounts()).pipe(take(1),map(value => {
      return value;
    }),);
  }

  public getAccount(id) : Observable<any> {
    return this.http.get(this.apiService.getAccount(id)).pipe(take(1),map(value => {
      return value;
    }),);
  }

  public addAccount(account) : Observable<any> {
    return this.http.post(this.apiService.addAccount(), { account }).pipe(take(1),map(value => {
      return value;
    }),);
  }

  public editAccount(id, account) : Observable<any> {
    return this.http.put(this.apiService.editAccount(id), { account }).pipe(take(1),map(value => {
      return value;
    }),);
  }

  public deleteAccount(id) : Observable<any> {
    return this.http.delete(this.apiService.deleteAccount(id)).pipe(take(1),map(value => {
      return value;
    }),);
  }
}
