import {Component, OnInit, OnDestroy, Input} from '@angular/core';

@Component({
  selector: "BlockBody",
  templateUrl: './block-body.component.html',
  styleUrls: ['./block-body.component.scss'],
})
export class BlockBody implements OnInit, OnDestroy  {

  ngOnInit() {
  }

  ngAfterViewInit(){
  }


  ngOnDestroy(){
  }
}
