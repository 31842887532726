import * as groups from './actions';

export interface State {
  groups: Array<any>;
  group: any;
  groupDevices: Array<any>;
  groupSites: Array<any>;
}

export const initialState: State = {
  groups: [],
  group: null,
  groupDevices: [],
  groupSites: [],
};

export function reducer(state = initialState, action: groups.Actions): State {
  switch (action.type) {

    case groups.REQUEST_GROUPS_SUCCESS: {
      const payload = action.payload;
      return {
        ...state,
        groups: payload ? payload : []
      }
    }

    case groups.FIND_GROUP_SUCCESS: {
      const payload = action.payload;
      return {
        ...state,
        group: payload ? payload : null
      }
    }

    case groups.FIND_GROUP_DEVICES_SUCCESS: {
      const payload = action.payload;
      if(!payload) {
        return {
          ...state,
          groupDevices: [],
        };
      }

      const timeNow = Math.floor(Date.now() / 1000);
      const groupDevices = payload.map(c => {
        const mostRecentInfo = c.analytics[c.analytics.length-1];
        return {
          key: c.key,
          color: c.color,
          name: mostRecentInfo.cameraname,
          version:  mostRecentInfo.version,
          release:  mostRecentInfo.release,
          enterprise:  mostRecentInfo.enterprise,
          connected:  mostRecentInfo.cameraConnected === "" ? "true" : mostRecentInfo.cameraConnected,
          hasBackchannel: mostRecentInfo.hasBackchannel === "" ? "true" : mostRecentInfo.hasBackchannel,
          uptime:  mostRecentInfo.uptime.split(",")[0],
          group: (state.group) ? state.group.name : '',
          active: (timeNow - mostRecentInfo.timestamp) < 180
        };
      });

      return {
        ...state,
        groupDevices,
      }
    }

    case groups.ADD_GROUP_SUCCESS: {
      const payload = action.payload;
      return {
        ...state,
        groups: payload
      }
    }

    case groups.EDIT_GROUP_SUCCESS: {
      const payload = action.payload;
      const group = payload.find(g => g.id == action.id);
      if(!group) {
        return state;
      }

      return {
        ...state,
        groups: payload,
        group,
      }
    }

    case groups.DELETE_GROUP_SUCCESS: {
      const payload = action.payload;
      return {
        ...state,
        groups: payload
      }
    }

    case groups.ASSIGN_DEVICES_SUCCESS: {
      const payload = action.payload;
      return {
        ...state,
        groups: payload
      }
    }
    case groups.RESET: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}

export const getGroups = (state: State) => state.groups;
export const getGroup = (state: State) => state.group;
export const getGroupDevices = (state: State) => state.groupDevices;
