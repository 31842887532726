import {timer as observableTimer } from 'rxjs';
import {take} from 'rxjs/operators';
import { Component, OnInit, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FormControl, FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Store } from '@ngrx/store';
import { VideoHeaderModalComponent } from './blocks/video/videoheadermodal.component';
import { ToastrService } from 'ngx-toastr';
import * as fromAuth from '../../../login/store';
import * as fromHome from '../../store';
import * as Home from '../../store/actions';

@Component({
  selector: 'header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Input() public openMenu: Function;

  //public notifications$ = this.store.select(fromHome.getNotifications);
  //public lastNotification$ = this.store.select(fromHome.getLastNotification);
  public user$ = this.store.select(fromAuth.getUser);
  public user;
  public notificationsSubscription: any;
  public timeSubscription: any;
  public userSubscription: any;
  public now: number;
  public showDropdown: boolean = false;
  public showFeedback: boolean = false;

  public notifications: any;
  public unreadNotifications: number;
  public pusher: any;
  public channel: any;
  public channels: any;
  public timer: any;
  public interval: any;
  public openVideoModal: any;
  public pageName: string;
  public rate = 'no';
  public feedbackForm: FormGroup;

  constructor(public dialog: MatDialog,
              private store: Store<fromHome.State>,
              private toastr: ToastrService,
              private fb: FormBuilder) {

    this.showDropdown = false;

    this.feedbackForm = this.fb.group({
      feedback: new FormControl('', [Validators.required,
      Validators.minLength(3)])
    });

    this.pageName = "";
    this.channels = [];

    // Get user, and subscribe to pusher notifications.
    this.userSubscription = this.user$.pipe(take(1)).subscribe(user => {
      if(user && user.username) {
        this.user = user;
      }
    });

    this.interval = 1000;
    this.timer = observableTimer(0, this.interval);

    /*this.notificationsSubscription = this.notifications$.subscribe((notifications) => {
      this.notifications = notifications;
      this.unreadNotifications = this.notifications.filter((n) => n.unread).length;
    })*/
  }

  ngOnInit() {
    this.now = Math.floor(Date.now());
    this.timeSubscription = this.timer.subscribe(() => {
      this.now = Math.floor(Date.now());
    });
  }

  ngOnDestroy() {
    this.timeSubscription.unsubscribe();
    //this.notificationsSubscription.unsubscribe();
    this.userSubscription.unsubscribe();
  }

  markRead($event, notification, i){
    this.store.dispatch(new Home.MarkReadNotification({index: i, notification}));
    $event.stopPropagation();
  }

  toggleDropdown(){
    this.showDropdown = !this.showDropdown;
  }

  toggleHelp(){
  }

  toggleFeedback(){
    this.showFeedback = true;
  }

  closeFeedback(){
    this.showFeedback = false;
  }

  sendFeedback(ev) {
    if(this.feedbackForm.valid) {
      this.store.dispatch(new Home.SendFeedback("feedback - ( " + this.rate + " stars ) " + this.feedbackForm.value.feedback));
      this.showFeedback = false;
    }
  }

  markReadAll($event){
    this.store.dispatch(new Home.MarkReadAll());
    this.showDropdown = false;
    $event.stopPropagation();
  }

  timeDifference(current, previous) {

    var msPerMinute = 60 * 1000;
    var msPerHour = msPerMinute * 60;
    var msPerDay = msPerHour * 24;
    var msPerMonth = msPerDay * 30;
    var msPerYear = msPerDay * 365;

    var elapsed = current - previous;

    if (elapsed < msPerMinute) {
         return Math.round(elapsed/1000) + ' seconds ago';
    }

    else if (elapsed < msPerHour) {
         return Math.round(elapsed/msPerMinute) + ' minutes ago';
    }

    else if (elapsed < msPerDay ) {
         return Math.round(elapsed/msPerHour ) + ' hours ago';
    }

    else if (elapsed < msPerMonth) {
        return Math.round(elapsed/msPerDay) + ' days ago';
    }

    else if (elapsed < msPerYear) {
        return Math.round(elapsed/msPerMonth) + ' months ago';
    }

    else {
        return Math.round(elapsed/msPerYear ) + ' years ago';
    }
  }

  openVideo(notification): void {
    // Only open if video.
    if(notification.media[0].type === 'video') {
      let dialogRef = this.openVideoModal = this.dialog.open(VideoHeaderModalComponent, {
        height: 'auto',
        width: '100%',
        data: notification.sequenceid
      });
      dialogRef.afterClosed().subscribe(result => {
        this.openVideoModal = null;
      });
    }
  }
}
