import {Component, Input} from '@angular/core';
import {
  MqttService,
} from '../../../../mqtt.service';

@Component({
  selector: "PTZPreset",
  templateUrl: './ptzpreset.component.html',
  styleUrls: ['./ptzpreset.component.scss'],
})
export class PTZPreset {

  @Input() deviceKey: string;
  @Input() cloudKey: string;
  @Input() onvif: boolean = true;
  @Input() talk: boolean = true;
  @Input() presets: Array<any> = [];
  @Input() onboardPresets: Array<any> = [];
  @Input() encrypted: boolean = false;
  public open: boolean = false;

  constructor(private mqttService: MqttService) {
    this.openPresets = this.openPresets.bind(this);
    this.updatePTZPosition = this.updatePTZPosition.bind(this);
    this.GoToPreset = this.GoToPreset.bind(this);
  }

  private openPresets(){
    this.open = !this.open;
  }

  private updatePTZPosition(x, y, z){
    const topic = "kerberos/agent/" + this.cloudKey;
    const payload = {
      action: "update-ptz-position",
      device_id: this.deviceKey,
      value: {
        action: "absolute-move",
        payload: {
          x,
          y,
          z
        }
      }
    };
    this.mqttService.publish(topic, payload, this.encrypted)
  }

  private GoToPreset(preset){
    const topic = "kerberos/agent/" + this.cloudKey;
    const payload = {
      action: "update-ptz-position",
      device_id: this.deviceKey,
      value: {
        action: "preset",
        payload: {
          preset
        }
      }
    };
    this.mqttService.publish(topic, payload, this.encrypted)
  }
}