import {Component, OnInit, OnDestroy, Input, ViewChild} from '@angular/core';
import {Form, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Store} from "@ngrx/store";
import { ProfileService } from '../../../home/profile/profile.service';
import * as fromSubscription from "../../../home/subscription/store";
import * as fromProfile from "../../../home/profile/store";
import { loadStripe } from '@stripe/stripe-js';
import { stripeConfig } from 'src/environments/environment';
@Component({
  selector: "CreditCardBox",
  templateUrl: './creditcardbox.component.html',
  styleUrls: ['./creditcardbox.component.scss'],
})
export class CreditCardBox implements OnInit, OnDestroy  {

  //@ViewChild(StripeCardComponent, { static: false }) card: StripeCardComponent;
  @Input("isUpdating") isUpdating: boolean;
  @Input("showText") showText: boolean = true;
  @Input("hideButton") hideButton: boolean = false;
  @Input("error") error: string = "";
  public stripe: any;
  public setupIntentSecret: string = "";
  public stripeCustomer: FormGroup;

  public profile$ = this.store.select(fromProfile.getProfile);
  public profileSubscription;
  public profile;

  constructor(private fb: FormBuilder,
              private store: Store<fromSubscription.State>,
              private profileService: ProfileService){
    this.stripeCustomer = this.fb.group({
      name: ['', [Validators.required]],
      address: ['', [Validators.required]]
    });
    loadStripe(stripeConfig.publishableKey).then((stripe) => {
      this.stripe = stripe;
    })

    this.addCustomer = this.addCustomer.bind(this);
    this.addCard = this.addCard.bind(this);
    this.showPaymentMethods = this.showPaymentMethods.bind(this);
  }

  ngOnInit(){
    this.profileSubscription = this.profile$.subscribe(profile => {
      this.profile = profile;
    });
  }
  addCustomer() {
    const name = this.stripeCustomer.get('name').value;
    const address = this.stripeCustomer.get('address').value;

    if (name === '' || address === '') {
      this.error = 'Please fill in all fields';
    } else {
      // We'll create the customer in Stripe if it was not created yet
      // On top of that we'll create a SetupIntent to save the card
      // for future payments
      this.error = '';
      this.profileService.addCustomer({name}).subscribe(result => {
          this.setupIntentSecret = result.data;
          this.showPaymentMethods(this.setupIntentSecret);
      });
    }
  }

  addCard() {

  }

  showPaymentMethods(setupIntentSecret ) {
    // Set up Stripe.js and Elements using the SetupIntent's client secret
    const options = {
      clientSecret: setupIntentSecret,
      // Fully customizable with appearance API.
      //appearance: {/*...*/},
    };
        
    const elements = this.stripe.elements(options);

    // Create and mount the Payment Element
    const paymentElement = elements.create('payment');
    paymentElement.mount('#payment-element');

    const form = document.getElementById('payment-form');
    form.addEventListener('submit', async (event) => {
      event.preventDefault();
      const fullHostname = location.protocol + '//' + location.host;
      const {error} = this.stripe.confirmSetup({
        //`Elements` instance that was used to create the Payment Element
        elements,
        confirmParams: {
          return_url: fullHostname + '/subscription#billing',
        }
      });
    
      if (error) {
        // This point will only be reached if there is an immediate error when
        // confirming the payment. Show error to your customer (for example, payment
        // details incomplete)
        const messageContainer = document.querySelector('#error-message');
        messageContainer.textContent = error.message;
      } else {
        // Your customer will be redirected to your `return_url`. For some payment
        // methods like iDEAL, your customer will be redirected to an intermediate
        // site first to authorize the payment, then redirected to the `return_url`.
      }
    });
  }

  ngOnDestroy(){
    this.profileSubscription.unsubscribe();
  }
}
