import {Component, OnInit, OnDestroy, Input, OnChanges, SimpleChanges} from '@angular/core';
import {DatePipe} from "@angular/common";
const moment = require('moment');

@Component({
  selector: "TasksList",
  templateUrl: './tasks-list.component.html',
  styleUrls: ['./tasks-list.component.scss'],
})
export class TasksList implements OnInit, OnDestroy, OnChanges  {

  @Input() tasks: any;
  @Input() role: string = "guest";
  @Input() editTask: any
  @Input() deleteTask: any
  public allTasks = [];

  constructor() {}

  ngOnInit(){
  }

  ngAfterViewInit(){
  }

  ngOnDestroy(){
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.tasks) {
      this.allTasks = [];
      const tasks = changes.tasks.currentValue;
      for(let i = 0; i < tasks.length; i++) {
        const task = tasks[i];
        this.allTasks.push({
          media_datetime: task.media_datetime,
          media_end_datetime: task.media_end_datetime,
          creation_datetime: task.creation_datetime,
          thumbnail: (task.media && task.media.metadata && task.media.metadata.analysis ) ? task.media.metadata.analysis.thumby.base64 : task.media.metadata.thumbnail, // thumbnail has been moved
          id: task.id,

          // Legacy below
          camera: task.camera,
          cameraId: task.media.metadata.camera_id,
          cameraName: task.media.instanceName,
          // New methods we have multiple cameras now (due to bulk export)
          cameras: task.cameras,
          cameraNames: task.camera_names,

          title: task.title,
          notes: task.notes,
          status: task.status,
          video_url: task.video_url,
          thumbnail_url: task.thumbnail_url,
          sprite_url: task.sprite_url,
          compressed_url: task.compressed_url,
          reporter: task.reporter_profile,
          assignees: task.assignees_profile,
          titleShort: this.truncate(task.title, 20, "..."),
          notesShort: this.truncate(task.notes, 50, "..."),
          export_status: task.export_status,
          export_files_count: task.export_files_count,
          downloaded_files: task.downloaded_files,
        });
      }
    }
  }

  public trackTask(index: number, task: any) {
    return task.id;
  }

  truncate(fullStr, strLen, separator) {
    if (fullStr.length <= strLen) return fullStr;
    separator = separator || '...';
    var sepLen = separator.length,
      charsToShow = strLen - sepLen,
      frontChars = Math.ceil(charsToShow/2),
      backChars = Math.floor(charsToShow/2);
    return fullStr.substr(0, frontChars) +
      separator +
      fullStr.substr(fullStr.length - backChars);
  }

  shortenString(text: string, length: number) : string {
    const trimmedString = text.length > length ? text.substring(0, length - 3) : text;
    return trimmedString
  }
}
