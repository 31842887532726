import * as videowalls from './actions';

export interface State {
  videowalls: Array<any>;
  videowall: any;
  error: any;
}

export const initialState: State = {
  videowalls: [],
  videowall: null,
  error: null
};

export function reducer(state = initialState, action: videowalls.Actions): State {
  switch (action.type) {

    case videowalls.REQUEST_VIDEOWALLS_SUCCESS: {
      const payload = action.payload;
      return {
        ...state,
        videowalls: payload ? payload : []
      }
    }

    case videowalls.ADD_VIDEOWALL_SUCCESS: {
      const payload = action.payload;
      return {
        ...state,
        videowalls: payload,
        error: null
      }
    }

    case videowalls.ADD_VIDEOWALL_FAILURE: {
      const payload = action.payload;
      return {
        ...state,
        error: payload
      }
    }

    case videowalls.EDIT_VIDEOWALL_SUCCESS: {
      const payload = action.payload;
      return {
        ...state,
        videowalls: payload
      }
    }

    case videowalls.EDIT_VIDEOWALL_FAILURE: {
      const payload = action.payload;
      return {
        ...state,
        error: payload
      }
    }

    case videowalls.DELETE_VIDEOWALL_SUCCESS: {
      const payload = action.payload;
      return {
        ...state,
        videowalls: payload
      }
    }

    case videowalls.RESET: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}

export const getVideowalls = (state: State) => state.videowalls;
export const getVideowall = (state: State) => state.videowall;
export const getError = (state: State) => state.error;
