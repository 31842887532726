import {Component, OnInit, OnDestroy, Input} from '@angular/core';

@Component({
  selector: "SiteBullet",
  templateUrl: './sitebullet.component.html',
  styleUrls: ['./sitebullet.component.scss'],
})
export class SiteBullet implements OnInit, OnDestroy  {

  @Input() color: string;
  @Input() title: string;

  constructor() {}

  ngOnInit() {
  }

  ngAfterViewInit(){
  }

  ngOnDestroy(){
  }
}
