import * as subscription from './actions';

export interface State {
  plan: any;
  planPurchased: boolean;
  credentials: any;
  reachedLimit: boolean;
  settings: any;
  activity: any;
  updating: boolean;
  updatingMessage: string;
  error: boolean;
  errormessage: string;
  success: boolean;
  successmessage: string;
  successPurchase: boolean,
  storage: any;
  updatingStorage: boolean;
  updatingStorageSuccess: boolean;
  updatingStorageFailed: boolean;
  updatingStorageMessage: string;
  verifyingStorage: boolean;
  verifyingStorageSuccess: boolean;
  verifyingStorageFailed: boolean;
  verifyingStorageMessage: string;
  couponPercentOff: number;
  validateCoupon: boolean;
  validateCouponSuccess: boolean;
  validateCouponFailed: boolean;
  validateCouponMessage: string;
}

export const initialState: State = {
  plan: null,
  planPurchased: true,
  credentials: null,
  reachedLimit: false,
  settings: null,
  activity: null,
  updating: false,
  updatingMessage: "",
  error: false,
  errormessage: "",
  success: false,
  successmessage: "",
  successPurchase: false,
  storage: null,
  updatingStorage: false,
  updatingStorageSuccess: false,
  updatingStorageFailed: false,
  updatingStorageMessage: "false",
  verifyingStorage: false,
  verifyingStorageSuccess: false,
  verifyingStorageFailed: false,
  verifyingStorageMessage: "false",
  couponPercentOff: 0,
  validateCoupon: false,
  validateCouponSuccess: false,
  validateCouponFailed: false,
  validateCouponMessage: "false",
};

export function reducer(state = initialState, action: subscription.Actions): State {
  switch (action.type) {
    case subscription.REQUEST_PLAN_SUCCESS: {
      const payload = action.payload;
      return {
        ...state,
        plan: payload.plan,
        planPurchased: !!payload.plan,
        credentials: payload.credentials,
        reachedLimit: (payload.reachedLimit == true) ? true : false
      }
    }

    case subscription.REQUEST_ACTIVITY_SUCCESS: {
      const payload = action.payload;
      return {
        ...state,
        activity: payload
      }
    }

    case subscription.REQUEST_STORAGE_SUCCESS: {
      const payload = action.payload;
      return {
        ...state,
        storage: payload
      }
    }

    case subscription.UPDATE_STORAGE: {
      return {
        ...state,
        updatingStorage: true,
        updatingStorageSuccess: false,
        updatingStorageFailed: false,
        updatingStorageMessage: ""
      }
    }


    case subscription.UPDATE_STORAGE_SUCCESS:  {
      const payload = action.payload;
      return {
        ...state,
        storage: payload,
        updatingStorage: false,
        updatingStorageSuccess: true,
        updatingStorageFailed: false,
        updatingStorageMessage: ""
      }
    }

    case subscription.UPDATE_STORAGE_FAILURE:  {
      const payload = action.payload;
      return {
        ...state,
        updatingStorage: false,
        updatingStorageSuccess: false,
        updatingStorageFailed: true,
        updatingStorageMessage: payload
      }
    }

    case subscription.PURCHASE_PLAN:  {
      return {
        ...state,
        updating: true,
        error: false,
        errormessage: "",
        success: false,
        successPurchase: false,
        successmessage: "",
        updatingMessage: "Processing payment"
      }
    }

    case subscription.DELETE_PLAN: {
      return {
        ...state,
        updating: true,
        error: false,
        errormessage: "",
        success: false,
        successmessage: "",
        updatingMessage: "Deleting subscription"
      }
    }

    case subscription.CANCEL_PLAN:  {
      return {
        ...state,
        updating: true,
        error: false,
        errormessage: "",
        success: false,
        successmessage: "",
        updatingMessage: "Cancelling subscription"
      }
    }

    case subscription.RESUME_PLAN:  {
      return {
        ...state,
        updating: true,
        error: false,
        errormessage: "",
        success: false,
        successmessage: "",
        updatingMessage: "Resuming subscription"
      }
    }

    case subscription.DELETE_PLAN_SUCCESS:
    case subscription.CANCEL_PLAN_SUCCESS:
    case subscription.RESUME_PLAN_SUCCESS: {
      const payload = action.payload;
      return {
        ...state,
        updating: false,
        plan: payload.plan,
        planPurchased: !!payload.plan,
        credentials: payload.credentials,
        success: true,
        successmessage: payload.success
      }
    }

    case subscription.PURCHASE_PLAN_SUCCESS: {
      const payload = action.payload;
      return {
        ...state,
        updating: false,
        plan: payload.plan,
        planPurchased: !!payload.plan,
        credentials: payload.credentials,
        success: true,
        successmessage: payload.success,
        successPurchase: true,
      }
    }

    case subscription.DELETE_PLAN_FAILURE:
    case subscription.CANCEL_PLAN_FAILURE:
    case subscription.RESUME_PLAN_FAILURE: {
      const payload = action.payload;
      return {
        ...state,
        updating: false,
        error: true,
        errormessage: payload
      }
    }

    case subscription.PURCHASE_PLAN_FAILURE: {
      const payload = action.payload;
      return {
        ...state,
        updating: false,
        error: true,
        errormessage: payload,
        successPurchase: false,
      }
    }

    case subscription.REQUEST_SETTINGS_SUCCESS: {
      const payload = action.payload;
      return {
        ...state,
        settings: payload
      }
    }

    case subscription.REQUEST_ACTIVITY_SUCCESS: {
      const payload = action.payload;
      return {
        ...state,
        activity: payload
      }
    }

    case subscription.VALIDATE_COUPON: {
      return {
        ...state,
        couponPercentOff: 0,
        validateCoupon: true,
        validateCouponSuccess: false,
        validateCouponFailed: false,
        validateCouponMessage: ""
      } 
    }

    case subscription.VALIDATE_COUPON_SUCCESS: {
      const payload = action.payload;
      return {
        ...state,
        couponPercentOff: payload.data,
        validateCoupon: false,
        validateCouponSuccess: true,
        validateCouponFailed: false,
        validateCouponMessage: payload
      } 
    }

    case subscription.VALIDATE_COUPON_FAILURE: {
      const payload = action.payload;
      return {
        ...state,
        couponPercentOff: 0,
        validateCoupon: false,
        validateCouponSuccess: false,
        validateCouponFailed: true,
        validateCouponMessage: payload
      }
    }

    case subscription.RESET: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}

export const getSubscription = (state: State) => state;
export const getPlan = (state: State) => state.plan;
export const getCredentials = (state: State) => state.credentials;
export const getStorage = (state: State) => state.storage;
export const getActivity = (state: State) => state.activity;
export const getSettings = (state: State) => state.settings;
