import { ModuleWithProviders }  from '@angular/core';
import { RouterModule } from '@angular/router';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { LoginService } from './login.service';
import { TokenInterceptor } from './token.interceptor';
import { LoginComponent } from './login.component';
import { SignupComponent } from './signup.component';
import { ActivateComponent } from './activate.component';
import { GuestGuard } from '../guards/guest.guard';
import { OvalComponent } from './blocks/oval/oval.component';
import { TosComponent } from './blocks/tos/tos.component';
import { SSOComponent } from "./sso.component";

export class LoginRoutes {

  public static getDeclarations() : any {
    return [
      LoginComponent,
      SignupComponent,
      OvalComponent,
      TosComponent,
      ActivateComponent,
      SSOComponent
    ];
  }

  public static getProviders() : any {
    return [
      LoginService,
      GuestGuard,
      {
        provide: HTTP_INTERCEPTORS,
        useClass: TokenInterceptor,
        multi: true
      }
    ]
  }

  public static getAppRoutes() : ModuleWithProviders {
    return RouterModule.forRoot([
      {
        path: 'login',
        children: [
          {
            path: '',
            canActivate: [ GuestGuard ],
            component: LoginComponent,
          },
          {
            path: 'activate',
            canActivate: [ GuestGuard ],
            component: ActivateComponent
          },
          {
            path: 'activate/:key',
            canActivate: [ GuestGuard ],
            component: ActivateComponent
          },
          {
            path: 'sso',
            canActivate: [ GuestGuard ],
            component: SSOComponent
          },
          {
            path: 'sso/:key',
            canActivate: [ GuestGuard ],
            component: SSOComponent
          },
        ]
      },
      {
        path: 'signup',
        canActivate: [ GuestGuard ],
        component: SignupComponent,
      }
    ]);
  }
};
