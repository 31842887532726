import * as channels from './actions';

export interface State {
  channels: any;
  channelsUpdating: boolean;
  channelTesting: boolean;
}

export const initialState: State = {
  channels: {
    browser: {
      enabled: true
    },
    email: {
      enabled: false,
      address: ""
    },
    slack: {
      enabled: false,
      hook: "",
      username: ""
    },
    pushbullet: {
      enabled: false,
      apikey: ""
    },
    telegram: {
      enabled: false,
      token: "",
      channel: ""
    },
    alexa: {
      enabled: false,
      accesscode: ""
    },
    webhook: {
      enabled: false,
      url: ""
    },
    ifttt: {
      enabled: false,
      token: ""
    },
    sms: {
      enabled: false,
      accountsid: "",
      authtoken: "",
      from: "",
      to: ""
    },
    pushover: {
      enabled: false,
      apikey: "",
      sendto: ""
    }
  },
  channelsUpdating: false,
  channelTesting: false
};

export function reducer(state = initialState, action: channels.Actions): State {
  switch (action.type) {

    case channels.REQUEST_CHANNELS: {
      return {
        ...state,
        channelsUpdating: true
      }
    }

    case channels.REQUEST_CHANNELS_FAILURE: {
      const payload = action.payload;
      return {
        ...state,
        channelsUpdating: false
      }
    }

    case channels.REQUEST_CHANNELS_SUCCESS: {
      const payload = action.payload;
      return {
        ...state,
        channels: {
          browser: {
            enabled: true
          },
          email: {
            ...initialState.channels.email,
            ...payload.email
          },
          slack: {
            ...initialState.channels.slack,
            ...payload.slack
          },
          pushbullet: {
            ...initialState.channels.pushbullet,
            ...payload.pushbullet
          },
          telegram: {
            ...initialState.channels.telegram,
            ...payload.telegram
          },
          alexa: {
            ...initialState.channels.alexa,
            ...payload.alexa
          },
          webhook: {
            ...initialState.channels.webhook,
            ...payload.webhook
          },
          ifttt: {
            ...initialState.channels.ifttt,
            ...payload.ifttt
          },
          sms: {
            ...initialState.channels.sms,
            ...payload.sms
          },
          pushover: {
            ...initialState.channels.pushover,
            ...payload.pushover
          }
        },
        channelsUpdating: false
      }
    }

    case channels.UPDATE_CHANNEL: {
      return {
        ...state,
        channelsUpdating: true
      }
    }

    case channels.UPDATE_CHANNEL_FAILURE: {
      const payload = action.payload;
      return {
        ...state,
        channelsUpdating: false
      }
    }

    case channels.UPDATE_CHANNEL_SUCCESS: {
      const payload = action.payload;
      const channel = payload.channel;
      const value = payload.payload;

      return {
        ...state,
        channels: {
          ...state.channels,
          [channel]: {
            ...state.channels[channel],
            ...value
          }
        },
        channelsUpdating: false
      }
    }

    case channels.TEST_CHANNEL: {
      return {
        ...state,
        channelTesting: false
      }
    }

    case channels.TEST_CHANNEL_FAILURE: {
      return {
        ...state,
        channelTesting: true
      }
    }

    case channels.TEST_CHANNEL_SUCCESS: {
      return {
        ...state,
        channelTesting: true
      }
    }

    default: {
      return state;
    }
  }
}

export const getChannels = (state: State) => state.channels;
export const getBrowserChannel = (state: State) => state.channels.browser;
export const getEmailChannel = (state: State) => state.channels.email;
export const getSlackChannel = (state: State) => state.channels.slack;
export const getAlexaChannel = (state: State) => state.channels.alexa;
export const getTelegramChannel = (state: State) => state.channels.telegram;
export const getPushbulletChannel = (state: State) => state.channels.pushbullet;
export const getWebhookChannel = (state: State) => state.channels.webhook;
export const getIftttChannel = (state: State) => state.channels.ifttt;
export const getSmsChannel = (state: State) => state.channels.sms;
export const getPushoverChannel = (state: State) => state.channels.pushover;
export const getChannelsUpdating = (state: State) => state.channelsUpdating;
export const getChannelTesting = (state: State) => state.channelTesting;
