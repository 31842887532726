import {Component, Input,} from '@angular/core';
import {
  MqttService,
} from '../../../../mqtt.service';


@Component({
  selector: "Io",
  templateUrl: './io.component.html',
  styleUrls: ['./io.component.scss'],
})
export class Io {

  @Input() hubKey: string;
  @Input() deviceKey: string;
  @Input() webrtc: any = null;
  @Input() onvif: boolean = true;
  @Input() talk: boolean = true;
  @Input() show: boolean = true;
  @Input() onboardIOs: Array<any> = [];
  @Input() encrypted: boolean = false;
  public outputs = [];
  public inputs = [];
  public open: boolean = false;

  constructor(private mqttService: MqttService) {
    this.openIOs = this.openIOs.bind(this);
    this.TriggerRelay = this.TriggerRelay.bind(this);
  }

  ngOnChanges(changes) {
    if (changes.onboardIOs && changes.onboardIOs.currentValue) {
      const io = changes.onboardIOs.currentValue;
      this.inputs = io.filter((i) => i.type === 'input');
      this.outputs = io.filter((i) => i.type === 'output');
    }
  }

  private openIOs(){
    this.open = !this.open;
  }

  private TriggerRelay(io){
    const topic = "kerberos/agent/" + this.hubKey;
    const payload = {
      action: "trigger-relay",
      device_id: this.deviceKey,
      value: {
        timestamp: Math.floor(Date.now() / 1000),
        token: io
      }
    };
    this.mqttService.publish(topic, payload, this.encrypted)
  }
}
