import { createSelector, createFeatureSelector } from '@ngrx/store';
import * as fromRoot from '../../../app.reducer';
import * as fromMedia from './media';
export * from './effects';

export interface MediaState {
  media: fromMedia.State;
}

export interface State extends fromRoot.State {
  media: MediaState;
}
export const Reducers = {
  media: fromMedia.reducer,
};

export const selectMediaState = createFeatureSelector<MediaState>('media');
export const selectMediaMediaState = createSelector(selectMediaState, (state: MediaState) => state.media);
export const getError = createSelector(selectMediaMediaState, fromMedia.getError);
export const getScrollPosition = createSelector(selectMediaMediaState, fromMedia.getScrollPosition);
export const getCurrentMedia = createSelector(selectMediaMediaState, fromMedia.getCurrentMedia);
export const getMedia = createSelector(selectMediaMediaState, fromMedia.getMedia);
export const getCurrentAnalysis = createSelector(selectMediaMediaState, fromMedia.getCurrentAnalysis);
export const getCurrentVault = createSelector(selectMediaMediaState, fromMedia.getCurrentVault);
export const getVault = createSelector(selectMediaMediaState, fromMedia.getVault);
export const getFilter = createSelector(selectMediaMediaState, fromMedia.getFilter);
export const getHours = createSelector(selectMediaMediaState, fromMedia.getHours);
export const getDays = createSelector(selectMediaMediaState, fromMedia.getDays);
export const canLoadMore = createSelector(selectMediaMediaState, fromMedia.canLoadMore);
export const isLoading = createSelector(selectMediaMediaState, fromMedia.isLoading);
export const isLoadingVault = createSelector(selectMediaMediaState, fromMedia.isLoadingVault);
export const isLoadingSequence = createSelector(selectMediaMediaState, fromMedia.isLoadingSequence);
export const getExportedMedia = createSelector(selectMediaMediaState, fromMedia.getExportMedia);
export const getExportedMediaCount = createSelector(selectMediaMediaState, fromMedia.getExportMediaCount);
