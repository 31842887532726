import { createSelector, createFeatureSelector } from '@ngrx/store';
import * as fromRoot from '../../../app.reducer';
import * as fromLivestream from './livestream';

export * from './effects';

export interface LivestreamState {
  livestream: fromLivestream.State;
}

export interface State extends fromRoot.State {
  livestream: LivestreamState;
}
export const Reducers = {
  livestream: fromLivestream.reducer
};

export const selectLivestreamState = createFeatureSelector<LivestreamState>('livestream');
export const selectLivestreamLivestreamState = createSelector(selectLivestreamState, (state: LivestreamState) => state.livestream);
export const getLivestream = createSelector(selectLivestreamLivestreamState, fromLivestream.getLivestream);
