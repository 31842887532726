import { ModuleWithProviders }  from '@angular/core';
import { RouterModule } from '@angular/router';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { WallService } from './wall.service';
import { WallComponent } from './wall.component';
import { GuestGuard } from '../guards/guest.guard';

export class WallRoutes {

  public static getDeclarations() : any {
    return [
      WallComponent,
    ];
  }

  public static getProviders() : any {
    return [
      WallService,
      GuestGuard,
    ]
  }

  public static getAppRoutes() : ModuleWithProviders {
    return RouterModule.forRoot([
      {
        path: 'wall',
        children: [
          {
            path: '',
            canActivate: [ GuestGuard ],
            component: WallComponent
          },
          {
            path: ':id',
            canActivate: [ GuestGuard ],
            component: WallComponent
          },
        ]
      }
    ]);
  }
};
