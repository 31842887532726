import {Component, OnInit, OnDestroy, Input, OnChanges} from '@angular/core';
import * as svg from "../../../shared/svg.inject";

@Component({
  selector: "ButtonField",
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonField implements OnInit, OnDestroy, OnChanges  {

  @Input() action: any;
  @Input() type: any;
  @Input() class: any;
  @Input() text: any;
  @Input() active: boolean = false;
  @Input() disabled: boolean = false;
  @Input() icononly: boolean = false;
  @Input() buttonType: string = 'submit';

  public classes: any;

  constructor() {}

  ngOnInit() {
    this.classes = {
      'alert': this.type === 'alert',
      'success': this.type === 'success',
      'neutral': this.type === 'neutral',
      'starred': this.type === 'starred',
      'outlined': this.type === 'outlined',
      'transparent': this.type === 'transparent',
      'submit': this.type === 'submit',
      'disabled': this.disabled,
      'active': this.active,
      'hide-text-for-mobile': this.class === 'hide-text-for-mobile'
    }
  }

  ngAfterViewInit(){
    svg.inject();
  }

  ngOnChanges(changes) {
    if(changes && changes.active) {
      this.classes = {
        ...this.classes,
        active: changes.active.currentValue,
      }
    }
  }

  ngOnDestroy(){
  }
}
