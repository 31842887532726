import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, } from '@angular/core';
import {Md5} from "ts-md5";

@Component({
  selector: "IOPicker",
  templateUrl: './iopicker.component.html',
  styleUrls: ['./iopicker.component.scss'],
})
export class IOPicker implements OnInit, OnDestroy, OnChanges  {

  @Input() icon: string;
  @Input() text: string;
  @Input() all: boolean;
  @Input() type: string = "all";
  @Input() items: any; // [{text: ..., value: ...}, ...s]
  @Input() selected: string[] = [];
  @Input() placeholder: string = "Search..";
  @Input() onChange: any;
  public open: boolean = false;
  public selectedAll: boolean = false;
  public search: string = "";
  public filteredItems: any[] = [];
  public selectedItems: string[] = [];
  public selectedValues: string[] = [];
  public gravatarSize: number = 40;

  constructor() {
    this.changeSelect = this.changeSelect.bind(this);
    this.toggleDropdown = this.toggleDropdown.bind(this);
  }

  ngOnInit(){}

  ngAfterViewInit(){}

  ngOnDestroy(){}

  ngOnChanges(changes: SimpleChanges) {
    if(changes.items){
      // We'll add the 'enabled' for now as is missing.
      const items = changes.items.currentValue;
      items.forEach(item => {
        item.enabled = true;
      });

      this.filteredItems = this.filterByName(changes.items.currentValue, this.search);
    }

    if(changes.selected && changes.selected.currentValue) {
      this.selectedItems = changes.selected.currentValue;
    }

    if(changes.all) {
      this.selectedAll = changes.all.currentValue;
    }
  }

  filterByName(items, search){
    // Filter by type before we filter by name.
    if(this.type === "inputs") {
      items = items.filter(i => i.type === "input");
    } else if(this.type === "outputs") {
      items = items.filter(i => i.type === "output");
    }

    if(search == "") {
      return items;
    }
    return items.filter(i => i.key.includes(search));
  }

  changeSearch(event) {
    const { value } = event.target;
    this.search = value;
    this.filteredItems = this.filterByName(this.items, this.search);
  }

  isSelected(value){
    return this.selectedItems.filter(s => s === value).length > 0;
  }

  changeSelect(value, event) {
    const { checked } = event.target;
    if (checked) {
      // add it
      this.selectedItems.push(value);
      // make unique
      this.selectedItems = this.selectedItems.filter((item, i, ar) => ar.indexOf(item) === i);
    } else {
      //remove it
      this.removeOccurrences(this.selectedItems, value);
    }
    // Reiterate over selectedValues.
    this.selectedValues = this.selectedItems.map(i => {
      const item = this.items.find(i2 => i2.id === i)
      if(item){
        return item.key;
      }
    })
    this.onChange(this.selectedItems);
  }

  toggleDropdown(){
    this.open = !this.open;
  }

  identify(index, item){
    return item.id;
  }

  removeOccurrences(array, item) {
    var i = array.length;
    while (i--) {
      if (array[i] === item) {
        array.splice(array.indexOf(item), 1);
      }
    }
  }
}
