import {Component, OnInit, OnDestroy, Input} from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: "Submenu",
  templateUrl: './submenu.component.html',
  styleUrls: ['./submenu.component.scss'],
  animations: [
    trigger('openClose', [
      state('true', style({ height: '*' })),
      state('false', style({ height: '0px' })),
      transition('false => true', [ animate(200) ]),
      transition('true => false', [ animate(200) ])
    ])
  ]
})
export class Submenu implements OnInit, OnDestroy  {

  @Input() title: string;
  @Input() icon: string;
  @Input() count: number = -1;

  public state = {
    'open': false
  };

  constructor() {}

  ngOnInit() {
    this.toggle = this.toggle.bind(this);
  }

  ngAfterViewInit(){
  }


  ngOnDestroy(){
  }

  open(){
    this.state.open = true;
  }

  collapse(){
    this.state.open = false;
  }

  toggle(){
    this.state.open = !this.state.open;
  }
}
