import {interval as observableInterval, Observable, timer as observableTimer} from 'rxjs';
import {takeWhile, throttle} from 'rxjs/operators';
import {Component, Inject, ViewChild} from '@angular/core';
import {Title} from '@angular/platform-browser';
import { FormControl, FormBuilder, Validators, FormGroup } from "@angular/forms";
import {Store} from '@ngrx/store';
import * as fromDevices from './devices/store';
import * as Devices from './devices/store/actions';
import * as Media from './media/store/actions';
import * as Livestream from './livestream/store/actions';
import * as fromLivestream from './livestream/store';
import * as Subscription from './subscription/store/actions';
import * as fromSubscription from './subscription/store';
import * as Profile from './profile/store/actions';
import * as Sites from './sites/store/actions';
import * as Groups from './groups/store/actions';
import * as Home from './store/actions';
import * as fromHome from './store';
import * as fromProfile from './profile/store';
import * as fromNotifications from './alerts/store';
import * as Notifications from './alerts/store/actions';
import * as SubscriptionInfo from '../shared/subscription.info';
import * as Auth from '../login/store/actions';
//import {PushNotificationsService} from 'ng-push';
import {environment, kerberosConfig} from '../../environments/environment';
import {Router} from "@angular/router";
import {MqttService as NgxMqtt} from 'ngx-mqtt';
import {MqttService} from '../shared/mqtt.service';
import {ToastService} from "../shared/toast.service";
import * as CryptoJS from 'crypto-js';  // AES encryption, symmetric

@Component({
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent {


  @ViewChild('passphrase', {static: false}) passphrase;

  public config: any;
  private alive: boolean;
  private timer: Observable<number>;
  public timerSubscription: any;
  private interval: number
  public showMenu: boolean = false;
  public showReleaseNotes: boolean;
  public motionObservable;
  public motionLegacyObservable;
  public generalObservable;

  public devices$ = this.store.select(fromDevices.getDevices);
  public devicesSubscription: any;
  public devices;

  public livestream$ = this.store.select(fromLivestream.getLivestream);
  public livestreamSubscription: any;
  public livestream;

  public detections$ = this.store.select(fromNotifications.getDetections);
  public detectionsSubscription;
  public detections;
  public selectedCameras;

  public releasenotes$ = this.store.select(fromHome.getReleaseNotes);
  public releasenotesSubscription: any;
  public releasenotes: any;
  public release: any;

  public subscription$ = this.store.select(fromSubscription.getSubscription);
  public subscriptionSubscription: any;
  public credentials: any;
  public plan: any;
  public cloudKey;
  public timeout;
  public levels = SubscriptionInfo.Levels;

  public isProduction: boolean;
  public routerSubscription: any;

  public profileSubscription: any;
  public profile$ = this.store.select(fromProfile.getProfile);
  public profile: any;
  public encryptionForm: FormGroup;
  public fingerprint: string = "";
  public encryptedFingerprint: string = "";
  public public_key: string = "";
  public symmetric_key: string = "";
  public decryptionStatus: string = "";
  public encryptionOpened: boolean = true;

  constructor(private title: Title,
              @Inject('mqttNew') private _mqttService: NgxMqtt,
              @Inject('mqttLegacy') private _mqttLegacyService: NgxMqtt,
              private mqttService: MqttService,
              private router: Router,
              private store: Store<fromDevices.State>,
              private homeStore: Store<fromHome.State>,
              //private pushNotifications: PushNotificationsService,
              private toastService: ToastService,
              private fb: FormBuilder) {
    this.config = kerberosConfig;
    this.alive = true;
    this.showMenu = false;
    this.showReleaseNotes = false;
    this.interval = 5000;
    this.timer = observableTimer(0, this.interval);
    this.openMenu = this.openMenu.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
    this.stopMqttListener = this.stopMqttListener.bind(this);
    this.logout = this.logout.bind(this);
    this.closeEncryption = this.closeEncryption.bind(this);
    this.openEncryption = this.openEncryption.bind(this);
    //this.pushNotifications.requestPermission();

    this.encryptionForm = this.fb.group({
      passphrase: new FormControl('', Validators.required),
    });

    this.detectionsSubscription = this.detections$.subscribe(detections => {
      this.detections = Object.assign({}, detections);
      if (this.detections.devicesList) {
        this.selectedCameras = this.detections.devicesList ? this.detections.devicesList.map(c => {
          return c.key
        }) : [];
      }
    });
  }

  startMqttListener() {
    const protocol: 'wss' | 'ws' = <'wss' | 'ws'> kerberosConfig.mqttProtocol;

    // TODO GET RID OF THIS!!
    // Connect to legacy MQTT
    if(kerberosConfig.mqttLegacyServer != "") {
      this._mqttLegacyService.connect({
        protocol,
        hostname: kerberosConfig.mqttLegacyServer,
        port: kerberosConfig.mqttLegacyPort,
        username: this.cloudKey,
        clientId: this.cloudKey,
        path: null
      });
    }

    // Connect to new MQTT
    this._mqttService.connect({
      protocol,
      hostname:  kerberosConfig.mqttServer,
      port:  kerberosConfig.mqttPort,
      username: kerberosConfig.mqttUsername,
      password: kerberosConfig.mqttPassword,
      clientId: this.cloudKey + this.makeid(5),
      path: '/mqtt'
    });
  }

  stopMqttListener() {
    if(this.motionObservable) {
      this.motionObservable.unsubscribe();
    }
    if(this.motionLegacyObservable) {
      this.motionLegacyObservable.unsubscribe();
    }
    if(this.generalObservable) {
      this.generalObservable.unsubscribe();
    }
    if(this._mqttService) {
      this._mqttService.disconnect();
    }
    if(kerberosConfig.mqttLegacyServer != ""){
      this._mqttLegacyService.disconnect();
    }
  }

  subscribeToMotion(){
    // Below two subscriptions are legacy and we are looking forward to use the generalSubscription as main communication line!
    if(kerberosConfig.mqttLegacyServer != "") {
      const motionLegacySubscription = this._mqttLegacyService.observe('kerberos/' + this.cloudKey + '/device/+/motion').pipe(throttle(ev => observableInterval(2500))).subscribe((message) => {
        if(this.detections.enabled) {
          const topic = message.topic.split("/")[3];
          if(this.detections.devicesAll || this.selectedCameras.includes(topic)){
            this.blink("Motion detected - ", 10);
            this.showNotification(topic);
          }
        }
      });
      this.motionLegacyObservable = motionLegacySubscription;
    }
    const motionSubscription = this._mqttService.observe('kerberos/' + this.cloudKey + '/device/+/motion').pipe(throttle(ev => observableInterval(2500))).subscribe((message) => {
      if(this.detections.enabled) {
        const topic = message.topic.split("/")[3];
        if(this.detections.devicesAll || this.selectedCameras.includes(topic)){
          this.blink("Motion detected - ", 10);
          this.showNotification(topic);
        }
      }
    });

    this.motionObservable = motionSubscription;


    // We'll move forward with a single subscription for everything.
    // Use the internal mqtt service to subscribe to general topics.
    const generalSubscription = this.mqttService.subscribe('kerberos/hub/' + this.cloudKey, (message) => {
      // We might want to consolidate all messages
      //this.store.dispatch(new Home.AddMessage(message));
      // Depending on the message we might do other things as well..
      const payload = message.payload;

      // When we receive a motion request, we'll show a notification and blink the title.
      // On top of that we'll mark the required camera as "alert notified".
      if(payload.action === "motion") {
        if(this.detections.enabled) {
          // TODO: We will need to read out the device_id from the payload!
          const device_id = payload.device_id;
          if(this.detections.devicesAll || this.selectedCameras.includes(device_id)){
            this.blink("Motion detected - ", 10);
            this.showNotification(device_id);
          }
        }
        this.store.dispatch(new Devices.Motion(payload));
      } else if(payload.action === "ptz-position") {
        this.store.dispatch(new Devices.UpdateDevicePTZPosition(payload));
      } else if(payload.action === "receive-hd-candidates") {
        this.store.dispatch(new Devices.ReceiveEvent(payload));
      } else if(payload.action === "receive-hd-answer") {
        this.store.dispatch(new Devices.ReceiveEvent(payload));
      } else if(payload.action === "receive-sd-stream") {
        this.store.dispatch(new Devices.ReceiveEvent(payload));
      } else if(payload.action === "receive-config") {
        const { value } = payload;
        this.store.dispatch(new Devices.LoadConfig(value));
      } else if(payload.action === "acknowledge-update-config") {
        // We'll delay it a bit..
        setTimeout(() => {
          this.store.dispatch(new Devices.UpdateConfigSuccess());
        }, 1000);
      }
    });
    this.generalObservable = generalSubscription;
  }

  ngOnInit(){
    this.timerSubscription = this.timer.pipe(takeWhile(() => this.alive)).subscribe(() => {
      this.store.dispatch(new Devices.RequestDevices());
      this.store.dispatch(new Media.GetDays());
      this.store.dispatch(new Profile.RequestProfile());
    });

    this.store.dispatch(new Home.RequestNotifications());
    this.store.dispatch(new Notifications.RequestNotificationsSettings());
    this.store.dispatch(new Home.RequestReleaseNotes());
    this.store.dispatch(new Subscription.RequestPlan());
    this.store.dispatch(new Subscription.RequestActivity());
    this.store.dispatch(new Subscription.RequestSettings());
    this.store.dispatch(new Livestream.RequestLivestream());
    this.store.dispatch(new Sites.RequestSites());
    this.store.dispatch(new Livestream.RequestLivestream());
    this.store.dispatch(new Groups.RequestGroups());
    this.store.dispatch(new Devices.RequestMediaDevices());

    this.isProduction = environment.production;

    this.routerSubscription = this.router.events.subscribe(() => {
       this.closeMenu();
    });

    this.devicesSubscription = this.devices$.subscribe(device => {
      this.devices = device.cameras;
    });

    this.releasenotesSubscription = this.releasenotes$.subscribe(releasenotes => {
      if(releasenotes) {
        const lastRelease = Object.keys(releasenotes).pop();
        this.release = lastRelease;
        this.releasenotes = releasenotes[lastRelease];
        this.showReleaseNotes = !(localStorage.getItem(this.release) === "true");
      }
    });

    this.livestreamSubscription = this.livestream$.subscribe(livestream => {
      this.livestream = livestream;
    });

    this.subscriptionSubscription = this.subscription$.subscribe(subscription => {

      const credentials = subscription.credentials;
      const plan = subscription.plan;

      if(credentials &&
         credentials.amazon_access_key_id !== "" &&
         this.credentials != credentials &&
         plan //&&
         //plan["level"] >= this.levels.Gold
       ) {

        this.plan = plan;
        this.credentials = credentials;
        this.cloudKey = this.credentials.amazon_access_key_id;

        this.startMqttListener();
        this.subscribeToMotion();
      }
    });

    this.profileSubscription = this.profile$.subscribe(profile => {
      if(profile) {
        this.profile = profile;
        if(profile.encryption && profile.encryption.enabled) {
          this.fingerprint = profile.encryption.fingerprint;
          this.encryptedFingerprint = profile.encryption.fingerprint_encrypted;
          this.public_key = profile.encryption.public_key;
          this.symmetric_key = profile.encryption.symmetric_key;

          if(profile.encryption.public_key !== "" && profile.encryption.public_key !== null) {
            if(!profile.encryption.has_passphrase && (this.profile.public_key === "" || this.profile.public_key === null)) {
              this.decryptionStatus = 'success';
              this.store.dispatch(new Profile.SetKeys({
                public_key: this.public_key,
                symmetric_key: this.symmetric_key
              }));
            }
          }

          if (this.encryptionOpened) {
            // We will focus in the passphrase field.
            setTimeout(() => {
              if(this.passphrase && this.passphrase.nativeElement) {
                this.passphrase.nativeElement.focus();
              }
            }, 250);
          }
        }
      }
    });
  }

  public hideReleaseNotes(){
    this.showReleaseNotes = false;
    localStorage.setItem(this.release, "true");
  }

  public blink(msg: string, count: number = 5): void {
    const prevTitle = this.title.getTitle();
    const step = () => {
      const newTitle = this.title.getTitle() === prevTitle ? msg + prevTitle : prevTitle;
      this.title.setTitle(newTitle);
      if (--count) {
        this.timeout = setTimeout(step.bind(this), 500);
      } else {
        this.title.setTitle(prevTitle);
      }
    };
    clearTimeout(this.timeout);
    step();
  }

  public showNotification(cameraId) {

    // Look for instance name.
    const camera = this.devices.find(camera => camera.key === cameraId);

    if(camera) {

      this.toastService.show("Something happened", "Movement at " + camera.name, 60000, ['livestream']);

      // Play sound. (text-to-speech)
      if(this.livestream.speech) {
        //responsiveVoice.speak("Something was detected at " + camera.name);
      }

      // This will try to show desktop notifications, however if it fails, it
      // will show a toast inside the browser.
      try {
        // Show desktop notification.
        /*this.pushNotifications.create("Something happened", {
          body: "Movement at " + camera.name,
          vibrate: [200, 100, 200, 100, 200, 100, 200],
          tag: 'notification'
        }).subscribe(res => {
          if (res.event.type === 'click') {
            // Focus the window.
            window.focus();
            this.router.navigate(['livestream']);
            // Close the notification.
            res.notification.close();
          }
        });*/
      } catch(error) {
      }
    }
  }

  verifyFingerprint() {
    const fingerprint = this.fingerprint;
    const encryptedFingerprint = this.encryptedFingerprint;
    const passphrase = this.encryptionForm.value.passphrase;
    if(!this.encryptionForm.invalid) {
      let fing = "";
      try {
         fing = CryptoJS.AES.decrypt(encryptedFingerprint, passphrase).toString(CryptoJS.enc.Utf8);
      } catch(error) {
        this.decryptionStatus = 'wrong-passphrase';
        return;
      }

      if(fingerprint === fing) {
        var public_key = CryptoJS.AES.decrypt(this.public_key, passphrase).toString(CryptoJS.enc.Utf8);
        this.public_key = public_key;
        var assymetric_key = CryptoJS.AES.decrypt(this.symmetric_key, passphrase).toString(CryptoJS.enc.Utf8);
        this.symmetric_key = assymetric_key;
        this.decryptionStatus = 'success';
        this.encryptionOpened = false;
        this.store.dispatch(new Profile.SetKeys({
          public_key: this.public_key,
          symmetric_key: this.symmetric_key
        }));
      } else {
        this.decryptionStatus = 'wrong-passphrase';
      }
    } else {
      this.decryptionStatus = 'failed';
    }
  }

  closeEncryption() {
    this.encryptionOpened = false;
  }

  openEncryption() {
    this.encryptionOpened = true;
  }

  public logout() {
    this.timerSubscription.unsubscribe();
    this.store.dispatch(new Devices.Reset());
    this.store.dispatch(new Media.Reset());
    this.store.dispatch(new Home.Reset());
    this.store.dispatch(new Profile.Reset());
    this.store.dispatch(new Subscription.Reset());
    this.store.dispatch(new Sites.Reset());
    this.store.dispatch(new Auth.Logout());
  }

  openMenu(){
    this.showMenu = !this.showMenu;
  }

  closeMenu(){
    this.showMenu = false;
  }

  makeid(length) {
    let result           = [];
    let characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let charactersLength = characters.length;
    for ( let i = 0; i < length; i++ ) {
      result.push(characters.charAt(Math.floor(Math.random() *
        charactersLength)));
    }
    return result.join('');
  }

  ngOnDestroy(){
    this.alive = false; // switches your IntervalObservable off
    if(this.timerSubscription) {
     this.timerSubscription.unsubscribe();
    }
    this.devicesSubscription.unsubscribe();
    this.releasenotesSubscription.unsubscribe();
    this.livestreamSubscription.unsubscribe();
    this.subscriptionSubscription.unsubscribe();
    this.routerSubscription.unsubscribe();
    this.profileSubscription.unsubscribe();
    this.stopMqttListener();
  }
}
