import { createSelector, createFeatureSelector } from '@ngrx/store';
import * as fromRoot from '../../../app.reducer';
import * as fromIOs from './ios';
export * from './effects';

export interface IOsState {
  ios: fromIOs.State;
}

export interface State extends fromRoot.State {
  ios: IOsState;
}
export const Reducers = {
  ios: fromIOs.reducer
};

export const selectTasksState = createFeatureSelector<IOsState>('ios');
export const selectTasksTasksState = createSelector(selectTasksState, (state: IOsState) => state.ios);
export const getState = createSelector(selectTasksTasksState, fromIOs.getState);
export const getIOs = createSelector(selectTasksTasksState, fromIOs.getIOs);
export const isLoading = createSelector(selectTasksTasksState, fromIOs.isLoading);

