import {Component, OnInit, OnDestroy, Input, OnChanges} from '@angular/core';

@Component({
  selector: "Card",
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
})
export class Card implements OnInit, OnDestroy, OnChanges  {

  @Input() title: string = "";
  @Input() subtitle: string = "";
  @Input() footer: string = "";
  @Input() icon: string = "";
  @Input() unit: string = "";
  @Input() percentage: string = "";
  @Input() button: string = "";
  @Input() buttonAction: any;

  constructor() {}

  ngOnInit() {

  }

  ngAfterViewInit(){
  }

  ngOnChanges(changes) {

  }

  ngOnDestroy(){
  }
}
