import * as releasenotes from './actions';

export interface State {
  releases: any;
}

export const initialState: State = {
  releases: null
};

export function reducer(state = initialState, action: releasenotes.Actions): State {
  switch (action.type) {

    case releasenotes.REQUEST_RELEASE_NOTES_SUCCESS: {
      return {
        ...state,
        releases: action.payload
      };
    }
    default: {
      return state;
    }
  }
}

export const getReleaseNotes = (state: State) => state.releases;
