import * as notifications from './actions';
import * as _ from "lodash";
import { not } from '@angular/compiler/src/output/output_ast';
import { time } from 'console';
import { trigger } from '@angular/animations';

export interface State {
  notifications: Array<any>;
  statistics: any;
  loading: number;
}

export const initialState: State = {
  notifications: [],
  statistics: null,
  loading: 0,
};

export function reducer(state = initialState, action: notifications.Actions): State {

  switch (action.type) {

    case notifications.REQUEST_WATCHLIST_SUCCESS: {
      const payload = action.payload;
      return {
        ...state,
        notifications: payload ? payload : []
      }
    }

    case notifications.REQUEST_FILTERED_WATCHLIST: {
      const filter = action.payload;
      if(filter.loadmore){
        return {
          ...state,
          loading: 1
        }
      }
      return state;
    }

    case notifications.REQUEST_FILTERED_WATCHLIST_FAILURE: {
      return {
        ...state,
        loading: -1
      }
    }

    case notifications.REQUEST_FILTERED_WATCHLIST_SUCCESS: {
      const filter = action.filter;
      const payload = action.payload;
      const notifications = state.notifications;

      if(filter.loadmore) {
        if(payload){
          // We will append older ones at the end.
          return {
            ...state,
            loading: 0,
            notifications: [
              ...notifications,
              ...payload
            ]
          }
        } else {
          // Nothing to load more...
          return {
            ...state,
            loading: -1
          }
        }
      } else {
        if(filter.newsearch) {
          if(payload){
            return {
              ...state,
              notifications: [...payload],
              loading: 0
            }
          } else {
            return {
              ...state,
              notifications: []
            };
          }
        } else {
          if(!payload){
            return state
          }
          // We only add the new ones in the beginning of the list.
          let timestamp = 0;
          if(notifications.length > 0) {
            const mostRecentNotification = notifications[0];
            timestamp = mostRecentNotification.timestamp;
          }
          const newNotifications = payload.filter(n => n.timestamp > timestamp);
          return {
            ...state,
            notifications: [
              ...newNotifications,
              ...state.notifications
            ]
          }
        }
      }
    }

    case notifications.GET_WATCHLIST_STATISTICS_SUCCESS: {
      const payload = action.payload;
      return {
        ...state,
        statistics: payload
      }
    }


    case notifications.EDIT_WATCHLIST_SUCCESS: {
      const payload = action.payload;
      const { id } = payload;
      let unread = 0;
      if(state.statistics) {
        unread = state.statistics.unread;
        if(payload.read){
          unread = unread - 1;
        }
      }
      return {
        ...state,
        notifications: [...state.notifications.map((n, i) => {
          if(n.id === id){
            return {...payload} ;
          } 
          return n;
        })],
        statistics: {
          ...state.statistics,
          unread: unread,
        }
      };
    }

    case notifications.READ_ALL_SUCCESS: {
      return {
        ...state,
        notifications: [...state.notifications.map((n, i) => {
          n.read = true;
          return n;
        })],
        statistics: {
          ...state.statistics,
          unread: 0,
        }
      };
    }
    
    case notifications.RESET: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}

export const getState = (state: State) => state;
export const getNotifications = (state: State) => state.notifications;
export const getNotificationsStatistics = (state: State) => state.statistics;
export const isLoading = (state: State) => state.loading;
