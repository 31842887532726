import * as ios from './actions';
import * as _ from "lodash";

export interface State {
  ios: Array<any>;
  loading: number;
}

export const initialState: State = {
  ios: [],
  loading: 0,
};

export function reducer(state = initialState, action: ios.Actions): State {

  switch (action.type) {

    case ios.REQUEST_IOS: {
      return {
        ...state,
        loading: 1
      }
    }

    case ios.REQUEST_IOS_FAILURE: {
      return {
        ...state,
        loading: 0
      }
    }

    case ios.REQUEST_IOS_SUCCESS: {
      const payload = action.payload;
      return {
        ...state,
        loading: 0,
        ios: payload.data ? payload.data : []
      }
    }

    case ios.FILTER_IOS: {
      return {
        ...state,
        loading: 1
      }
    }

    case ios.FILTER_IOS_FAILURE: {
      return {
        ...state,
        loading: 0
      }
    }

    case ios.FILTER_IOS_SUCCESS: {
      const payload = action.payload;
      return {
        ...state,
        loading: 0,
        ios: payload.data ? payload.data : []
      }
    }

    case ios.RESET: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}

export const getState = (state: State) => state;
export const getIOs = (state: State) => state.ios;
export const isLoading = (state: State) => state.loading;
