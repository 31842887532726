import {Component, Input} from '@angular/core';
import * as SubscriptionInfo from "../../../subscription.info";
import { kerberosConfig } from 'src/environments/environment';

@Component({
  selector: "DevicesDetail",
  templateUrl: './devices-detail.component.html',
  styleUrls: ['./devices-detail.component.scss'],
})
export class DevicesDetail  {
  @Input() camera: any;
  @Input() removeDevice: any;
  @Input() cloudKey: any;
  @Input() plan: any;
  @Input() user: any;
  @Input() hideSites: boolean = false;
  @Input() layout: string = 'list';
  public levels = SubscriptionInfo.Levels;
  public showStream: boolean = false;
  public config = kerberosConfig;
}
