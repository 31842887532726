import {of as observableOf,  Observable } from 'rxjs';
import {catchError, switchMap, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as Action from '../store/actions';
import { RecentRecordingsService } from '../recent-recordings.service';

@Injectable()
export class Effects {

    /* Retrieve all Recent Recordings */

    @Effect()
    requestRecordings: Observable<Action.RequestRecordingsFailure | Action.RequestRecordingsSuccess> = this.actions$.pipe(
        ofType(Action.REQUEST_RECORDINGS),
        switchMap((action: Action.RequestRecordings) => {
          return this.recentRecordingsService.getRecordings().pipe(map((groups) => {
            return new Action.RequestRecordingsSuccess(groups);
          }),
          catchError(e => {
            return observableOf(new Action.RequestRecordingsFailure(e));
          }),);
        }));

    constructor(private recentRecordingsService: RecentRecordingsService,
                private actions$: Actions) {}
}
