import { Action } from '@ngrx/store';

export const GET_DAYS = '[Day] Get days';
export const GET_DAYS_SUCCESS = '[Day] Get days Success';
export const GET_DAYS_FAILURE = '[Day] Get days Failure';
export const GET_FILTER = '[Day] Get filter';
export const SET_FILTER = '[Day] Set filter';
export const RESET = '[Day] Reset';

export class GetDays implements Action {
  readonly type = GET_DAYS;

  constructor(public payload?: any) {}
}

export class GetDaysSuccess implements Action {
  readonly type = GET_DAYS_SUCCESS;

  constructor(public payload: any) {}
}

export class GetDaysFailure implements Action {
  readonly type = GET_DAYS_FAILURE;

  constructor(public payload?: any) {}
}

// --------------------
// Get and set filter
//

export class GetFilter implements Action {
  readonly type = GET_FILTER;

  constructor(public payload?: any) {}
}

export class SetFilter implements Action {
  readonly type = SET_FILTER;

  constructor(public payload?: any) {}
}

export class Reset implements Action {
  readonly type = RESET;

  constructor() {}
}


export type Actions =
  | GetDays
  | GetDaysSuccess
  | GetDaysFailure
  | GetFilter
  | SetFilter
  | Reset;
