import {
  Component,
  ElementRef, HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';

@Component({
  selector: "MultiSelect",
  templateUrl: './multiselect.component.html',
  styleUrls: ['./multiselect.component.scss'],
})
export class MultiSelect implements OnInit, OnDestroy, OnChanges  {

  @ViewChild('searchInput', { static: false }) searchInput: ElementRef;
  @Input() icon: string;
  @Input() text: string;
  @Input() error: boolean = false;
  @Input() disabled: boolean = false;
  @Input() items: any; // [{text: ..., value: ...}, ...]
  @Input() selected: any; // [{text: ..., value: ...}, ...]
  @Input() placeholder: string = "Search..";
  @Input() onChange: any;
  public open: boolean = false;
  public search: string = "";
  public filteredItems: any[] = [];
  public selectedItems: string[] = [];
  public selectedValues: string[] = [];
  @Input() position: string = "left";

  constructor(private eRef: ElementRef) {
    this.changeSelect = this.changeSelect.bind(this);
    this.toggleDropdown = this.toggleDropdown.bind(this);
  }

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if(!this.eRef.nativeElement.contains(event.target)) {
      this.open = false;
    }
  }

  ngOnInit(){
  }

  ngAfterViewInit(){
  }

  ngOnDestroy(){
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.items){
      this.filteredItems = this.filterByName(changes.items.currentValue, this.search);
    }
    if(changes.selected) {
      this.selectedItems = changes.selected.currentValue;
      // Reiterate over selectedValues.
      this.selectedValues = this.selectedItems.map(i => {
        const item = this.items.find(i2 => i2.value === i)
        if(item){
          return item.text;
        }
      });
    }
  }

  filterByName(items, search){
    if(search == "") {
      return items;
    }
    return items.filter(i => i.text.includes(search) || i.text.toLowerCase().includes(search.toLowerCase()));
  }

  changeSearch(event) {
    const { value } = event.target;
    this.search = value;
    this.filteredItems = this.filterByName(this.items, this.search);
  }

  isSelected(value){
    return this.selectedItems.filter(s => s === value).length > 0;
  }

  changeSelect(value, event) {
    const { checked } = event.target;
    if (checked) {
      // add it
      this.selectedItems.push(value);
      // make unique
      this.selectedItems = this.selectedItems.filter((item, i, ar) => ar.indexOf(item) === i);
    } else {
      //remove it
      this.removeOccurrences(this.selectedItems, value);
    }
    // Reiterate over selectedValues.
    this.selectedValues = this.selectedItems.map(i => {
      const item = this.items.find(i2 => i2.value === i)
      if(item){
        return item.text;
      }
    });
    this.onChange(this.selectedItems);
  }

  toggleDropdown(){
    this.open = !this.open;

    if(this.open){
      setTimeout(() => {
        this.searchInput.nativeElement.focus();
      }, 100);
    }
  }

  identify(index, item){
    return item.value;
  }

  removeOccurrences(array, item) {
    var i = array.length;
    while (i--) {
      if (array[i] === item) {
        array.splice(array.indexOf(item), 1);
      }
    }
  }
}
