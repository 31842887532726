import {map, take} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '../../shared/api.service';

@Injectable()
export class WatchlistService {

  constructor(private http: HttpClient,
              private apiService: ApiService) {}

  public getNotifications() : Observable<any> {
    return this.http.get(this.apiService.getNotifications()).pipe(take(1),map(value => {
      return value;
    }),);
  }

  public editNotification(id, payload) : Observable<any> {
    return this.http.put(this.apiService.editNotification(id), payload).pipe(take(1),map(value => {
      return value;
    }),);
  }

  public getFilteredNotifications(payload) : Observable<any> {
    return this.http.post(this.apiService.getFilteredNotifications(), payload).pipe(take(1),map(value => {
      return value;
    }),);
  }

  public getNotificationsStatistics() : Observable<any> {
    return this.http.get(this.apiService.getNotificationsStatistics()).pipe(take(1),map(value => {
      return value;
    }),);
  }

  public addTask(task) : Observable<any> {
    return this.http.post(this.apiService.addTask(), { task }).pipe(take(1),map(value => {
      return value;
    }),);
  }

  public editTask(id, task) : Observable<any> {
    return this.http.put(this.apiService.editTask(id), { task }).pipe(take(1),map(value => {
      return value;
    }),);
  }

  public deleteTask(id) : Observable<any> {
    return this.http.delete(this.apiService.deleteTask(id)).pipe(take(1),map(value => {
      return value;
    }),);
  }

  public readAll() : Observable<any> {
    return this.http.post(this.apiService.readAllWatchlist(), {}).pipe(take(1),map(value => {
      return value;
    }),);
  }
}
