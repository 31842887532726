import { createSelector, createFeatureSelector } from '@ngrx/store';
import * as fromRoot from '../../../app.reducer';
import * as fromVideowalls from './videowall';

export * from './effects';

export interface VideowallState {
  videowalls: fromVideowalls.State;
}

export interface State extends fromRoot.State {
  videowalls: VideowallState;
}

export const Reducers = {
  videowalls: fromVideowalls.reducer
};

export const selectVideowallsState = createFeatureSelector<VideowallState>('videowall');
export const selectVideowallsVideowallsState = createSelector(selectVideowallsState, (state: VideowallState) => state.videowalls);
export const getVideowalls = createSelector(selectVideowallsVideowallsState, fromVideowalls.getVideowalls);
export const getVideowall = createSelector(selectVideowallsVideowallsState, fromVideowalls.getVideowall);
export const getError = createSelector(selectVideowallsVideowallsState, fromVideowalls.getError);
