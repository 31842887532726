import { Component, OnInit, OnDestroy, Input } from '@angular/core';

@Component({
  selector: 'LoadMore',
  templateUrl: './loadmore.component.html',
  styleUrls: ['./loadmore.component.scss']
})
export class LoadMore implements OnInit, OnDestroy  {

  @Input() public canLoadMore: boolean;
  @Input() public loading: boolean;
  @Input() public getMedia: Function;

  ngOnInit() {}

  ngOnDestroy(){}

  onClick() {
    this.getMedia();
  }
}
