import * as analytics from './actions';
import * as _ from "lodash";
import { not } from '@angular/compiler/src/output/output_ast';
import { time } from 'console';
import { trigger } from '@angular/animations';

export interface State {
  statistics: any;
  loading: number;
}

export const initialState: State = {
  statistics: null,
  loading: 0,
};

export function reducer(state = initialState, action: analytics.Actions): State {

  switch (action.type) {

    case analytics.REQUEST_ANALYTICS: {
      return {
        ...state,
        loading: 1
      }
    }

    case analytics.REQUEST_ANALYTICS_FAILURE: {
      return {
        ...state,
        loading: 0
      }
    }

    case analytics.REQUEST_ANALYTICS_SUCCESS: {
      const payload = action.payload;
      return {
        ...state,
        loading: 0,
        statistics: payload.data ? payload.data : []
      }
    }

    case analytics.RESET: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}

export const getState = (state: State) => state;
export const getStatistics = (state: State) => state.statistics;
export const isLoading = (state: State) => state.loading;
