import {map, take} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '../../shared/api.service';

@Injectable()
export class SubscriptionService {

  constructor(private http: HttpClient,
              private apiService: ApiService) {}

  public purchasePlan(plan, coupon) : Observable<any> {
    return this.http.post(this.apiService.purchasePlan(), { plan, coupon }).pipe(take(1),map(value => {
      return value;
    }),);
  }

  public addPaymentMethod(paymentmethod) : Observable<any> {
    return this.http.post(this.apiService.addPaymentMethod(), { paymentmethod }).pipe(take(1),map(value => {
      return value;
    }),);
  }

  public getStorage() : Observable<any> {
    return this.http.get(this.apiService.getStorage()).pipe(take(1),map(value => {
      return value;
    }),);
  }

  public updateStorage(storage) : Observable<any> {
    return this.http.post(this.apiService.updateStorage(), storage).pipe(take(1),map(value => {
      return value;
    }),);
  }

  public verifyStorage(storage) : Observable<any> {
    return this.http.post(this.apiService.verifyStorage(), storage).pipe(take(1),map(value => {
      return value;
    }),);
  }

  public changeRegion(region) : Observable<any> {
    return this.http.post(this.apiService.changeRegion(), { region }).pipe(take(1),map(value => {
      return value;
    }),);
  }

  public deletePlan(feedback) : Observable<any> {
    return this.http.post(this.apiService.deletePlan(), feedback).pipe(take(1),map(value => {
      return value;
    }),);
  }

  public cancelPlan(feedback) : Observable<any> {
    return this.http.post(this.apiService.cancelPlan(), feedback).pipe(take(1),map(value => {
      return value;
    }),);
  }

  public resumePlan() : Observable<any> {
    return this.http.post(this.apiService.resumePlan(), {}).pipe(take(1),map(value => {
      return value;
    }),);
  }

  public getPlan() : Observable<any> {
    return this.http.get(this.apiService.getPlan()).pipe(take(1),map(value => {
      return value;
    }),);
  }

  public getActivity() : Observable<any> {
    return this.http.get(this.apiService.getActivity()).pipe(take(1),map(value => {
      return value;
    }),);
  }

  public getSettings() : Observable<any> {
    return this.http.get(this.apiService.getSettings()).pipe(take(1),map(value => {
      return value;
    }),);
  }

  public validateCoupon(coupon) : Observable<any> {
    return this.http.post(this.apiService.validateCoupon(), { coupon }).pipe(take(1),map(value => {
      return value;
    }),);
  }
}
