import {Component, OnInit, OnDestroy, Input, OnChanges} from '@angular/core';

@Component({
  selector: "ModalActions",
  templateUrl: './modalactions.component.html',
  styleUrls: ['./modalactions.component.scss'],
})
export class ModalActions implements OnInit, OnDestroy, OnChanges  {

  @Input("type") type: string;
  @Input("text") text: string;
  @Input("link") link: string;
  public classes: any;

  constructor() {}

  ngOnInit(){
    this.classes = {
      info: this.type === 'info',
      error: this.type === 'error',
      pointer: this.link && this.link.length
    }
  }

  ngAfterViewInit(){
  }

  ngOnChanges(changes) {

  }

  ngOnDestroy(){
  }
}
