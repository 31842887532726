import {Component, OnInit, Input, Inject } from '@angular/core';
import { MqttService} from "ngx-mqtt";

@Component({
  selector: "Record",
  templateUrl: './record.component.html',
  styleUrls: ['./record.component.scss'],
})
export class Record implements OnInit  {

  @Input() deviceKey: string;
  @Input() onvif: boolean = true;
  @Input() talk: boolean = true;
  @Input() encrypted: boolean = false;
  public muted: boolean = true;
  public stream: Promise<void>;

  constructor(
    @Inject('mqttNew') private mqttService: MqttService) {}

  ngOnInit(){
    this.record = this.record.bind(this);
  }

  record(){
  }
}
