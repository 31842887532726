import {Component, OnInit, OnDestroy, Input, OnChanges, HostListener, ElementRef} from '@angular/core';

@Component({
  selector: "Modal",
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class Modal implements OnInit, OnDestroy, OnChanges  {

  @Input() type: string;
  @Input() text: string;
  @Input() link: string;
  @Input() fullWidth: boolean = false;
  @Input() open: boolean = false;
  @Input() closeEvent: any;
  public classes: any;

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(evt: KeyboardEvent) {
    if(this.closeEvent) {
      this.closeEvent();
    }
  }

  constructor(private eRef: ElementRef) {}

  ngOnInit(){
    this.classes = {
      info: this.type === 'info',
      error: this.type === 'error',
      pointer: this.link && this.link.length
    }
  }

  ngAfterViewInit(){
    this.open = false;
  }

  ngOnChanges(changes) {
  }

  ngOnDestroy(){
  }
}
