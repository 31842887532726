import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'Tos',
  templateUrl: './tos.component.html',
  styleUrls: ['./tos.component.scss'],

})
export class TosComponent {
  @Input() public closeTermsOfUse: Function;
  @Input() public acceptTermsOfUse: Function;
  constructor() {}

  public accept() {
    this.acceptTermsOfUse();
    this.closeTermsOfUse();
  }

  public close() {
    this.closeTermsOfUse();
  }
}
