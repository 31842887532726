
import {defer as observableDefer, of as observableOf,  Observable } from 'rxjs';
import {take, catchError, map, switchMap, delay, exhaustMap, withLatestFrom, tap} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Router  } from '@angular/router';
import * as Action from '../store/actions';
import { WallService } from '../wall.service';
import { State } from '../../app.reducer';
import { HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { LoggingService } from 'src/app/shared/logging.service';

@Injectable()
export class Effects {

  public isDemo: boolean = environment.demo;

  /* Get the videowall */

  @Effect()
  videowall: Observable<Action.GetVideowallSuccess | Action.GetVideowallFailure> = this.actions$.pipe(
    ofType(Action.GET_VIDEOWALL),
    switchMap((action: Action.GetVideowall) => {
      const payload = action.payload;
      return this.wallService.decryptVideowall(payload).pipe(map((payload) => {
        return new Action.GetVideowallSuccess({videowall: payload.data});
      }),
      catchError((e: HttpErrorResponse) => {
        return observableOf(new Action.GetVideowallFailure(e.error.message));
      }),);
    }),);

    constructor(private wallService: WallService,
              private store$: Store<State>,
              private actions$: Actions,
              private router: Router,
              private log: LoggingService) {}
}
