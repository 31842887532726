import {Component, } from '@angular/core';
import {Router} from '@angular/router';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {environment, kerberosConfig} from '../../environments/environment';
import {patternValidator} from '../shared/pattern.validator';
import {DeviceDetectorService} from 'ngx-device-detector';
import {Store} from '@ngrx/store';
import {Authenticate} from './models/user';
import * as fromAuth from './store';
import * as Auth from './store/actions';
import * as Analytics from '../home/analytics/store/actions';
import * as Media from '../home/media/store/actions';
import * as WatchList from '../home/watchlist/store/actions';
import * as Tasks from '../home/tasks/store/actions';
import * as Day from '../home/day/store/actions';
import * as Devices from '../home/devices/store/actions';
import * as Sites from '../home/sites/store/actions';
import * as Groups from '../home/groups/store/actions';
import * as Alerts from '../home/alerts/store/actions';
import * as Counting from '../home/counting/store/actions';
import {HttpClient} from '@angular/common/http';

@Component({
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {

  public isSSO: boolean = false;
  public redirectToDomain: boolean = false;
  public ssoDomains: string = "";
  public ssoForceDomains: string = "";
  public domainName: string = "";
  public domain: string = "";
  public isPrivate;
  public loginPageError$= this.store.select(fromAuth.getLoginPageError);
  public loginPageErrorSubscription: any;
  public loginPageError: any = null;
  public loginPageState$ = this.store.select(fromAuth.getLoginPagePending);

  public forgot$ = this.store.select(fromAuth.getForgot);
  public forgotSubscription: any;
  public forgot: any;
  public forgotError$ = this.store.select(fromAuth.getForgotError);
  public forgotErrorSubscription: any;
  public forgotError: any;
  public forgotPending$ = this.store.select(fromAuth.getForgotPending);
  public forgotPending: any;

  public mfaRequired$ = this.store.select(fromAuth.getMFARequired);
  public mfaRequiredSubscription: any;
  public mfaRequired: any;

  public mfaRequest$ = this.store.select(fromAuth.getMFARequest);
  public mfaRequestSubscription;
  public mfaRequest: any = {};

  public forceMFA$ = this.store.select(fromAuth.getForceMFA);
  public forceMFASubscription: any;
  public forceMFA: any;

  public ssoDomains$ = this.store.select(fromAuth.getSSODomains);
  public ssoDomainsSubscription: any;
  public ssoDomainsArray: any;
  public ssoForceArray: any;
  public forceSSO: boolean = false;

  public loginForm: FormGroup;
  public forgotForm: FormGroup;
  public secretForm: FormGroup;
  public showTermsOfUse: boolean = false;
  public showForgottenForm: boolean = false;
  public isDemo = environment.demo;
  public tos: boolean = false;
  public deviceInfo;
  public ip;
  public userProfile;
  public logoName;
  public loginDescription;
  public loginCopyright;
  public config: any;
  public validationForm;

  constructor(private http: HttpClient,
              private router: Router,
              private builder: FormBuilder,
              private store: Store<fromAuth.State>,
              private deviceService: DeviceDetectorService,
              private fb: FormBuilder) {

    this.config = kerberosConfig;

    if(this.config.multiTenant) {
      const currentUrl = window.location.href; // "https://msnet.kerberos.io/login"; //
      const currentUrlParts = currentUrl.split("/");
      if(currentUrlParts.length >= 3) {
        const hostname = currentUrlParts[2];
        if(hostname !== "") {
          // Get rid of the base domain, so we have the "subdomain".
          this.domain = hostname.replace(this.config.tenantBaseDomain, "");
          // we will also remove the "." in front of the base domain.
          this.domain = this.Trim(this.domain, ".");
        }
      }
    }

    this.isPrivate = kerberosConfig.privateEdition;
    this.logoName = kerberosConfig.logo;
    this.loginDescription = kerberosConfig.loginDescription;
    this.loginCopyright = kerberosConfig.loginCopyright;
    this.ssoDomains = kerberosConfig.ssoDomains;
    this.ssoForceDomains = kerberosConfig.ssoForceDomains;
    this.checkEmail = this.checkEmail.bind(this)
    this.checkDomain = this.checkDomain.bind(this);
    this.validateQRCode = this.validateQRCode.bind(this);
    this.closeMFAEnabledSuccess = this.closeMFAEnabledSuccess.bind(this);
    this.closeMFAEnabledError = this.closeMFAEnabledError.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.redirectToDomainPage = this.redirectToDomainPage.bind(this);
    this.redirectoToSSO = this.redirectoToSSO.bind(this);

    this.store.dispatch(new Auth.GetSSODomains());

    // Get device information and ip address
    this.http.get("https://api.ipify.org?format=json").subscribe(ip => {
      this.deviceInfo = this.deviceService.getDeviceInfo();
      this.ip = ip['ip'];
    });

    // https://api.ipify.org?format=json

    this.loginForm = builder.group({
      "domain": [""],
      "username": ["", Validators.required],
      "password": ["", Validators.required]
    });

    if(this.isDemo) {
      this.loginForm.setValue({
        domain: "",
        username: "demo",
        password: "demodemo"
      });
    }

    this.forgotForm = builder.group({
      "usernameOrEmail": ["", [Validators.required, , patternValidator(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]],
    });

    this.secretForm = builder.group({
      "secret": ["", Validators.required]
    });

    this.loginPageErrorSubscription = this.loginPageError$.subscribe(error => {
      this.loginPageError = error;
    });

    this.forgotSubscription = this.forgot$.subscribe(forgot => {
      this.forgot = forgot;
    });

    this.forgotErrorSubscription = this.forgotError$.subscribe(error => {
      this.forgotError = error;
    });

    this.mfaRequiredSubscription = this.mfaRequired$.subscribe(mfaRequired => {
      this.mfaRequired = mfaRequired;
    });

    this.forceMFASubscription = this.forceMFA$.subscribe(forceMFA => {
      this.forceMFA = forceMFA;
    });

    this.mfaRequestSubscription = this.mfaRequest$.subscribe(mfaRequest => {
      this.mfaRequest = mfaRequest;
      // If we got the success message we'll show the login page again ;)
      if (mfaRequest.success) {
        this.showForgottenForm = false;
        this.showTermsOfUse = false;
        this.forceMFA = false;
      }
    });

    this.ssoDomainsSubscription = this.ssoDomains$.subscribe(ssoDomains => {
      this.ssoDomainsArray = ssoDomains.domains;
      this.ssoForceArray = ssoDomains.forceSSO;

      this.forceSSO = false;
      // Check if we are in multi tenant mode, and if so, we need to check if the domain is in the ssoForceArray.
      if(this.config.multiTenant) {
        if(this.ssoForceArray.length > 0) {
          for (let i = 0; i < this.ssoForceArray.length; i++) {
            const domain = this.ssoForceArray[i];
            if(domain !== "" && this.domain === domain) {
              this.forceSSO = true;
              this.domainName = domain;
            }
          }
        }
      }
    });
  }

  private Trim(str, ch) {
    var start = 0,
        end = str.length;
    while(start < end && str[start] === ch)
        ++start;
    while(end > start && str[end - 1] === ch)
        --end;
    return (start > 0 || end < str.length) ? str.substring(start, end) : str;
  }

  public checkEmail($event){
    const { value } = $event.target;
    this.isSSO = false;
    const splitted = this.ssoDomains.split(";");
    // SSO domains can also be added in the backend, so we need to check those as well.
    if (this.ssoDomainsArray.length > 0) {
      for (let i = 0; i < this.ssoDomainsArray.length; i++) {
        const domain = this.ssoDomainsArray[i];
        splitted.push(domain);
      }
    }
    for (let i = 0; i < splitted.length; i++) {
      const domain = splitted[i];
      if(domain !== "" && value.indexOf("@"+domain) >= 0) {
        this.isSSO = true;
        this.domainName = domain;
      }
    }
  }

  public checkDomain($event){
    const { value } = $event.target;
    this.redirectToDomain = false;
    this.domainName = value;
    const splitted = this.ssoDomains.split(";");
    // SSO domains can also be added in the backend, so we need to check those as well.
    if (this.ssoDomainsArray.length > 0) {
      for (let i = 0; i < this.ssoDomainsArray.length; i++) {
        const domain = this.ssoDomainsArray[i];
        splitted.push(domain);
      }
    }
    for (let i = 0; i < splitted.length; i++) {
      const domain = splitted[i];
      if(domain !== "" && value === domain){
        this.redirectToDomain = true;
      }
    }
  }

  public onSubmit($event: Authenticate) : void {
    // Redirect to SSO url ;)
    const values = this.loginForm.value;
    let sso = false;

    let ssoDomainsSplitted = [];
    if(this.ssoDomains !== "") {
      ssoDomainsSplitted = this.ssoDomains.split(";");
    }

    // SSO domains can also be added in the backend, so we need to check those as well.
    if (this.ssoDomainsArray.length > 0) {
      for (let i = 0; i < this.ssoDomainsArray.length; i++) {
        const domain = this.ssoDomainsArray[i];
        ssoDomainsSplitted.push(domain);
      }
    }

    if(ssoDomainsSplitted.length > 0) {
      let detectedDomain = "";
      for (let i = 0; i < ssoDomainsSplitted.length; i++) {
        const domain = ssoDomainsSplitted[i];
        if(domain !== "" && values.username.indexOf("@"+domain) >= 0) {
          sso = true;
          detectedDomain = domain;
        }
      }
      if (detectedDomain !== "") {
        window.location.href = kerberosConfig.newApiUrl + '/sso/' + detectedDomain;
      }
    }

    // Reset the store
    this.store.dispatch(new Auth.Reset());
    this.store.dispatch(new Media.Reset());
    this.store.dispatch(new Day.Reset());
    this.store.dispatch(new WatchList.Reset());
    this.store.dispatch(new Tasks.Reset());
    this.store.dispatch(new Devices.Reset());
    this.store.dispatch(new Sites.Reset());
    this.store.dispatch(new Groups.Reset());
    this.store.dispatch(new Alerts.Reset());
    this.store.dispatch(new Counting.Reset());
    this.store.dispatch(new Analytics.Reset());

    if (!sso){
      if (environment.production && !this.isDemo && values.username === 'demo') {
        console.log("Sorry not letting in a demo user.")
      } else {
        let username = values.username;
        let domain = this.domain;
        if(domain === "") {
          domain = values.domain;
        }
        this.store.dispatch(new Auth.Login({
          username,
          password: values.password,
          provider: "password",
          deviceInfo: this.deviceInfo,
          ip: this.ip,
          domain,
        }));
      }
    }
  }

  public onSubmitSecret($event: Authenticate) : void {
    const valuesLogin = this.loginForm.value;
    const valuesSecret = this.secretForm.value;

    let username = valuesLogin.username;
    let domain = this.domain;
    if(domain === "") {
      domain = valuesLogin.domain;
    }

    this.store.dispatch(new Auth.Login({
      username: username, // we need to send again the username and password.
      password: valuesLogin.password, // we need to send again the username and password.
      provider: "password",
      deviceInfo: this.deviceInfo,
      ip: this.ip,
      domain,
      secret: valuesSecret.secret,
    }));
  }

  public onForgot($event) : void {
    const values = this.forgotForm.value;
    this.store.dispatch(new Auth.Forgot({
      usernameOrEmail: values.usernameOrEmail,
      domain: this.domain
    }));
  }

  public openTermsOfUse() {
    this.showTermsOfUse = true;
  }

  public closeTermOfUse() {
    this.showTermsOfUse = false;
  }

  public closeError() {
    this.loginPageError = null;
  }

  public closeMFAEnabledSuccess() {
    this.mfaRequest.success = false;
  }

  public closeMFAEnabledError() {
    this.mfaRequest.failed = false;
  }

  public closeForgotSuccess() {
    this.forgot = null;
  }

  public closeForgotError() {
    this.forgotError = null;
  }

  public openForgotForm() {
    this.showForgottenForm = true;
    this.store.dispatch(new Auth.ResetMFA());
  }

  public closeForgotForm() {
    this.showForgottenForm = false;
  }

  public closeSecretForm() {
    this.store.dispatch(new Auth.ResetMFA());
  }

  public logout() : void {
    this.store.dispatch(new Auth.Logout());
  }

  public ngOnInit() {
    this.validationForm = this.fb.group({
      code: new FormControl('', Validators.required)
    });
  }

  validateQRCode() {
    if(this.validationForm.valid) {
      const data = {
        secret: this.validationForm.get('code').value,
        userId: this.mfaRequest.user
      };
      if(!this.isDemo) {
        this.store.dispatch(new Auth.ValidateMFA(data));
      }
    }
    return false;
  }

  redirectToDomainPage() {
    // Get protocol from url
    let protocol = window.location.protocol;
    const url = protocol + '//' + this.domainName + "." + kerberosConfig.tenantBaseDomain;
    window.location.href = url
    return false
  }

  redirectoToSSO() {
    const domain = this.domainName;
    window.location.href = this.config.newApiUrl + '/sso/' + domain;
    return false;
  }

  public ngOnDestroy() {
    this.loginPageErrorSubscription.unsubscribe();
    this.forgotSubscription.unsubscribe();
    this.forgotErrorSubscription.unsubscribe();
    this.mfaRequiredSubscription.unsubscribe();
    this.forceMFASubscription.unsubscribe();
    this.mfaRequestSubscription.unsubscribe();
    this.ssoDomainsSubscription.unsubscribe();
  }
}
