import * as messages from './actions';

export interface Message {
  id: number;
  value: any
}

export interface State {
  messages: Array<Message>;
}

export const initialState: State = {
  messages: [] as Array<Message> // []
};

export function reducer(state = initialState, action: messages.Actions): State {
  switch (action.type) {
    case messages.ADD_MESSAGE: {
      const payload = action.payload;
      const message = {
        id: payload.action,
        value: payload.value
      }
      const messages = state.messages
      return {
        ...state,
        messages: [...messages, message]
      };
    }
    default: {
      return state;
    }
  }
}

export const getMessages = (state: State) => state.messages;
