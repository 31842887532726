import { createSelector, createFeatureSelector } from '@ngrx/store';
import * as fromRoot from '../../../app.reducer';
import * as fromRoles from './roles';

export * from './effects';

export interface RolesState {
  roles: fromRoles.State;
}

export interface State extends fromRoot.State {
  roles: RolesState;
}

export const Reducers = {
  roles: fromRoles.reducer
};

export const selectRolesState = createFeatureSelector<RolesState>('roles');
export const selectRolesRolesState = createSelector(selectRolesState, (state: RolesState) => state.roles);
export const getRoles = createSelector(selectRolesRolesState, fromRoles.getRoles);
export const getError = createSelector(selectRolesRolesState, fromRoles.getError);
//export const getSite = createSelector(selectSitesSitesState, fromSites.getSite);
