import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, } from '@angular/core';
import {FormControl, FormGroup} from "@angular/forms";
import {MatDatepickerInputEvent} from "@angular/material/datepicker";

@Component({
  selector: "DateInputField",
  templateUrl: './dateinput.component.html',
  styleUrls: ['./dateinput.component.scss'],
})
export class DateInputField implements OnInit, OnDestroy, OnChanges  {

  @Input() error: boolean;
  @Input() icon: string;
  @Input() label: string;
  @Input() value: string;
  @Input() currentDate: any;
  @Input() onChange: any;
  @Input() minDate: any;
  @Input() maxDate: any;
  @Input() controlName: any;
  @Input() parentForm: FormGroup = null;
  public date: FormControl;

  ngOnInit(){
  }

  ngAfterViewInit(){
  }

  ngOnDestroy(){
  }

  ngOnChanges(change: SimpleChanges){
    if(change.currentDate) {
      this.date.setValue(change.currentDate.currentValue);
    }
  }

  addEvent(type: string, event: MatDatepickerInputEvent<Date>) {
    this.onChange(event.value);
  }
}
