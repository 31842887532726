import { Component, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { Store } from '@ngrx/store';
import * as fromAuth from './store';
import * as Auth from './store/actions';

@Component({
  templateUrl: './activate.component.html',
  styleUrls: ['./activate.component.scss'],
})
export class ActivateComponent {

  public pincode: string = "";

  public status: string = "info";

  public key: string = "";
  public routeSubscription : any;

  @ViewChild('char1', { static: true }) char1: ElementRef;
  @ViewChild('char2', { static: true }) char2: ElementRef;
  @ViewChild('char3', { static: true }) char3: ElementRef;
  @ViewChild('char4', { static: true }) char4: ElementRef;
  @ViewChild('char5', { static: true }) char5: ElementRef;

  public activateStatus$= this.store.select(fromAuth.getActivateStatus);
  public activateStatus;
  public activateStatusSubscription;

  public activatePending$= this.store.select(fromAuth.getActivatePending);
  public activatePending;
  public activatePendingSubscription;

  public occupied: Array<boolean>;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private builder: FormBuilder,
              private store: Store<fromAuth.State>,
              private cdr: ChangeDetectorRef) {

      this.occupied = [false, false, false, false, false];
  }

  public ngOnInit(){
    this.activateStatusSubscription = this.activateStatus$.subscribe(status => {
      this.activateStatus = status;
    });

    this.activatePendingSubscription = this.activatePending$.subscribe(pending => {
      this.activatePending = pending;
      this.cdr.detectChanges();
    });
  }

  public ngAfterViewInit() {
    this.routeSubscription = this.route.params.subscribe(params => {
      this.key = params['key'];

      if(!this.key || this.key == "") {
        this.char1.nativeElement.focus();
      }

      if(this.key && this.key.length === 5) {
        this.pincode = this.key;
        this.sendActivationPin();
      }

      for(let i = 0; i < this.key.length; i++){
        if(i==0){
          this.occupied[0] = true;
          this.char1.nativeElement.value = this.key[0]
        } else if(i==1){
          this.occupied[1] = true;
          this.char2.nativeElement.value = this.key[1]
        } else if(i==2){
          this.occupied[2] = true;
          this.char3.nativeElement.value = this.key[2]
        } else if(i==3){
          this.occupied[3] = true;
          this.char4.nativeElement.value = this.key[3]
        } else if(i==4){
          this.occupied[4] = true;
          this.char5.nativeElement.value = this.key[4]
        }
      }
    });
  }

  public checkIfFirst(e) {
    if(e.keyCode != 8){
      let firstOccupied = null;
      for(let i = 0; i < this.occupied.length; i++){
        if(!this.occupied[i]) {
          firstOccupied = i;
          break;
        }
      }

      if(firstOccupied == 0) {
        this.char1.nativeElement.focus();
      } else if(firstOccupied == 1) {
        this.char2.nativeElement.focus();
      } else if(firstOccupied == 2) {
        this.char3.nativeElement.focus();
      } else if(firstOccupied == 3) {
        this.char4.nativeElement.focus();
      } else if(firstOccupied == 4) {
        this.char5.nativeElement.focus();
      }
    }
  }

  public goBack(e, prevElement: string) {
    if(e.keyCode == 8){
      this.store.dispatch(new Auth.ResetActivate());

      if(prevElement === 'start') {
        this.occupied[0] = false;
      } else if(prevElement === 'char1') {
        this.occupied[1] = false;
        this.char1.nativeElement.focus();
      } else if(prevElement === 'char2') {
        this.occupied[2] = false;
        this.char2.nativeElement.focus();
      } else if(prevElement === 'char3') {
        this.occupied[3] = false;
        this.char3.nativeElement.focus();
      } else if(prevElement === 'char4') {
        this.occupied[4] = false;
        this.char4.nativeElement.focus();
      }
      return false;
    }
    return true;
  }

  public goToNext(e, nextElement: string) {
    // Check if only one character, if more takes last one.
    const value = e.target.value;
    if(value.length > 1) {
      e.target.value = value.substr(value.length-1);
    }

    // Reset value if pressing a special character (other than controls)
    if ((e.which > 90 || e.which < 48) &&
         e.keyCode != 9 && // backspace
         e.keyCode != 8 && // tab
         e.keyCode != 16 && // shift
         e.keyCode != 17 && // ctrlt
         e.keyCode != 18 && // alt
         e.keyCode != 19 && // pause break
         e.keyCode != 20 && // capslock
         e.keyCode != 27 && // escape
         e.keyCode != 33 && // pageup
         e.keyCode != 34 // pagedown
    ) {
      e.target.value = "";
    }

    // On letter or number go to next field
    if (e.which <= 90 && e.which >= 48) {

      this.pincode = this.char1.nativeElement.value +
                      this.char2.nativeElement.value +
                      this.char3.nativeElement.value +
                      this.char4.nativeElement.value +
                      this.char5.nativeElement.value;

      if(nextElement === 'char2') {
        if(this.char1.nativeElement.value !== ""){
          this.occupied[0] = true;
        }
        this.char2.nativeElement.focus();
      } else if(nextElement === 'char3') {
        if(this.char2.nativeElement.value !== ""){
          this.occupied[1] = true;
        }
        this.char3.nativeElement.focus();
      } else if(nextElement === 'char4') {
        if(this.char3.nativeElement.value !== ""){
          this.occupied[2] = true;
        }
        this.char4.nativeElement.focus();
      } else if(nextElement === 'char5') {
        if(this.char4.nativeElement.value !== ""){
          this.occupied[3] = true;
        }
        this.char5.nativeElement.focus();
      } else if(nextElement === 'end') {
        if(this.char5.nativeElement.value !== ""){
          this.occupied[4] = true;
        }
        this.char5.nativeElement.focus();
      }

      // If a valid pin is entered we'll try to verify it
      if(this.pincode.length == 5 &&
        this.char1.nativeElement.value !== "" &&
        this.char2.nativeElement.value !== "" &&
        this.char3.nativeElement.value !== "" &&
        this.char4.nativeElement.value !== "" &&
        this.char5.nativeElement.value !== ""){
          this.sendActivationPin();
        }
    }
  }

  public sendActivationPin() {
    this.store.dispatch(new Auth.Activate(this.pincode.toUpperCase()));
  }

  public ngOnDestroy() {
    this.routeSubscription.unsubscribe();
    this.activateStatusSubscription.unsubscribe();
    this.activatePendingSubscription.unsubscribe();
  }
}
