import {Component, OnInit, OnDestroy, Input, SimpleChange} from '@angular/core';

@Component({
  selector: "SliderField",
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
})
export class SliderField implements OnInit, OnDestroy  {

  @Input() change: any;
  @Input() value: boolean = false;
  @Input() disabled: boolean = false;
  @Input() model: any = null;

  constructor() {
    this.changeValue = this.changeValue.bind(this);
  }

  ngOnInit(){

  }

  ngAfterViewInit(){
  }


  ngOnDestroy(){
  }

  ngOnChanges(changes: any){
    if(changes.model){
      this.model = changes.model.currentValue;
    }
  }

  changeValue(event){
    if(this.change) {
      if(this.model) {
        this.change(this.model, event.target.checked);
      } else {
        this.change(event.target.checked);
      }
    }
  }
}
