import {Component, OnInit, OnDestroy, Input, ViewChild, ElementRef} from '@angular/core';
import {FormGroup} from "@angular/forms";
import { fromEvent } from 'rxjs';
import { debounceTime, filter, distinctUntilChanged, tap } from 'rxjs/operators';

@Component({
  selector: "InputField",
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
})
export class InputField {

  @ViewChild('input', { static: false }) input: ElementRef;
  @Input() label: any;
  @Input() value: any;
  @Input() readonly: boolean = false;
  @Input() loading: boolean = false;
  @Input() error: boolean = false;
  @Input() placeholder: any;
  @Input() controlName: any;
  @Input() parentForm: FormGroup = null;
  @Input() onChange: any;

  ngAfterViewInit(){
    if(this.input) {
      fromEvent(this.input.nativeElement,'keyup')
      .pipe(
          debounceTime(500),
          distinctUntilChanged(),
          tap(() => {
            const {value} = this.input.nativeElement;
            if(this.onChange){
              this.onChange(value);
            }
          })
      )
      .subscribe();
    }
  }
}
