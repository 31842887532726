import { createSelector, createFeatureSelector } from '@ngrx/store';
import * as fromRoot from '../../../app.reducer';
import * as fromTasks from './tasks';

export * from './effects';

export interface TasksState {
  tasks: fromTasks.State;
}

export interface State extends fromRoot.State {
  tasks: TasksState;
}
export const Reducers = {
  tasks: fromTasks.reducer
};

export const selectTasksState = createFeatureSelector<TasksState>('tasks');
export const selectTasksTasksState = createSelector(selectTasksState, (state: TasksState) => state.tasks);
export const getState = createSelector(selectTasksTasksState, fromTasks.getState);
export const getTasks = createSelector(selectTasksTasksState, fromTasks.getTasks);
export const getTasksFiltered = createSelector(selectTasksTasksState, fromTasks.getTasksFiltered);
export const getTasksSuccess = createSelector(selectTasksTasksState, fromTasks.getTasksSuccess);
export const getStatistics = createSelector(selectTasksTasksState, fromTasks.getTasksStatistics);
