import { Action } from '@ngrx/store';

export const RESET = '[Analytics] Reset';
export const RESET_MESSAGE = '[Analytics] Hide success';
export const REQUEST_ANALYTICS = '[Analytics] Requesting analytics';
export const REQUEST_ANALYTICS_SUCCESS = '[Analytics] Requesting analytics Success';
export const REQUEST_ANALYTICS_FAILURE = '[Analytics] Requesting analytics Failure';

export class Reset implements Action {
  readonly type = RESET;

  constructor() {}
}

export class ResetMessage implements Action {
  readonly type = RESET_MESSAGE;

  constructor() {}
}

export class RequestAnalytics implements Action {
  readonly type = REQUEST_ANALYTICS;

  constructor(public payload: any) {}
}

export class RequestAnalyticsSuccess implements Action {
  readonly type = REQUEST_ANALYTICS_SUCCESS;

  constructor(public payload: any) {}
}

export class RequestAnalyticsFailure implements Action {
  readonly type = REQUEST_ANALYTICS_FAILURE;

  constructor(public payload?: any) {}
}

export type Actions =
  | Reset
  | ResetMessage
  | RequestAnalytics
  | RequestAnalyticsSuccess
  | RequestAnalyticsFailure;
