import {
  animate,
  keyframes,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';

import { kerberosConfig } from '../../environments/environment';
import { Component } from '@angular/core';
import { Toast, ToastrService, ToastPackage } from 'ngx-toastr';

@Component({
  selector: '[pink-toast-component]',
  styles: [`
    :host {
      background-color: #fff;
      position: relative;
      overflow: hidden;
      margin: 20px 0 6px;
      padding: 10px 10px 10px 10px;
      width: 300px;
      border-radius: 3px 3px 3px 3px;
      color: #333;
      pointer-events: all;
      cursor: pointer;
    }
    .row {
      margin-left: 10px;
    }
    .open {
      text-transform: uppercase;
      font-size: 13px;
      color: #92403a !important;
      font-weight: 600;
      display: table;
      padding-top: 3px;
    }
    .remove {
      transform: rotate(45deg);
      font-size: 30px;
      font-family: 'Lato', sans-serif;
      position: absolute;
      top: 1px;
      right: 7px;
      color: #ccc;
      display: table;
      width: 20px;
    }
  `],
  template: `
  <div class="row">
    <div class="col-3" *ngIf="logoName === ''">
      <img width="36px" src="../assets/images/logos/header-minimal-logo-36x36.svg" alt=""/>
    </div>
    <div class="col-3" *ngIf="logoName === 'custom'">
      <img width="36px" src="../assets/custom/logo-sidebar.svg"  alt=""/>
      <img width="36px" src="../assets/custom/logo-sidebar.png"  alt=""/>
      <img width="36px" src="../assets/custom/logo-sidebar.jpg"  alt=""/>
    </div>
    <div class="col-9">
      <div *ngIf="title" [class]="options.titleClass" [attr.aria-label]="title">
        {{ message }}
      </div>
      <div *ngIf="message && options.enableHtml" role="alert" aria-live="polite"
        [class]="options.messageClass" [innerHTML]="message">
      </div>
      <div *ngIf="message && !options.enableHtml" role="alert" aria-live="polite"
        [class]="options.messageClass" [attr.aria-label]="message">
        {{ title }}<br/>
        <a (click)="action($event)" class="open">open link</a>
      </div>
    </div>
  </div>
  <div *ngIf="options.progressBar">
    <div class="toast-progress" [style.width]="width + '%'"></div>
  </div>
  <div class="remove">
    <a (click)="remove()">+</a>
  </div>
  `,
  animations: [
    trigger('flyInOut', [
      state('inactive', style({
        display: 'none',
        opacity: 0
      })),
      transition('inactive => active', animate('400ms ease-out', keyframes([
        style({
          transform: 'translate3d(100%, 0, 0) skewX(-30deg)',
          opacity: 0,
        }),
        style({
          transform: 'skewX(20deg)',
          opacity: 1,
        }),
        style({
          transform: 'skewX(-5deg)',
          opacity: 1,
        }),
        style({
          transform: 'none',
          opacity: 1,
        }),
      ]))),
      transition('active => removed', animate('400ms ease-out', keyframes([
        style({
          opacity: 1,
        }),
        style({
          transform: 'translate3d(100%, 0, 0) skewX(30deg)',
          opacity: 0,
        }),
      ]))),
    ]),
  ],
  preserveWhitespaces: false,
})
export class ToastCustom extends Toast {
  // constructor is only necessary when not using AoT

  public logoName;

  constructor(
    protected toastrService: ToastrService,
    public toastPackage: ToastPackage,
  ) {
    super(toastrService, toastPackage);
    this.logoName = kerberosConfig.logo;
  }

  action(event: Event) {
    this.toastPackage.triggerAction(event);
  }
}
