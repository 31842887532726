import {take, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { ApiService } from '../../shared/api.service';

@Injectable()
export class LocationsService {

  constructor(private http: HttpClient,
              private apiService: ApiService) {}

  public getDevices() : Observable<any> {
    return this.http.get(this.apiService.getDevices()).pipe(take(1),map(value => {
      return value;
    }),);
  }

  public findDevice(device) : Observable<any> {
    return this.http.get(this.apiService.findDevice(device)).pipe(take(1),map(value => {
      return value;
    }),);
  }

  public getLatestVersion() : Observable<any> {
    const url = "https://api.github.com/repos/kerberos-io/machinery/releases";

    // Disable Authorization, we request to another API.
    const httpOptions = {
      headers: new HttpHeaders({
        'X-No-Authorization': 'true'
      })
    };

    return this.http.get(url, httpOptions).pipe(take(1),map(version => {
      const firstVersion = version[0];
      return firstVersion.tag_name;
    }),);
  }

  public removeDevice(device) : Observable<any> {
    return this.http.delete(this.apiService.deleteDevice(device)).pipe(take(1),map(value => {
      return value;
    }),);
  }

  public muteDevice(device, payload) : Observable<any> {
    const mute = payload;
    return this.http.post(this.apiService.muteDevice(device), { mute }).pipe(take(1),map(value => {
      return value;
    }));
  }

  public updateDevice(device, payload) : Observable<any> {
    return this.http.put(this.apiService.updateDevice(device), { payload }).pipe(take(1),map(value => {
      return value;
    }));
  }
}
