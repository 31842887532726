
import {take, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { ApiService } from '../../shared/api.service';

@Injectable()
export class ChannelsService {

  constructor(private http: HttpClient,
              private apiService: ApiService) {}

  public getChannels() : Observable<any> {
    return this.http.get(this.apiService.getChannels()).pipe(take(1),map(value => {
      return value;
    }),);
  }

  public updateChannels(channels) : Observable<any> {
    return this.http.put(this.apiService.updateChannels(), {channels}).pipe(take(1),map(value => {
      return value;
    }),);
  }

  public testChannel(channel) : Observable<any> {
    return this.http.post(this.apiService.testChannel(), {channel}).pipe(take(1),map(value => {
      return value;
    }),);
  }
}
