import {Component, OnInit, OnDestroy, Input, OnChanges} from '@angular/core';

@Component({
  selector: "KPI",
  templateUrl: './kpi.component.html',
  styleUrls: ['./kpi.component.scss'],
})
export class KPI implements OnInit, OnDestroy, OnChanges  {

  @Input() number: string;
  @Input() divider: string;
  @Input() footer: string;
  @Input() unit: string;
  @Input() progress: string;
  @Input() progressValue: string;
  @Input() withDivider: boolean = true;

  constructor() {}

  ngOnInit(){

  }

  ngAfterViewInit(){
  }

  ngOnChanges(changes) {

  }

  ngOnDestroy(){
  }
}
