import { Action } from '@ngrx/store';

export const UPDATE_DEVICE_ACTIVITY = '[Devices] Update Device Activity';
export const RESET = '[Devices] Reset Devices';
export const REQUEST_DEVICES = '[Devices] Requesting Devices';
export const REQUEST_DEVICES_SUCCESS = '[Devices] Requesting Devices Success';
export const REQUEST_DEVICES_FAILURE = '[Devices] Requesting Devices Failure';
export const REMOVE_DEVICE = '[Devices] Remove Device';
export const REMOVE_DEVICE_SUCCESS = '[Devices] Remove Device Success';
export const REMOVE_DEVICE_FAILURE = '[Devices] Remove Device Failure';
export const REQUEST_LATEST_VERSION = '[Devices] Requesting latest version';
export const REQUEST_LATEST_VERSION_SUCCESS = '[Devices] Requesting latest version Success';
export const REQUEST_LATEST_VERSION_FAILURE = '[Devices] Requesting latest version Failure';
export const FIND_DEVICE = '[Devices] Find a specific device';
export const FIND_DEVICE_SUCCESS = '[Devices] Find a specific device Success';
export const FIND_DEVICE_FAILURE = '[Devices] Find a specific device Failure';
export const UPDATE_DEVICE = '[Devices] Update a specific device';
export const UPDATE_DEVICE_SUCCESS = '[Devices] Update a specific device Success';
export const UPDATE_DEVICE_FAILURE = '[Devices] Update a specific device Failure';
export const MUTE_DEVICE = '[Devices] Mute a specific device';
export const MUTE_DEVICE_SUCCESS = '[Devices] Mute a specific device Success';
export const MUTE_DEVICE_FAILURE = '[Devices] Mute a specific device Failure';

export class UpdateDeviceActivity implements Action {
  readonly type = UPDATE_DEVICE_ACTIVITY;

  constructor(public payload: any) {}
}

export class Reset implements Action {
  readonly type = RESET;

  constructor() {}
}

export class RequestDevices implements Action {
  readonly type = REQUEST_DEVICES;

  constructor() {}
}

export class RequestDevicesSuccess implements Action {
  readonly type = REQUEST_DEVICES_SUCCESS;

  constructor(public payload: any) {}
}

export class RequestDevicesFailure implements Action {
  readonly type = REQUEST_DEVICES_FAILURE;

  constructor(public payload?: any) {}
}

export class RemoveDevice implements Action {
  readonly type = REMOVE_DEVICE;

  constructor(public payload?: any) {}
}

export class RemoveDeviceSuccess implements Action {
  readonly type = REMOVE_DEVICE_SUCCESS;

  constructor(public payload: any) {}
}

export class RemoveDeviceFailure implements Action {
  readonly type = REMOVE_DEVICE_FAILURE;

  constructor(public payload?: any) {}
}

export class RequestLatestVersion implements Action {
  readonly type = REQUEST_LATEST_VERSION;

  constructor() {}
}

export class RequestLatestVersionSuccess implements Action {
  readonly type = REQUEST_LATEST_VERSION_SUCCESS;

  constructor(public payload: any) {}
}

export class RequestLatestVersionFailure implements Action {
  readonly type = REQUEST_LATEST_VERSION_FAILURE;

  constructor(public payload?: any) {}
}

export class FindDevice implements Action {
  readonly type = FIND_DEVICE;

  constructor(public payload?: any) {}
}

export class FindDeviceSuccess implements Action {
  readonly type = FIND_DEVICE_SUCCESS;

  constructor(public payload: any) {}
}

export class FindDeviceFailure implements Action {
  readonly type = FIND_DEVICE_FAILURE;

  constructor(public payload?: any) {}
}

export class MuteDevice implements Action {
  readonly type = MUTE_DEVICE;

  constructor(public payload?: any) {}
}

export class MuteDeviceSuccess implements Action {
  readonly type = MUTE_DEVICE_SUCCESS;

  constructor(public payload: any) {}
}

export class MuteDeviceFailure implements Action {
  readonly type = MUTE_DEVICE_FAILURE;

  constructor(public payload?: any) {}
}

export class UpdateDevice implements Action {
  readonly type = UPDATE_DEVICE;

  constructor(public payload?: any) {}
}

export class UpdateDeviceSuccess implements Action {
  readonly type = UPDATE_DEVICE_SUCCESS;

  constructor(public payload: any) {}
}

export class UpdateDeviceFailure implements Action {
  readonly type = UPDATE_DEVICE_FAILURE;

  constructor(public payload?: any) {}
}


export type Actions =
  | UpdateDeviceActivity
  | Reset
  | RequestDevices
  | RequestDevicesSuccess
  | RequestDevicesFailure
  | RemoveDevice
  | RemoveDeviceSuccess
  | RemoveDeviceFailure
  | RequestLatestVersion
  | RequestLatestVersionSuccess
  | RequestLatestVersionFailure
  | FindDevice
  | FindDeviceSuccess
  | FindDeviceFailure
  | MuteDevice
  | MuteDeviceSuccess
  | MuteDeviceFailure
  | UpdateDevice
  | UpdateDeviceSuccess
  | UpdateDeviceFailure;
