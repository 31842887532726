import {Component, OnInit, OnDestroy, Input} from '@angular/core';

@Component({
  selector: "MultiToggle",
  templateUrl: './multitoggle.component.html',
  styleUrls: ['./multitoggle.component.scss'],
})
export class MultiToggle implements OnInit, OnDestroy  {

  @Input() onChange: any;
  @Input() option: string;

  public state = {
    'selected': 'list'
  };

  constructor() {
    this.changeToggle = this.changeToggle.bind(this);
  }

  ngOnInit(){

  }

  ngOnChanges(changes) {
    if (changes.option) {
      this.state.selected = changes.option.currentValue;
    }
  }

  ngAfterViewInit(){
  }


  ngOnDestroy(){
  }

  changeToggle(value) {
    this.state.selected = value;
    this.onChange(value);
  }
}
