import { Action } from '@ngrx/store';

export const RESET = '[Accounts] Reset';
export const REQUEST_ACCOUNTS = '[Accounts] Requesting accounts';
export const REQUEST_ACCOUNTS_SUCCESS = '[Accounts] Requesting accounts Success';
export const REQUEST_ACCOUNTS_FAILURE = '[Accounts] Requesting accounts Failure';
export const FIND_ACCOUNT = '[Accounts] Find account';
export const FIND_ACCOUNT_SUCCESS = '[Accounts] Find account Success';
export const FIND_ACCOUNT_FAILURE = '[Accounts] Find account Failure';
export const ADD_ACCOUNT = '[Accounts] Add account';
export const ADD_ACCOUNT_SUCCESS = '[Accounts] Add account Success';
export const ADD_ACCOUNT_FAILURE = '[Accounts] Add account Failure';
export const EDIT_ACCOUNT = '[Accounts] Edit account';
export const EDIT_ACCOUNT_SUCCESS = '[Accounts] Edit account Success';
export const EDIT_ACCOUNT_FAILURE = '[Accounts] Edit account Failure';
export const DELETE_ACCOUNT = '[Accounts] Delete account';
export const DELETE_ACCOUNT_SUCCESS = '[Accounts] Delete account Success';
export const DELETE_ACCOUNT_FAILURE = '[Accounts] Delete account Failure';

export class Reset implements Action {
  readonly type = RESET;

  constructor() {}
}

export class RequestAccounts implements Action {
  readonly type = REQUEST_ACCOUNTS;

  constructor() {}
}

export class RequestAccountsSuccess implements Action {
  readonly type = REQUEST_ACCOUNTS_SUCCESS;

  constructor(public payload: any) {}
}

export class RequestAccountsFailure implements Action {
  readonly type = REQUEST_ACCOUNTS_FAILURE;

  constructor(public payload?: any) {}
}

export class FindAccount implements Action {
  readonly type = FIND_ACCOUNT;

  constructor(public payload: any) {}
}

export class FindAccountSuccess implements Action {
  readonly type = FIND_ACCOUNT_SUCCESS;

  constructor(public payload: any) {}
}

export class FindAccountFailure implements Action {
  readonly type = FIND_ACCOUNT_FAILURE;

  constructor(public payload?: any) {}
}

export class AddAccount implements Action {
  readonly type = ADD_ACCOUNT;

  constructor(public payload: any) {}
}

export class AddAccountSuccess implements Action {
  readonly type = ADD_ACCOUNT_SUCCESS;

  constructor(public payload: any) {}
}

export class AddAccountFailure implements Action {
  readonly type = ADD_ACCOUNT_FAILURE;

  constructor(public payload?: any) {}
}

export class EditAccount implements Action {
  readonly type = EDIT_ACCOUNT;

  constructor(public id: any, public payload: any) {}
}

export class EditAccountSuccess implements Action {
  readonly type = EDIT_ACCOUNT_SUCCESS;

  constructor(public payload: any) {}
}

export class EditAccountFailure implements Action {
  readonly type = EDIT_ACCOUNT_FAILURE;

  constructor(public payload?: any) {}
}

export class DeleteAccount implements Action {
  readonly type = DELETE_ACCOUNT;

  constructor(public id: any) {}
}

export class DeleteAccountSuccess implements Action {
  readonly type = DELETE_ACCOUNT_SUCCESS;

  constructor(public payload: any) {}
}

export class DeleteAccountFailure implements Action {
  readonly type = DELETE_ACCOUNT_FAILURE;

  constructor(public payload?: any) {}
}

export type Actions =
  | Reset
  | RequestAccounts
  | RequestAccountsSuccess
  | RequestAccountsFailure
  | FindAccount
  | FindAccountSuccess
  | FindAccountFailure
  | AddAccount
  | AddAccountSuccess
  | AddAccountFailure
  | EditAccount
  | EditAccountSuccess
  | EditAccountFailure
  | DeleteAccount
  | DeleteAccountSuccess
  | DeleteAccountFailure;
