import {
  Component,
  ElementRef, HostListener,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import {FormGroup} from "@angular/forms";
import Timezones from "./timezones";

@Component({
  selector: "LanguageSelect",
  templateUrl: './languageselect.component.html',
  styleUrls: ['./languageselect.component.scss'],
})
export class LanguageSelect implements OnChanges  {

  @Input() label: any;
  @Input() value: any;
  @Input() icon: string;
  @Input() default: string;
  @Input() error: boolean = false;
  @Input() text: string;
  @Input() controlName: any;
  @Input() parentForm: FormGroup = null;
  @Input() placeholder: string = "Search..";
  @Input() onChange: any;
  @Input() readonly: boolean = false;
  public open: boolean = false;
  public search: string = "";
  public filteredItems: any[] = [];
  public selectedItem: string = "";
  public selectedValue: string = "";
  public items = [];

  constructor(private eRef: ElementRef) {
    this.items = Timezones;
    this.filteredItems = this.filterByName(this.items, this.search);
    this.changeSelect = this.changeSelect.bind(this);
    this.toggleDropdown = this.toggleDropdown.bind(this);
    this.getLabel = this.getLabel.bind(this);
  }

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if(!this.eRef.nativeElement.contains(event.target)) {
      this.open = false;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.default){
      const item = this.items.filter(i => i.value === changes.default.currentValue);
      if (item.length >= 1) {
        this.selectedItem = item[0].text;
        this.selectedValue = item[0].value;
      }
    }
    if(changes.value){
      const item = this.items.filter(i => i.utc.indexOf(changes.value.currentValue) > -1);
      if (item.length >= 1) {
        this.selectedItem = item[0].text;
        this.selectedValue = item[0].value;
      }
    }
  }

  filterByName(items, search){
    if(search == "") {
      return items;
    }
    return items.filter(i => i.text.includes(search) || i.text.toLowerCase().includes(search.toLowerCase()));
  }

  changeSearch(event) {
    const { value } = event.target;
    this.search = value;
    this.filteredItems = this.filterByName(this.items, this.search);
    event.stopPropagation();
    event.preventDefault();
    return false;
  }

  isSelected(value){
    return this.selectedValue === value;
  }

  getLabel(value){
    const item = this.items.filter(i => i.utc.indexOf(value) > -1);
    if(item.length >= 1) {
      return item[0].text;
    }
  }

  changeSelect(value, event) {
    const item =  this.items.filter(i => i.value === value);
    if (item.length >= 1) {
      this.selectedItem = item[0].text;
      this.selectedValue = item[0].utc[0];
      this.value = value;
      if(this.onChange){
        this.onChange(this.selectedValue);
      }
    }
    event.stopPropagation();
    event.preventDefault();
    return false;
  }

  toggleDropdown(){
    this.open = !this.open;
  }
}
