import { Action } from '@ngrx/store';

export const RESET = '[Sites] Reset';
export const REQUEST_SITES = '[Sites] Requesting sites';
export const REQUEST_SITES_SUCCESS = '[Sites] Requesting sites Success';
export const REQUEST_SITES_FAILURE = '[Sites] Requesting sites Failure';
export const FIND_SITE = '[Sites] Find site';
export const FIND_SITE_SUCCESS = '[Sites] Find site Success';
export const FIND_SITE_FAILURE = '[Sites] Find site Failure';
export const FIND_SITE_DEVICES = '[Sites] Find devices of site';
export const FIND_SITE_DEVICES_SUCCESS = '[Sites] Find devices of  site Success';
export const FIND_SITE_DEVICES_FAILURE = '[Sites] Find devices of  site Failure';
export const ADD_SITE = '[Sites] Add site';
export const ADD_SITE_SUCCESS = '[Sites] Add site Success';
export const ADD_SITE_FAILURE = '[Sites] Add site Failure';
export const EDIT_SITE = '[Sites] Edit site';
export const EDIT_SITE_SUCCESS = '[Sites] Edit site Success';
export const EDIT_SITE_FAILURE = '[Sites] Edit site Failure';
export const DELETE_SITE = '[Sites] Delete site';
export const DELETE_SITE_SUCCESS = '[Sites] Delete site Success';
export const DELETE_SITE_FAILURE = '[Sites] Delete site Failure';
export const ASSIGN_DEVICES = '[Sites] Assign devices';
export const ASSIGN_DEVICES_SUCCESS = '[Sites] Assign devices Success';
export const ASSIGN_DEVICES_FAILURE = '[Sites] Assign devices Failure';

export class Reset implements Action {
  readonly type = RESET;

  constructor() {}
}

export class RequestSites implements Action {
  readonly type = REQUEST_SITES;

  constructor() {}
}

export class RequestSitesSuccess implements Action {
  readonly type = REQUEST_SITES_SUCCESS;

  constructor(public payload: any) {}
}

export class RequestSitesFailure implements Action {
  readonly type = REQUEST_SITES_FAILURE;

  constructor(public payload?: any) {}
}

export class FindSite implements Action {
  readonly type = FIND_SITE;

  constructor(public payload: any) {}
}

export class FindSiteSuccess implements Action {
  readonly type = FIND_SITE_SUCCESS;

  constructor(public payload: any) {}
}

export class FindSiteFailure implements Action {
  readonly type = FIND_SITE_FAILURE;

  constructor(public payload?: any) {}
}

export class FindDevicesOfSite implements Action {
  readonly type = FIND_SITE_DEVICES;

  constructor(public payload: any) {}
}

export class FindDevicesOfSiteSuccess implements Action {
  readonly type = FIND_SITE_DEVICES_SUCCESS;

  constructor(public payload: any) {}
}

export class FindDevicesOfSiteFailure implements Action {
  readonly type = FIND_SITE_DEVICES_FAILURE;

  constructor(public payload?: any) {}
}

export class AddSite implements Action {
  readonly type = ADD_SITE;

  constructor(public payload: any) {}
}

export class AddSiteSuccess implements Action {
  readonly type = ADD_SITE_SUCCESS;

  constructor(public payload: any) {}
}

export class AddSiteFailure implements Action {
  readonly type = ADD_SITE_FAILURE;

  constructor(public payload?: any) {}
}

export class EditSite implements Action {
  readonly type = EDIT_SITE;

  constructor(public id: any, public payload: any) {}
}

export class EditSiteSuccess implements Action {
  readonly type = EDIT_SITE_SUCCESS;

  constructor(public id: any, public payload: any) {}
}

export class EditSiteFailure implements Action {
  readonly type = EDIT_SITE_FAILURE;

  constructor(public payload?: any) {}
}

export class DeleteSite implements Action {
  readonly type = DELETE_SITE;

  constructor(public id: any) {}
}

export class DeleteSiteSuccess implements Action {
  readonly type = DELETE_SITE_SUCCESS;

  constructor(public payload: any) {}
}

export class DeleteSiteFailure implements Action {
  readonly type = DELETE_SITE_FAILURE;

  constructor(public payload?: any) {}
}

export class AssignDevices implements Action {
  readonly type = ASSIGN_DEVICES;

  constructor(public id: any, public payload: any) {}
}

export class AssignDevicesSuccess implements Action {
  readonly type = ASSIGN_DEVICES_SUCCESS;

  constructor(public payload: any) {}
}

export class AssignDevicesFailure implements Action {
  readonly type = ASSIGN_DEVICES_FAILURE;

  constructor(public payload?: any) {}
}

export type Actions =
  | Reset
  | RequestSites
  | RequestSitesSuccess
  | RequestSitesFailure
  | FindSite
  | FindSiteSuccess
  | FindSiteFailure
  | FindDevicesOfSite
  | FindDevicesOfSiteSuccess
  | FindDevicesOfSiteFailure
  | AddSite
  | AddSiteSuccess
  | AddSiteFailure
  | EditSite
  | EditSiteSuccess
  | EditSiteFailure
  | DeleteSite
  | DeleteSiteSuccess
  | DeleteSiteFailure
  | AssignDevices
  | AssignDevicesSuccess
  | AssignDevicesFailure;
