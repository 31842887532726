import { Component, OnInit, ChangeDetectionStrategy, ViewChild, ElementRef, Input, HostListener } from '@angular/core';
import { CanvasTools } from "../../../../../vendor/vott-ct-new";
import { MqttStream } from '../stream/block/mqttstream/mqttstream.component';
import Heatmap from 'visual-heatmap'
import {fromEvent, interval } from 'rxjs'
import {debounceTime, throttle  } from 'rxjs/operators'

@Component({
  selector: 'Labeler',
  templateUrl: './labeler.component.html',
  styleUrls: ['./labeler.component.scss']
})
export class LabelerComponent { //implements OnInit {

  public isLoading;
  public regionShadow: any[] = [];
  public timerSubscription;
  public image: any;

  @ViewChild('editor', { static: true }) editor: ElementRef;
  @ViewChild('toolbar', { static: true }) toolbar: ElementRef;
  @ViewChild('heatmapper', { static: false }) heatmapper: ElementRef;
  @ViewChild('frames', { static: false }) frames: ElementRef;
  @ViewChild('video', { static: true }) video: ElementRef;
  @ViewChild('canvas', { static: true }) canvas: ElementRef;
  @ViewChild('cursor', { static: true }) cursor: ElementRef;
  @ViewChild('hovercursor', { static: true }) hovercursor: ElementRef;
  @ViewChild('timeline', { static: false }) timeline: ElementRef;
  @ViewChild('lines', { static: true }) lines: ElementRef;

  @Input() id: string = "heatmap";
  @Input() type: string = "polygon";
  @Input() device: string;
  @Input() thumbnail: string = "";
  @Input() cloudKey: string;
  @Input() polylines: any[] = [];
  @Input() polygons: any[] = [];
  @Input() hits: any[] = [];
  @Input() fixedLines: any[] = [];
  @Input() fixedRegions: any[] = []; // This is also used for heatmaps (legacy)
  @Input() heatmapPoints: any[] = [];
  @Input() heatmapWidth: any;
  @Input() heatmapHeight: any;
  @Input() media: any;
  @Input() videosrc: string = "";
  @Input() onAddRegion: Function;
  @Input() onUpdateRegion: Function;
  @Input() onDeleteRegion: Function;

  public mouseMoveSubscription;
  public mouseClickSubscription;
  public videoDuration: number = 0;
  public secondWidth: number = 0;

  constructor() {
    this.image = document.createElement('img');
    this.initCanvas = this.initCanvas.bind(this);
    this.moveTimeline = this.moveTimeline.bind(this);
    this.moveHoverTimeline = this.moveHoverTimeline.bind(this);
    this.changeTime = this.changeTime.bind(this);
    this.changeCursor = this.changeCursor.bind(this);
  }

  ngOnChanges(changes){
    if(changes.type && changes.type.currentValue){
      this.type = changes.type.currentValue;
    }

    if(changes.thumbnail && changes.thumbnail.currentValue){
      const thumbnail = changes.thumbnail.currentValue;
      const isUrl = this.isUrl(thumbnail);
      this.image.src = isUrl ? thumbnail : "data:image/jpeg;base64," + thumbnail;
    }

    if(changes.videosrc && changes.videosrc.currentValue){
      const videosrc = changes.videosrc.currentValue;
      const video = <HTMLVideoElement> this.video.nativeElement
      video.src = videosrc;
      video.muted = true;

      // On seeked
      video.onseeked = () => {
        var canvas = <HTMLCanvasElement> this.canvas.nativeElement;
        var ctx = canvas.getContext('2d');
  
        // Draw video on canvas
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        // Draw video on canvas
        ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
        // Save canvas image as data url (png format by default)
        var dataURL = canvas.toDataURL("image/jpg");
        if(this.image){
          this.image.src = dataURL;
        }
      };
    }
  }

  ngAfterViewInit() {


    const video = <HTMLVideoElement> this.video.nativeElement;
    video.onloadedmetadata = () => {
      const currentTime = video.currentTime;
      video.currentTime = currentTime;
      this.videoDuration = video.duration;

      // Get width of timeline  
      const timeline = this.timeline.nativeElement;
      const width = timeline.clientWidth;
      this.secondWidth = width / video.duration
      const secondPercentage = this.secondWidth / width

      // Add lines to timeline
      // Create new objects for each duration
      const lines = this.lines.nativeElement;
      const seconds = this.counter(video.duration);
      seconds.forEach((line, index) => {
        const lineElement = document.createElement('div');
        lineElement.classList.add('line');
        lineElement.style.left = `${secondPercentage*index*100}%`;
        lineElement.style.position = 'absolute';
        lineElement.style.width = '2px';
        lineElement.style.height = '80px';
        lineElement.style.background = '#bebebe';
        if(index === 0 || index === seconds.length - 1){
          lineElement.style.width = '0';
        }
        const textNode = document.createElement('span');
        textNode.classList.add('line-text');
        textNode.style.fontSize = '10px';
        textNode.style.position = 'absolute';
        textNode.style.bottom = '-16px';
        textNode.style.left = '-2px';
        if(line > 9){
          textNode.style.left = '-5px';
        }
        textNode.innerHTML = line;
        lineElement.appendChild(textNode);
        lines.appendChild(lineElement);

        // Write milliseconds lines
        if(index < seconds.length - 1){
          const sublines = 5
          for(let i = 1; i < sublines; i++){
            const millilineElement = document.createElement('div');
            millilineElement.classList.add('milli');
            millilineElement.style.left = `${secondPercentage*(index+i/sublines)*100}%`;
            millilineElement.style.position = 'absolute';
            millilineElement.style.width = '2px';
            millilineElement.style.height = '80px';
            millilineElement.style.background = '#f4f4f4';
            lines.appendChild(millilineElement);
          }
        }
      });

      this.videoDuration = video.duration;

      this.mouseClickSubscription = 
      fromEvent(this.timeline.nativeElement, 'click')
      .pipe(debounceTime(100))
      .subscribe((e: MouseEvent) => {
          this.moveTimeline(e);
      });
  
      this.mouseMoveSubscription = 
      fromEvent(this.timeline.nativeElement, 'mousemove').subscribe((e: MouseEvent) => {
        this.moveHoverTimeline(e);
      });
  
    }

    this.initCanvas();
    const currentTime = video.currentTime;
    video.currentTime = currentTime;
          
    // On right key press
    document.addEventListener('keydown', (e) => {
      if(e.keyCode == 39){
        // Get video currentTime
        const video = <HTMLVideoElement> this.video.nativeElement;
        const currentTime = video.currentTime;
        this.changeTime(currentTime+0.1);
        this.changeCursor(currentTime+0.1);
      }
      if(e.keyCode == 37){
         // Get video currentTime
         const video = <HTMLVideoElement> this.video.nativeElement;
         const currentTime = video.currentTime;
         if(currentTime-0.1 >= 0){
            this.changeTime(currentTime-0.1);
            this.changeCursor(currentTime-0.1);
         }
      }
    });
  }

  isUrl(url: string){
    return url.indexOf("https") > -1
  }

  counter(i: number) {
    const array = [];
    if(i) {
      for (let index = 0; index < i+1; index++) {
        array.push(index);
      }
    }
    return array;
  }
  
  public moveTimeline(event){

    // Get coordinates x of cursor
    const x = event.offsetX;
    

    if(x <= 0) {
      return;
    }
    
    // Move cursor
    const cursor = <HTMLDivElement> this.cursor.nativeElement;
    cursor.style.left = x + "px";
         
    // Get width and height of the element
    const width = event.target.clientWidth;
    // Get relative position of the element
    const relX = x / width;

    const video = <HTMLVideoElement> this.video.nativeElement
    const time = video.duration * relX;

    // Change time in span html
    const t = <HTMLDivElement> this.cursor.nativeElement.querySelector(".time");
    t.innerHTML = this.formatTime(time);

    this.changeTime(time);
  }

  public moveHoverTimeline(event){

    // Get coordinates x of cursor
    const x = event.offsetX;

    const video = <HTMLVideoElement> this.video.nativeElement
    if(x <= 0 || !video || video.duration <= 0 || Number.isNaN(video.duration)) {
      return;
    }
    
    // Move cursor
    const cursor = <HTMLDivElement> this.hovercursor.nativeElement;
    cursor.style.left = x + "px";
         
    // Get width and height of the element
    const width = event.target.clientWidth;
    // Get relative position of the element
    const relX = x / width;

    const time = video.duration * relX;

    // Change time in span html
    const t = <HTMLDivElement> this.hovercursor.nativeElement.querySelector(".time");
    t.innerHTML = this.formatTime(time);
  }

  public formatTime(time){
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time - minutes * 60);
    const milliseconds = Math.floor((time - seconds - minutes * 60) * 100);
    let millisecondsStrings = milliseconds.toString();
    if(milliseconds < 10){
      millisecondsStrings = "0" + milliseconds;
    }

    if(minutes < 10 && seconds < 10){
      return "0" + minutes + ":0" + seconds + ":" + millisecondsStrings;
    }
    if(minutes < 10){
      return "0" + minutes + ":" + seconds + ":" + millisecondsStrings;
    }
    if(seconds < 10){
      return minutes + ":0" + seconds + ":" + millisecondsStrings;
    }
    return minutes + ":" + seconds + ":" + millisecondsStrings;
  }

  public changeTime(time){
    if(time > 0) {
      const video = <HTMLVideoElement> this.video.nativeElement
      video.currentTime = time;
    }
  }

  public changeCursor(time){

    // Get width of timeline
    const timeline = this.timeline.nativeElement;
    const width = timeline.clientWidth;

    // Get duration of video
    const video = <HTMLVideoElement> this.video.nativeElement;
    this.videoDuration = video.duration;
    const percentage = width / this.videoDuration;

    // Move cursor
    const cursor = <HTMLDivElement> this.cursor.nativeElement;
    cursor.style.left = percentage * time + "px";
        
    // Change time in span html
    const t = <HTMLDivElement> this.cursor.nativeElement.querySelector(".time");
    t.innerHTML = this.formatTime(time);
  }


  initCanvas() {      
    // Get references for editor and toolbar containers
    const editorContainer = <HTMLDivElement> this.editor.nativeElement;
    const toolbarContainer = <HTMLDivElement> this.toolbar.nativeElement;
    // Init the editor with toolbar.
    const editorAPI = new CanvasTools.Editor(editorContainer);
    const editor = editorAPI.api;

    let toolbarSet = CanvasTools.Editor.FullToolbarSet;
    if(this.type == "polygon") {
      toolbarSet = CanvasTools.Editor.PolygonToolbarSet;
    } else if(this.type == "polyline") {
      toolbarSet = CanvasTools.Editor.PolylineToolbarSet;
    } 

    if(this.type !== "none" && this.type !== "heatmap") {
      editor.addToolbar(toolbarContainer, toolbarSet, "./assets/images/canvastools/");
    }

    let w = 0, h = 0;
    editor.onSelectionEnd = (regionData) => {
      let id = (new Date().getTime()).toString();
      const color = "red";
      let tags = this.generateRandomTagsDescriptor(id, color);

      let type;
      if(this.type == "polygon") {
        type = CanvasTools.Core.RegionDataType.Polygon;
      } else if(this.type == "polyline") {
        type = CanvasTools.Core.RegionDataType.Polyline;
      }
      
      const r = new CanvasTools.Core.RegionData(regionData.x, regionData.y, regionData.width, regionData.height, regionData.points, type)
      editor.addRegion(id, r, tags);
      const normalized = editorAPI.scaleRegionToSourceSize(r, w, h);
      if(this.onAddRegion){
        // This might push a polygon (motion) or polyline (counting)
        this.onAddRegion(this.type, this.device, id, normalized, w, h);
      }
    }

    editor.onRegionMoveEnd = (id, regionData) => {
      const normalized = editorAPI.scaleRegionToSourceSize(regionData, w, h);
      if(this.onUpdateRegion){
        this.onUpdateRegion(this.type, this.device, id, normalized, w, h);
      }
    };

    editor.onRegionDelete = (id, regionData) => {
      const normalized = editorAPI.scaleRegionToSourceSize(regionData, w, h);
      this.onDeleteRegion(this.type, this.device, id, normalized, w, h);
    };
    

    this.isLoading = true;
    let imageLoaded = false;
    this.image.onload = () => {

      editor.addContentSource(this.image);

      if(!imageLoaded) {
        let instance = null;
        if(this.type === "heatmap") {
          instance = Heatmap("#canvas-"+this.id, {
            size: 30.0,
            max: 100,
            blur: 1.0,
            width: this.image.width,
            height: this.image.height,
            gradient: [{
                color: [0, 0, 255, 1.0],
                offset: 0
            }, {
                color: [0, 0, 255, 1.0],
                offset: 0.2
            }, {
                color: [0, 255, 0, 1.0],
                offset: 0.45
            }, {
                color: [255, 255, 0, 1.0],
                offset: 0.85
            }, {
                color: [255, 0, 0, 1.0],
                offset: 1.0
            }]
          });
        }

        // If hits are found we will draw rectangles
        if(this.hits && this.hits.length > 0){
          for(let i = 0; i < this.hits.length; i++){
            const p = [];
            const hit = this.hits[i];
            const x = hit.position[0];
            const y = hit.position[1];
            const width = 10;
            const height = 10;
            p.push(new CanvasTools.Core.Point2D(x,y));
            const region = new CanvasTools.Core.RegionData(x, y, width, height, p, CanvasTools.Core.RegionDataType.Point)
            
            const r = editorAPI.scaleRegionToFrameSize(region, hit.videoWidth, hit.videoHeight);
            let tags = this.generateRandomTagsDescriptor(hit.segment, "red");
            editor.addRegion(hit.segment, r, tags);
          }
        }

        // if we need to add fixed lines
        if(this.fixedLines && this.fixedLines.length > 0){
          for(let i = 0; i < this.fixedLines.length; i++){
            const p = this.fixedLines[i];
            const region =  p.regionPoints;
            let regionData = this.tranformPolygon(region);

            if(regionData) {
              const r = editorAPI.scaleRegionToFrameSize(regionData, p.width, p.height);
              let tags = this.generateRandomTagsDescriptor(p.id, "green");
              editor.addRegion(p.id, r, tags);
            }
          }
        }

        // if we need to add fixed regions
        if(this.fixedRegions && this.fixedRegions.length > 0){
          for(let i = 0; i < this.fixedRegions.length; i++){
            const r = this.fixedRegions[i];
            const traject = r.traject;
            const { classified } = r;
            let color = "yellow";
            if(classified == 'pedestrian'){
              color = "red";
            } else if(classified == 'car'){
              color = "green";
            } else if(classified == 'cyclist'){
              color = "blue";
            }

            if(traject && traject.length){
              const trajectLength = traject.length;
              for(let j = 0; j < traject.length; j++){
                const t = traject[j];
                const x1 = t[0]
                const y1 = t[1]
                const x2 = t[2]
                const y2 = t[3]

                const w = x2 - x1
                const h = y2 - y1
                const p = new CanvasTools.Core.Point2D(x1,y1);
                const region = new CanvasTools.Core.RegionData(x1, y1, w, h, [p], CanvasTools.Core.RegionDataType.Rect)
                const regionScaled = editorAPI.scaleRegionToFrameSize(region, r.frameWidth, r.frameHeight);
        
                if(this.type === 'heatmap') {
                  if(instance) {
                    const incX = regionScaled.width / 2
                    const incY = regionScaled.height / 2
                    instance.addData([{
                        x:  regionScaled.x + incX,
                        y: regionScaled.y + incY,
                        value: 5
                    }], true);
                  }
                } else {
                  if(j % 3 === 0) {
                    let tags = this.generateRandomTagsDescriptor(classified, color);
                    editor.addRegion(classified, regionScaled, tags);
                  }
                }
              }
            }
          }
        }

        // Better for heatmap if already have the coordinates prepared in a single array
        // above method expects to have an object from the analysis pipeline.

        if(this.heatmapPoints && this.heatmapPoints.length > 0){
          for(let i = 0; i < this.heatmapPoints.length; i++){
            const point = this.heatmapPoints[i];
            const x1 = point[0];
            const y1 = point[1] + 125;
          
            const p = new CanvasTools.Core.Point2D(x1,y1);
            const region = new CanvasTools.Core.RegionData(x1, y1, w, h, [p], CanvasTools.Core.RegionDataType.Rect)
            const regionScaled = editorAPI.scaleRegionToFrameSize(region, this.heatmapWidth, this.heatmapHeight);

            instance.addData([{
              x:  regionScaled.x ,
              y: regionScaled.y,
              value: 5
            }], true);
          }
        }

        // If polygons or polylines are added (is used for drawing)
        if(this.type == "polygon") {
          for(let i = 0; i < this.polygons.length; i++) {
            const p = this.polygons[i];
            if(p.device === this.device) {
              const region =  p.regionPoints;
              let regionData = this.tranformPolygon(region);

              if(regionData) {
                const r = editorAPI.scaleRegionToFrameSize(regionData, p.width, p.height);
                let tags = this.generateRandomTagsDescriptor(p.id, "red");
                editor.addRegion(p.id, r, tags);
              }
            }
          }   
        } else if(this.type == "polyline") {
          for(let i = 0; i < this.polylines.length; i++) {
            const p = this.polylines[i];
            if(p.device === this.device) {
              const region =  p.regionPoints;
              let regionData = this.tranformPolyline(region);

              if(regionData) {
                const r = editorAPI.scaleRegionToFrameSize(regionData, p.width, p.height);
                let tags = this.generateRandomTagsDescriptor(p.id, "red");
                editor.addRegion(p.id, r, tags);
              }
            }
          }   
        }
        this.isLoading = false; 
      }
      imageLoaded = true;
    }
  }

  onResize(event) {
    
  }

  openDialog(callback, polyline): void {
    /*let dialogRef = this.dialog.open(DialogComponent, {
      maxWidth: '800px',
      data: {
        onSubmit: callback,
        polyline,
      }
    });*/
  }

  tranformPolyline(points) {
    if(!points || points.length == 0)
      return null;

    let x = -1;
    let y = -1;
    let width = -1;
    let height = -1;

    let minX = points[0].x;
    let maxX = points[0].x;
    let minY = points[0].y;
    let maxY = points[0].y;

    const p = [];
    for(let i = 0; i < points.length; i++){
      // Add to array of points
      p.push(new CanvasTools.Core.Point2D(points[i].x,points[i].y));

      // Check the bounding box.
      if(points[i].x < minX) {
        minX = points[i].x
      }
      if(points[i].x > maxX) {
        maxX = points[i].x
      }
      if(points[i].y < minY) {
        minY = points[i].y
      }
      if(points[i].y > maxY) {
        maxY = points[i].y
      }
    }

    x = minX;
    y = minY;
    width = maxX - minX
    height = maxY - minY

    const region = new CanvasTools.Core.RegionData(x, y, width, height, p, CanvasTools.Core.RegionDataType.Polyline)
    return region
  }

  tranformPolygon(points) {
    if(!points || points.length == 0)
      return null;

    let x = -1;
    let y = -1;
    let width = -1;
    let height = -1;

    let minX = points[0].x;
    let maxX = points[0].x;
    let minY = points[0].y;
    let maxY = points[0].y;

    const p = [];
    for(let i = 0; i < points.length; i++){
      // Add to array of points
      p.push(new CanvasTools.Core.Point2D(points[i].x,points[i].y));

      // Check the bounding box.
      if(points[i].x < minX) {
        minX = points[i].x
      }
      if(points[i].x > maxX) {
        maxX = points[i].x
      }
      if(points[i].y < minY) {
        minY = points[i].y
      }
      if(points[i].y > maxY) {
        maxY = points[i].y
      }
    }

    x = minX;
    y = minY;
    width = maxX - minX
    height = maxY - minY

    const region = new CanvasTools.Core.RegionData(x, y, width, height, p, CanvasTools.Core.RegionDataType.Polygon)
    return region
  }

  generateRandomTagsDescriptor(id, color) {
    const Color = CanvasTools.Core.Colors.Color;
    let c = new Color("#943734");
    if(color === "red") {
      c = new Color("#943734");
    } else if(color === "green") {
      c = new Color("#24be23");
    } else if(color === "blue") {
      c = new Color("#0f65f4");
    } else if(color === "yellow") {
      c = new Color("#e7f623");
    } 

    const primaryTags = [
        new CanvasTools.Core.Tag(id, c)
    ];
    const primaryTag = primaryTags[0];
    let tags = new CanvasTools.Core.TagsDescriptor([primaryTag]);
    return tags;
  }

  makeid(length) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  ngOnDestroy() {
    clearInterval(this.timerSubscription);
    this.mouseMoveSubscription.unsubscribe();
    this.mouseClickSubscription.unsubscribe();
    this.image = null;
  }
}
