import {Component, OnInit, OnDestroy, Input} from '@angular/core';

@Component({
  selector: "CameraBullet",
  templateUrl: './camerabullet.component.html',
  styleUrls: ['./camerabullet.component.scss'],
})
export class CameraBullet implements OnInit, OnDestroy  {

  @Input() color: string;
  @Input() title: string;

  constructor() {}

  ngOnInit(){
  }

  ngAfterViewInit(){
  }

  ngOnDestroy(){
  }
}
