import { Action } from '@ngrx/store';

export const RESET = '[Roles] Reset';
export const REQUEST_ROLES = '[Roles] Requesting roles';
export const REQUEST_ROLES_SUCCESS = '[Roles] Requesting roles Success';
export const REQUEST_ROLES_FAILURE = '[Roles] Requesting roles Failure';
export const FIND_ROLE = '[Roles] Find role';
export const FIND_ROLE_SUCCESS = '[Roles] Find role Success';
export const FIND_ROLE_FAILURE = '[Roles] Find role Failure';
export const ADD_ROLE = '[Roles] Add role';
export const ADD_ROLE_SUCCESS = '[Roles] Add role Success';
export const ADD_ROLE_FAILURE = '[Roles] Add role Failure';
export const EDIT_ROLE = '[Roles] Edit role';
export const EDIT_ROLE_SUCCESS = '[Roles] Edit role Success';
export const EDIT_ROLE_FAILURE = '[Roles] Edit role Failure';
export const DELETE_ROLE = '[Roles] Delete role';
export const DELETE_ROLE_SUCCESS = '[Roles] Delete role Success';
export const DELETE_ROLE_FAILURE = '[Roles] Delete role Failure';

export class Reset implements Action {
  readonly type = RESET;

  constructor() {}
}

export class RequestRoles implements Action {
  readonly type = REQUEST_ROLES;

  constructor() {}
}

export class RequestRolesSuccess implements Action {
  readonly type = REQUEST_ROLES_SUCCESS;

  constructor(public payload: any) {}
}

export class RequestRolesFailure implements Action {
  readonly type = REQUEST_ROLES_FAILURE;

  constructor(public payload?: any) {}
}

export class FindRole implements Action {
  readonly type = FIND_ROLE;

  constructor(public payload: any) {}
}

export class FindRoleSuccess implements Action {
  readonly type = FIND_ROLE_SUCCESS;

  constructor(public payload: any) {}
}

export class FindRoleFailure implements Action {
  readonly type = FIND_ROLE_FAILURE;

  constructor(public payload?: any) {}
}

export class AddRole implements Action {
  readonly type = ADD_ROLE;

  constructor(public payload: any) {}
}

export class AddRoleSuccess implements Action {
  readonly type = ADD_ROLE_SUCCESS;

  constructor(public payload: any) {}
}

export class AddRoleFailure implements Action {
  readonly type = ADD_ROLE_FAILURE;

  constructor(public payload?: any) {}
}

export class EditRole implements Action {
  readonly type = EDIT_ROLE;

  constructor(public id: any, public payload: any) {}
}

export class EditRoleSuccess implements Action {
  readonly type = EDIT_ROLE_SUCCESS;

  constructor(public payload: any) {}
}

export class EditRoleFailure implements Action {
  readonly type = EDIT_ROLE_FAILURE;

  constructor(public payload?: any) {}
}

export class DeleteRole implements Action {
  readonly type = DELETE_ROLE;

  constructor(public id: any) {}
}

export class DeleteRoleSuccess implements Action {
  readonly type = DELETE_ROLE_SUCCESS;

  constructor(public payload: any) {}
}

export class DeleteRoleFailure implements Action {
  readonly type = DELETE_ROLE_FAILURE;

  constructor(public payload?: any) {}
}

export type Actions =
  | Reset
  | RequestRoles
  | RequestRolesSuccess
  | RequestRolesFailure
  | FindRole
  | FindRoleSuccess
  | FindRoleFailure
  | AddRole
  | AddRoleSuccess
  | AddRoleFailure
  | EditRole
  | EditRoleSuccess
  | EditRoleFailure
  | DeleteRole
  | DeleteRoleSuccess
  | DeleteRoleFailure;
