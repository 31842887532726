import {Component, OnInit, OnDestroy, Input, OnChanges} from '@angular/core';
import * as svg from "../../../shared/svg.inject";

@Component({
  selector: "Checkbox",
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
})
export class Checkbox implements OnInit, OnDestroy, OnChanges  {

  @Input() icon: string;
  @Input() label: string;
  @Input() checked: any;
  @Input() onChange: any;
  @Input() disabled: boolean = false;

  constructor() {}
  ngOnInit(){}
  ngOnChanges(changes) {}
  ngOnDestroy(){}
}
