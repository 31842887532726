import { Action } from '@ngrx/store';

export const GET_VIDEOWALL = '[Videowall] Get videowall';
export const GET_VIDEOWALL_SUCCESS = '[Videowall] Get videowall Success';
export const GET_VIDEOWALL_FAILURE = '[Videowall] Get videowall Failure';
export const RESET = '[Videowall] Reset';

export class GetVideowall implements Action {
  readonly type = GET_VIDEOWALL;

  constructor(public payload: any){}
}

export class GetVideowallSuccess implements Action {
  readonly type = GET_VIDEOWALL_SUCCESS;

  constructor(public payload: any) {}
}

export class GetVideowallFailure implements Action {
  readonly type = GET_VIDEOWALL_FAILURE;

  constructor(public payload: any) {}
}

export class Reset implements Action {
  readonly type = RESET;

  constructor() {}
}

export type Actions =
  | GetVideowall
  | GetVideowallSuccess
  | GetVideowallFailure
  | Reset;
