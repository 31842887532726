import {Component, OnInit, OnDestroy, Input} from '@angular/core';
import { MatTableDataSource } from "@angular/material/table";

@Component({
  selector: "VerifiedBadge",
  templateUrl: './verified.component.html',
  styleUrls: ['./verified.component.scss'],
})
export class VerifiedBadge  {

}
