import * as profile from './actions';
import * as devices from "../../devices/store/actions";
//import { User } from '../models/user';

export interface State {
  sites: Array<any>;
  site: any;
  siteDevices: Array<any>;
  error: any;
}

export const initialState: State = {
  sites: [],
  site: null,
  siteDevices: [],
  error: null,
};

export function reducer(state = initialState, action: profile.Actions): State {
  switch (action.type) {

    case profile.REQUEST_SITES_SUCCESS: {
      const payload = action.payload;
      return {
        ...state,
        sites: payload ? payload : []
      }
    }

    case profile.REQUEST_SITES_FAILURE: {
      const payload = action.payload;
      return {
        ...state,
        error: payload.error.data
      };
    }


    case profile.FIND_SITE_SUCCESS: {
      const payload = action.payload;
      return {
        ...state,
        site: payload ? payload : null
      }
    }

    case profile.FIND_SITE_DEVICES_SUCCESS: {
      const payload = action.payload;
      if(!payload) {
        return {
          ...state,
          siteDevices: [],
        };
      }

      const timeNow = Math.floor(Date.now() / 1000);
      const siteDevices = payload.map(c => {
        const mostRecentInfo = c.analytics[c.analytics.length-1];
        return {
          key: c.key,
          color: c.color,
          name: mostRecentInfo.cameraname,
          version:  mostRecentInfo.version,
          release:  mostRecentInfo.release,
          enterprise:  mostRecentInfo.enterprise,
          connected:  mostRecentInfo.cameraConnected === "" ? "true" : mostRecentInfo.cameraConnected,
          hasBackChannel: mostRecentInfo.hasBackChannel === "" ? "false" : mostRecentInfo.hasBackChannel,
          uptime:  mostRecentInfo.uptime.split(",")[0],
          site: (state.site) ? state.site.name : '',
          active: (timeNow - mostRecentInfo.timestamp) < 180,
          presets: c.presets,
          onvif:  mostRecentInfo.onvif === "" ? "true" : mostRecentInfo.onvif,
          onvif_presets_list: mostRecentInfo.onvif_presets_list,
          onvif_events_list: mostRecentInfo.onvif_events_list,
          onvif_zoom:  mostRecentInfo.onvif_zoom,
          onvif_pantilt:  mostRecentInfo.onvif_pantilt,
          featurePermissions: c.featurePermissions,
        };
      });

      return {
        ...state,
        siteDevices,
      }
    }

    case profile.ADD_SITE_SUCCESS: {
      const payload = action.payload;
      return {
        ...state,
        sites: payload
      }
    }

    case profile.EDIT_SITE_SUCCESS: {
      const payload = action.payload;
      const site = payload.find(s => s.id == action.id);
      if(!site) {
        return state;
      }

      return {
        ...state,
        sites: payload,
        site: site,
      }
    }

    case profile.DELETE_SITE_SUCCESS: {
      const payload = action.payload;
      return {
        ...state,
        sites: payload
      }
    }

    case profile.ASSIGN_DEVICES_SUCCESS: {
      const payload = action.payload;
      return {
        ...state,
        sites: payload
      }
    }
    case profile.RESET: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}

export const getSitesState = (state: State) => state;
export const getSites = (state: State) => state.sites;
export const getSite = (state: State) => state.site;
export const getSiteDevices = (state: State) => state.siteDevices;
