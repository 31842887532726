import * as notifications from './actions';

export interface State {
  messages: Array<any>;
  lastMessage: any;
}

export const initialState: State = {
  messages: [],
  lastMessage: null
};

export function reducer(state = initialState, action: notifications.Actions): State {
  switch (action.type) {

    case notifications.MARK_READ_ALL: {
      return {
        ...state,
        messages: [],
      };
    }

    case notifications.MARK_READ_NOTIFICATION: {
      const i = action.payload.index;
      return {
        ...state,
        messages: [
          ...state.messages.slice(0, i),
          Object.assign({}, state.messages[i], {
            unread: false
          }),
          ...state.messages.slice(i+1)
        ]
      };
    }

    case notifications.ADD_NOTIFICATION: {
      return {
        ...state,
        messages: [action.payload, ...state.messages],
        lastMessage: action.payload
      };
    }

    case notifications.REQUEST_NOTIFICATIONS_SUCCESS: {
      return {
        ...state,
        messages: action.payload
      };
    }

    case notifications.RESET: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}

export const getNotifications = (state: State) => state.messages;
export const getLastNotification = (state: State) => state.lastMessage;
