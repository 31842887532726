import {Component, OnInit, Input, SimpleChanges, OnChanges, ViewEncapsulation, OnDestroy} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Store} from "@ngrx/store";
import * as fromTasks from "../../../home/tasks/store";
import * as Tasks from "../../../home/tasks/store/actions";
import * as fromAccounts from "../../../home/accounts/store";
import * as Accounts from "../../../home/accounts/store/actions";
import * as Media from "../../../home/media/store/actions";
import * as fromMedia from "../../../home/media/store";
import * as fromSites from "../../../home/sites/store";
import * as fromDevices from "../../../home/devices/store";
import {Md5} from "ts-md5";

import {DatePipe} from "@angular/common";
import { Options } from '@angular-slider/ngx-slider';
import { Subscription } from 'rxjs';

const moment = require('moment');

@Component({
  selector: "ExportMedia",
  templateUrl: './exportmedia.component.html',
  styleUrls: ['./exportmedia.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ExportMedia implements OnInit, OnDestroy  {

  @Input() showText: boolean = true;
  @Input() error: string;
  @Input() sequenceId: any
  @Input() mediaId: any
  @Input() media: any
  @Input() task: any
  @Input() mode: string = 'add';

  public value: number = 0;
  public highValue: number = 24;

  public gravatarSize: number = 40;
  public accounts$ = this.store.select(fromAccounts.getAccounts);
  public accountsError$ = this.store.select(fromAccounts.getError);
  public accountsSubscription;
  public accounts;
  public accountsPickerItems: any = [];
  public selectedAccounts: any = [];
  public selectedAccountsLabels: any = [];

  private daySubscription: Subscription;

  private loading$ = this.store.select(fromMedia.isLoading);
  private loadingSubscription;
  private loading;
  public canLoadMore: boolean = true;

  public exportMedia$ = this.store.select(fromMedia.getExportedMedia);
  public exportMediaSubscription;
  public exportMedia: Array<any> = [];
  public exportMediaWithPreview: Array<any> = [];
  public exportMediaOffset: number = 0;

  public exportMediaCount$ = this.store.select(fromMedia.getExportedMediaCount);
  public exportMediaCountSubscription;
  public exportMediaCount: number = 0;

  public sites$ = this.store.select(fromSites.getSites);
  public sitesSubscription;
  public sites: Array<any> = [];
  public selectedSites: string[] = [];

  public instances: Array<any> = [];
  public selectedInstances: string[] = [];
  public instancesFiltered: Array<any>;
  public cameras$ = this.store.select(fromDevices.getCameras);
  public cameraSubscription;

  public taskForm: FormGroup;
  public taskUpdated: boolean = false;
  public taskFormInvalidControls: any[] = [];

  private startTime: number;
  private endTime: number;
  public minDate: any;
  public maxDate: any;
  public availableDates: string[] = [];
  public currentDate: any = null;
  public selectedDates: any[];
  public openVideo: boolean = false;

  public sliderOptions: Options = {
    floor: 0,
    ceil: 60 * 60 * 24, // 24 hours in seconds
    step: 1,
    tickStep: 1,
    tickValueStep: 1,
    showTicks: true,
    showTicksValues: true,
    draggableRange: true,
    stepsArray: [
      { value: 0 },
      { value: 1 },
      { value: 2 },
      { value: 3 },
      { value: 4 },
      { value: 5 },
      { value: 6 },
      { value: 7 },
      { value: 8 },
      { value: 9 },
      { value: 10 },
      { value: 11 },
      { value: 12 },
      { value: 13 },
      { value: 14 },
      { value: 15 },
      { value: 16 },
      { value: 17 },
      { value: 18 },
      { value: 19 },
      { value: 20 },
      { value: 21 },
      { value: 22 },
      { value: 23 },
      { value: 24 }
    ],
  };

  constructor(private fb: FormBuilder,
              private store: Store<fromTasks.State>,
              public datePipe: DatePipe){

    this.taskForm = this.fb.group({
      title: ['', [Validators.required]],
      notes: ['', [Validators.required]],
    });

    this.onChangeAccounts = this.onChangeAccounts.bind(this);
    this.findInvalidControls = this.findInvalidControls.bind(this);
    this.onChangeSites = this.onChangeSites.bind(this);
    this.onChangeCameras = this.onChangeCameras.bind(this);
    this.onChangeDate = this.onChangeDate.bind(this);
    this.changeTimeline = this.changeTimeline.bind(this);
    this.getExportedMedia = this.getExportedMedia.bind(this);
    this.createExport = this.createExport.bind(this);
    this.onClose = this.onClose.bind(this);
    this.loadMoreExportedMedia = this.loadMoreExportedMedia.bind(this);
  }

  ngOnInit(){
    this.store.dispatch(new Accounts.RequestAccounts());
    this.accountsSubscription = this.accounts$.subscribe(accounts => {
      this.accounts = accounts;
      this.accountsPickerItems = this.accounts.map(c => {
        return {
          text: c.firstname + " " + c.lastname,
          email: c.email,
          username: c.username,
          role: c.role,
          value: c.id
        }
      });
    });

      // Get all the available days from the account, and mark them
    // as available in the datepicker.

    this.daySubscription = this.store.select(fromMedia.getDays).subscribe((days) => {
      this.availableDates = days;
      moment.defaultFormat = "YYYY-MM-DD";
      this.maxDate = moment(this.availableDates[0], moment.defaultFormat).toDate()
      this.minDate = moment(this.availableDates[this.availableDates.length -1], moment.defaultFormat).toDate()
      if(this.currentDate == null && this.availableDates.length > 0) {
        this.currentDate = moment(this.availableDates[0], moment.defaultFormat).toDate();
        this.selectedDates = [moment(this.availableDates[0], moment.defaultFormat)];
      }
    });

    this.sitesSubscription = this.sites$.subscribe(sites => {
      // Setting the sites
      if (sites == null){
        sites = [];
      }
      this.sites = sites.map(s => {
        return {
          value: s.id,
          text: s.name,
          details: s,
          icon: "sites",
        }
      });
    });

    this.cameraSubscription = this.cameras$.subscribe(cameras => {
      // Get all the cameras registered with the account,
      // and list them in the instance selection box.
      this.instances = cameras.map(item => {
         return {
           text: item.name,
           value: item.key,
           version: item.version,
           icon: "camera"
         }
      });

      // This will redo the filtering based on the current
      // selected sites.
      this.instancesFiltered = this.instances;
    });

    this.loadingSubscription = this.loading$.subscribe(loading => this.loading = loading);

    this.exportMediaSubscription = this.exportMedia$.subscribe(exportMedia => {
      this.exportMedia = exportMedia;
      this.canLoadMore = false;
      if(exportMedia && exportMedia.length > 0) {
        for(let i = 0; i < exportMedia.length; i++){
          const media = exportMedia[i];
          if(media.thumbnail_url !== "") {
            this.exportMediaWithPreview = [...this.exportMediaWithPreview, media];
            this.canLoadMore = true;
          }
        }

        // Get the last media item to know the offset.
        if(this.exportMediaWithPreview.length > 0) {
          const media = this.exportMediaWithPreview[this.exportMediaWithPreview.length-1];
          this.exportMediaOffset = media.timestamp;
        }
      }
    });

    this.exportMediaCountSubscription = this.exportMediaCount$.subscribe(exportMediaCount => {
      this.exportMediaCount = exportMediaCount;
    });
  }

  changeTimeline(event) {
    const { value, highValue } = event;
    if (this.startTime != value || this.endTime != highValue) {
      this.startTime = value * 60 * 60;
      this.endTime = highValue * 60 * 60;
    }
    this.getExportedMedia(0);
  }

  onChangeDate(date) {
    this.selectedDates = date;
    this.getExportedMedia(0);
  }

  onChangeSites(sites){
    this.selectedSites = sites;
    this.instancesFiltered = this.instances;
    if (this.selectedSites.length == 0){
      this.selectedInstances = [];
    }
    const filteredSites = this.sites.filter(s => this.selectedSites.indexOf(s.value) > -1);
    if(filteredSites && filteredSites.length) {
      const instancesFiltered = filteredSites.reduce((acc, s) => {
        return [...acc, ...s.details.all_devices]
      }, []);
      this.selectedInstances = this.instances.filter(i => instancesFiltered.indexOf(i.value) > -1).map(i => i.value);
    }
    this.getExportedMedia(0);
  }

  onChangeCameras(cameras){
    this.selectedInstances = cameras;
    this.getExportedMedia(0);
  }

  onChangeAccounts(accounts, labels){
    this.selectedAccounts = accounts;
    this.selectedAccountsLabels = labels;
  }

  getGravatar(email) {
    return 'https://www.gravatar.com/avatar/' + Md5.hashStr(email) + '?s=' + this.gravatarSize + '&d=mm';
  }

  findInvalidControls(form) {
    const invalid = [];
    const controls = form.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    return invalid;
  }

  getExportedMedia(offset) {
    let dates = this.getCleanDate(this.selectedDates);
    const filter = {
      "dates": dates,
      "hourRange": {
        "start": this.startTime,
        "end": this.endTime
      },
      "instances": this.selectedInstances,
      "offset": offset,
      "limit": 32,
    }
    // Check if offset is back to start we can clear the preview array.
    // Filter has been changed
    if (offset === 0) {
      this.exportMediaWithPreview = [];
    }
    this.store.dispatch(new Media.GetExportMedia(filter));
  }

  loadMoreExportedMedia(){
    this.getExportedMedia(this.exportMediaOffset);
  }

  getCleanDate(selectedDates) {
    let dates = [];
    if(selectedDates){
      if(selectedDates.length && selectedDates[0].hasOwnProperty("_isAMomentObject")) {
        dates.push(selectedDates[0].format('DD-MM-Y'));
      }
      else {
        dates = Array.isArray(selectedDates) ? selectedDates : [this.datePipe.transform(selectedDates, 'dd-MM-yyyy')];
      }
    }
    return dates;
  }

  createExport() {
    this.taskUpdated = true;
    this.taskFormInvalidControls = this.findInvalidControls(this.taskForm);
    if(this.taskForm.valid && this.selectedAccounts.length > 0 && this.exportMedia.length > 0) {
      // We will create a task, this will go into the export media
      // microservice. An async job is going to move the recordings.
      const taskData = {
        title: this.taskForm.get('title').value,
        notes: this.taskForm.get('notes').value,
        assignees: this.selectedAccounts,
        assignees_profile: this.selectedAccountsLabels,
        mediaId: "",
        sequenceId: "",
        export_status: "new",
        export_files: this.exportMedia,
        cameras: this.selectedInstances,
      }
      this.store.dispatch(new Tasks.AddTask(taskData))
      return true;
    }
    return false;
  }

  onClose() {
    this.openVideo = false;
  }

  ngOnDestroy() {
    this.accountsSubscription.unsubscribe();
    this.loadingSubscription.unsubscribe();
    this.exportMediaSubscription.unsubscribe();
    this.exportMediaCountSubscription.unsubscribe();
    this.sitesSubscription.unsubscribe();
    this.cameraSubscription.unsubscribe();
    this.daySubscription.unsubscribe();
  }
}
