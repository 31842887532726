import { createSelector, createFeatureSelector } from '@ngrx/store';
import * as fromRoot from '../../../app.reducer';
import * as fromRecordings from './recordings';
export * from './effects';

export interface RecordingsState {
  recordings: fromRecordings.State;
}

export interface State extends fromRoot.State {
  recordings: RecordingsState;
}
export const Reducers = {
  recordings: fromRecordings.reducer
};

export const selectGroupsState = createFeatureSelector<RecordingsState>('recordings');
export const selectGroupsGroupsState = createSelector(selectGroupsState, (state: RecordingsState) => state.recordings);
export const GetRecordings = createSelector(selectGroupsGroupsState, fromRecordings.getRecordings);
