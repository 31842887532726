import {Component, ViewChild, Input, SimpleChanges, OnChanges} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Store} from "@ngrx/store";
import * as fromMedia from "../../../home/media/store";
import * as Media from "../../../home/media/store/actions";
import {Md5} from "ts-md5";
import {DatePipe} from "@angular/common";
import { Tags } from '../tags/tags.component';
const moment = require('moment');

@Component({
  selector: "Share",
  templateUrl: './share.component.html',
  styleUrls: ['./share.component.scss'],
})
export class ShareComponent implements OnChanges  {

  @Input() showText: boolean = true;
  @Input() error: string;
  @Input() sequenceId: any
  @Input() mediaId: any
  @Input() media: any

  public mediaDate:  string = "";
  public mediaTime:  string = "";
  public selectedEmails: string[] = [];

  public shareForm: FormGroup;
  public shareUpdated: boolean = false;
  public shareFormInvalidControls: any[] = [];

  @ViewChild('tags', { static: true }) tagsChild: Tags;

  constructor(private fb: FormBuilder,
              private store: Store<fromMedia.State>,
              public datePipe: DatePipe){

    this.shareForm = this.fb.group({
      title: ['', [Validators.required]],
      notes: ['', [Validators.required]],
    });

    this.findInvalidControls = this.findInvalidControls.bind(this);
    this.onChangeEmails = this.onChangeEmails.bind(this);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.media) {
      this.media = changes.media.currentValue;
      if (this.media) {
        const dateTime = moment(this.media.timestamp * 1000).toDate();
        this.mediaDate = this.datePipe.transform(dateTime, 'MMMM dd, EEEE');
        this.mediaTime = this.datePipe.transform(dateTime, 'HH:MM:SS');
        this.selectedEmails = [];
        this.shareForm.reset();
      }
    }
  }

  onChangeEmails(emails){
    this.selectedEmails = emails;
  }

  findInvalidControls(form) {
    const invalid = [];
    const controls = form.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    return invalid;
  }

  shareMedia() {
    this.shareUpdated = true;
    this.shareFormInvalidControls = this.findInvalidControls(this.shareForm);
    if(this.shareForm.valid && this.selectedEmails.length > 0) {
      const shareData = {
        title: this.shareForm.get('title').value,
        notes: this.shareForm.get('notes').value,
        media: this.media.metadata,
        sequenceId: this.sequenceId,
        recipients: this.selectedEmails,
      }
      this.store.dispatch(new Media.ShareMedia(shareData))
      return true;
    }
    return false;
  }

  cleanup() {
    this.shareUpdated = false;
    this.shareFormInvalidControls = [];
    this.selectedEmails = [];
    this.shareForm.reset();
    this.tagsChild.cleanup();
  }
}
