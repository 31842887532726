import {of as observableOf,  Observable } from 'rxjs';
import {catchError, switchMap, map, mergeMap} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as Action from '../store/actions';
import { IOService } from '../io.service';

@Injectable()
export class Effects {

    /* Retrieve all ios */

    @Effect()
    requestIOs: Observable<Action.RequestIOsFailure | Action.RequestIOsSuccess> = this.actions$.pipe(
      ofType(Action.REQUEST_IOS),
      switchMap((action: Action.RequestIOs) => {
        return this.ioService.getIOs(action.payload).pipe(map((ios) => {
          return new Action.RequestIOsSuccess(ios);
        }),
        catchError(e => {
          return observableOf(new Action.RequestIOsFailure(e));
        }),);
      })
    );

    /* Filter ios */

    @Effect()
    filterIOs: Observable<Action.FilterIOsFailure | Action.FilterIOsSuccess> = this.actions$.pipe(
      ofType(Action.FILTER_IOS),
      switchMap((action: Action.FilterIOs) => {
        return this.ioService.filterIOs(action.payload).pipe(map((ios) => {
          return new Action.FilterIOsSuccess(ios);
        }),
        catchError(e => {
          return observableOf(new Action.FilterIOsFailure(e));
        }),);
      })
    );

    /* Delete io */

    @Effect()
    deleteIO: Observable<Action.DeleteIOFailure | Action.DeleteIOSuccess> = this.actions$.pipe(
      ofType(Action.DELETE_IO),
      switchMap((action: Action.DeleteIO) => {
        return this.ioService.deleteIO(action.payload).pipe(map((io) => {
          return new Action.DeleteIOSuccess(io);
        }),
        catchError(e => {
          return observableOf(new Action.DeleteIOFailure(e));
        }),);
      })
    );

    constructor(private ioService: IOService,
                private actions$: Actions) {}
}
