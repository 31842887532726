import {of as observableOf,  Observable } from 'rxjs';
import {catchError, switchMap, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as Action from '../store/actions';
import { GroupsService } from '../groups.service';

@Injectable()
export class Effects {

    /* Retrieve all Groups */

    @Effect()
    requestGroups: Observable<Action.RequestGroupsFailure | Action.RequestGroupsSuccess> = this.actions$.pipe(
      ofType(Action.REQUEST_GROUPS),
      switchMap((action: Action.RequestGroups) => {
        return this.groupsService.getGroups().pipe(map((groups) => {
          return new Action.RequestGroupsSuccess(groups);
        }),
        catchError(e => {
          return observableOf(new Action.RequestGroupsFailure(e));
        }),);
      })
    );

    /* Find a Group */

    @Effect()
    findGroup: Observable<Action.FindGroupFailure | Action.FindGroupSuccess> = this.actions$.pipe(
      ofType(Action.FIND_GROUP),
      switchMap((action: Action.FindGroup) => {
        return this.groupsService.getGroup(action.payload).pipe(map((group) => {
          return new Action.FindGroupSuccess(group);
        }),
        catchError(e => {
          return observableOf(new Action.FindGroupFailure(e));
        }),);
      })
    );

    /* Find all devices of a Group */

    @Effect()
    findDevicesOfGroup: Observable<Action.FindDevicesOfGroupFailure | Action.FindDevicesOfGroupSuccess> = this.actions$.pipe(
      ofType(Action.FIND_GROUP_DEVICES),
      switchMap((action: Action.FindDevicesOfGroup) => {
        return this.groupsService.getDevicesFromGroup(action.payload).pipe(map((groups) => {
            return new Action.FindDevicesOfGroupSuccess(groups);
          }),
          catchError(e => {
            return observableOf(new Action.FindDevicesOfGroupFailure(e));
          }),);
      })
    );

    /* Add Group */

    @Effect()
    addGroup: Observable<Action.AddGroupFailure | Action.AddGroupSuccess> = this.actions$.pipe(
      ofType(Action.ADD_GROUP),
      switchMap((action: Action.AddGroup) => {
        return this.groupsService.addGroup(action.payload).pipe(map((groups) => {
          return new Action.AddGroupSuccess(groups);
        }),
        catchError(e => {
          return observableOf(new Action.AddGroupFailure(e));
        }),);
      })
    );

    /* Edit Group */

    @Effect()
    editGroup: Observable<Action.EditGroupFailure | Action.EditGroupSuccess> = this.actions$.pipe(
      ofType(Action.EDIT_GROUP),
      switchMap((action: Action.EditGroup) => {
        return this.groupsService.editGroup(action.id, action.payload).pipe(map((groups) => {
          return new Action.EditGroupSuccess(action.id, groups);
        }),
        catchError(e => {
          return observableOf(new Action.EditGroupFailure(e));
        }),);
      })
    );

    /* Delete Group */

    @Effect()
    deleteGroup: Observable<Action.DeleteGroupFailure | Action.DeleteGroupSuccess> = this.actions$.pipe(
      ofType(Action.DELETE_GROUP),
      switchMap((action: Action.DeleteGroup) => {
        return this.groupsService.deleteGroup(action.id).pipe(map((groups) => {
          return new Action.DeleteGroupSuccess(groups);
        }),
        catchError(e => {
          return observableOf(new Action.DeleteGroupFailure(e));
        }),);
      })
    );

    /* Assign devices to Group */

    @Effect()
    assignDevices: Observable<Action.AssignDevicesFailure | Action.AssignDevicesSuccess> = this.actions$.pipe(
      ofType(Action.ASSIGN_DEVICES),
      switchMap((action: Action.AssignDevices) => {
        return this.groupsService.assignDevices(action.id, action.payload).pipe(map((Groups) => {
          return new Action.AssignDevicesSuccess(Groups);
        }),
        catchError(e => {
          return observableOf(new Action.AssignDevicesFailure(e));
        }),);
      })
    );

    constructor(private groupsService: GroupsService,
                private actions$: Actions) {}
}
