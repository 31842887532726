import {Component, Input } from '@angular/core';
import {DatePipe} from "@angular/common";
import {Store} from "@ngrx/store";
import * as fromTasks from "../../../../home/tasks/store";
import * as Tasks from '../../../../home/tasks/store/actions';
import {LoggingService} from "../../../logging.service";
import { not } from '@angular/compiler/src/output/output_ast';
const moment = require('moment');

@Component({
  selector: "WatchLine",
  templateUrl: './watch-line.component.html',
  styleUrls: ['./watch-line.component.scss'],
})
export class WatchLine {

  @Input() notification: any;
  @Input() addTask: any
  @Input() editTask: any
  @Input() markAsRead: any
  @Input() share: any
  @Input() role: string
  public open: boolean = false;

  constructor(private store: Store<fromTasks.State>,
              private log: LoggingService) {}

  createTask(notification){
    if(this.addTask){
      this.addTask(notification)
    }
  }

  shareRecording(notification){
    if(this.share){
      notification.media = {
        timestamp: notification.timestamp,
        instanceName: notification.cameraName,
        sites: notification.sites,
        metadata: {
          key: notification.media_key,
          provider: notification.media_provider,
          source: notification.media_source,
        }
      }
      this.share(notification)
    }
  }

  openNotification(){
    this.open = !this.open;
    if(this.notification && !this.notification.read && this.markAsRead){
      this.markAsRead(this.notification)
    }
  }

  deleteTask() {
    this.open = !this.open;
    this.log.Action("delete-task", {taskId: this.notification.id})
    this.store.dispatch(new Tasks.DeleteTask(this.notification.id));
  }

  truncate(fullStr, strLen, separator) {
    if (fullStr.length <= strLen) return fullStr;

    separator = separator || '...';

    var sepLen = separator.length,
      charsToShow = strLen - sepLen,
      frontChars = Math.ceil(charsToShow/2),
      backChars = Math.floor(charsToShow/2);

    return fullStr.substr(0, frontChars) +
      separator +
      fullStr.substr(fullStr.length - backChars);
  }

  shortenString(text: string, length: number) : string {
    const trimmedString = text.length > length ? text.substring(0, length - 3) : text;
    return trimmedString
  }
}
