
import {of as observableOf,  Observable } from 'rxjs';
import {map, switchMap, catchError} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as Action from '../store/actions';
import { CountingService } from '../counting.service';

@Injectable()
export class Effects {

    /* Get all regions for the different cameras */

    @Effect()
    GetRegions: Observable<Action.GetRegionsFailure | Action.GetRegionsSuccess> = this.actions$.pipe(
        ofType(Action.GET_REGIONS),
        switchMap((action: Action.GetRegions) => {
          return this.countingService.getRegions().pipe(map((data) => {
            const regions = data;
            return new Action.GetRegionsSuccess(regions);
          }),
          catchError(e => {
            return observableOf(new Action.GetRegionsFailure(e));
          }),);
        }));

    /* Add a a region to the device */

    @Effect()
    AddRegion: Observable<Action.AddRegionFailure | Action.AddRegionSuccess> = this.actions$.pipe(
        ofType(Action.ADD_REGION),
        switchMap((action: Action.AddRegion) => {
          const data = {
            device: action.device,
            id: action.id,
            name: action.name,
            direction: action.direction,
            level: action.level,
            zone: action.zone,
            region: action.payload
          };
          return this.countingService.addRegion(data).pipe(map((payload) => {
            return new Action.AddRegionSuccess(payload);
          }),
          catchError(e => {
            return observableOf(new Action.AddRegionFailure(e));
          }),);
        }));

    /* Update a region to the device */

    @Effect()
    UpdateRegion: Observable<Action.UpdateRegionFailure | Action.UpdateRegionSuccess> = this.actions$.pipe(
        ofType(Action.UPDATE_REGION),
        switchMap((action: Action.UpdateRegion) => {
          const data = {
            device: action.device,
            id: action.id,
            name: action.name,
            direction: action.direction,
            level: action.level,
            zone: action.zone,
            region: action.payload
          };
          return this.countingService.updateRegion(data).pipe(map((data) => {
            return new Action.UpdateRegionSuccess(data);
          }),
          catchError(e => {
            return observableOf(new Action.UpdateRegionFailure(e));
          }),);
        }));

    /* Delete a region to the device */

    @Effect()
    DeleteRegion: Observable<Action.DeleteRegionFailure | Action.DeleteRegionSuccess> = this.actions$.pipe(
        ofType(Action.DELETE_REGION),
        switchMap((action: Action.DeleteRegion) => {
          const data = {
            device: action.device,
            id: action.id,
            region: action.payload
          };
          return this.countingService.deleteRegion(data).pipe(map((d) => {
            return new Action.DeleteRegionSuccess(data);
          }),
          catchError(e => {
            return observableOf(new Action.DeleteRegionFailure(e));
          }),);
        }));

    /* Get all counting analytics */

    @Effect()
    GetCountingAnalytics: Observable<Action.GetAnalyticsFailure | Action.GetAnalyticsSuccess> = this.actions$.pipe(
        ofType(Action.GET_ANALYTICS),
        switchMap((action: Action.GetAnalytics) => {
          return this.countingService.getCountingAnalytics(action.payload).pipe(map((data) => {
            const analytics = data;
            return new Action.GetAnalyticsSuccess(analytics);
          }),
          catchError(e => {
            return observableOf(new Action.GetAnalyticsFailure(e));
          }),);
        }));

    constructor(private countingService: CountingService,
                private actions$: Actions) {}
}