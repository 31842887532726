import { createSelector, createFeatureSelector } from '@ngrx/store';
import * as fromRoot from '../../app.reducer';
import * as fromNotification from './notification';
import * as fromReleaseNotes from './releasenotes';
import * as fromMessages from './messages';

export * from './effects';

export interface HomeState {
  notification: fromNotification.State;
  releasenotes: fromReleaseNotes.State;
  messages: fromMessages.State;
}

export interface State extends fromRoot.State {
  home: HomeState;
}

export const Reducers = {
  notification: fromNotification.reducer,
  releasenotes: fromReleaseNotes.reducer,
  messages: fromMessages.reducer
};

export const selectHome= createFeatureSelector<HomeState>('home');
export const selectNotificationState = createSelector(selectHome, (state: HomeState) => state.notification);
export const getNotifications = createSelector(selectNotificationState, fromNotification.getNotifications);
export const getLastNotification = createSelector(selectNotificationState, fromNotification.getLastNotification);
export const selectReleaseNotesState = createSelector(selectHome, (state: HomeState) => state.releasenotes);
export const getReleaseNotes = createSelector(selectReleaseNotesState, fromReleaseNotes.getReleaseNotes);
