import {Component, OnInit, OnDestroy, Input} from '@angular/core';
import { v4 as uuidv4 } from "uuid";
import { WebVoiceProcessor, VuMeterEngine } from '@picovoice/web-voice-processor';
import {
  MqttService,
} from '../../../../mqtt.service';


@Component({
  selector: "Talk",
  templateUrl: './talk.component.html',
  styleUrls: ['./talk.component.scss'],
})
export class Talk implements OnInit, OnDestroy  {

  @Input() hubKey: string;
  @Input() deviceKey: string;
  @Input() webrtc: any = null;
  @Input() onvif: boolean = true;
  @Input() talk: boolean = true;
  @Input() show: boolean = true;
  @Input() hasBackChannel: boolean = false;
  @Input() encrypted: boolean = false;
  public muted: boolean = true;
  public stream: Promise<void>;
  public vuMeterEngine: VuMeterEngine;
  public engine: any;
  public muteToUnmuteCounter: number = 0;
  public volume: number = 0;
  public volumeCSS: string = "volume-0";

  constructor(private mqttService: MqttService) {}

  ngOnInit(){
    this.sendAudio = this.sendAudio.bind(this);
    this.vuMeterCallback = this.vuMeterCallback.bind(this);
    let voice_options = {
      frameLength: 1024,
      outputSampleRate: 8000,
      filterOrder: 50
    };
    WebVoiceProcessor.setOptions(voice_options);
    this.vuMeterEngine = new VuMeterEngine(this.vuMeterCallback);
    this.engine = {
      onmessage: (e) => {
        /// ... handle inputFrame
        const { inputFrame } = e.data;
        let samples = Array.from(inputFrame)
        const topic = "kerberos/agent/" + this.hubKey;
        const payload = {
          action: "get-audio-backchannel",
          device_id: this.deviceKey,
          value: {
            timestamp: Math.floor(Date.now() / 1000),
            data: samples,
          }
        };

        this.mqttService.publish(topic, payload, this.encrypted)
      }
    }
  }

  vuMeterCallback(dB) {
    const MIN_DB = -60;
    let pct = (Math.max(dB, MIN_DB) * 100) / -MIN_DB + 100;
    this.volume = pct;
    if (pct > 40) {
      this.muteToUnmuteCounter = 0;
      if(this.webrtc) {
        this.webrtc.mute(true);
      }
    } else {
      if (this.muteToUnmuteCounter > 3) {
        if(this.webrtc) {
          this.webrtc.mute(false);
        }
      } else {
        this.muteToUnmuteCounter++;
      }
    }

    // Do some colouring
    if(this.volume == 0){
      this.volumeCSS = "volume-0";
    } else if(this.volume >= 10 && this.volume < 20){
      this.volumeCSS = "volume-10";
    } else if(this.volume >= 20 && this.volume < 30){
      this.volumeCSS = "volume-20";
    } else if(this.volume >= 30 && this.volume < 40){
      this.volumeCSS = "volume-30";
    } else if(this.volume >= 40 && this.volume < 50){
      this.volumeCSS = "volume-40";
    } else if(this.volume >= 50 && this.volume < 60){
      this.volumeCSS = "volume-50";
    } else if(this.volume >= 60 && this.volume < 70){
      this.volumeCSS = "volume-60";
    } else if(this.volume >= 70 && this.volume < 80){
      this.volumeCSS = "volume-70";
    } else if(this.volume >= 80 && this.volume < 90){
      this.volumeCSS = "volume-80";
    } else if(this.volume >= 90 && this.volume <= 100){
      this.volumeCSS = "volume-90";
    }
  }

  sendAudio(){
    this.muted = !this.muted;
    if(!this.muted){
      WebVoiceProcessor.subscribe([this.engine, this.vuMeterEngine]);
    } else {
      WebVoiceProcessor.unsubscribe([this.engine, this.vuMeterEngine]);
      if(this.webrtc){
        this.webrtc.mute(false);
      }
      this.volume = 0;
      this.volumeCSS = "volume-0";
    }
  }

  ngOnDestroy() {
    WebVoiceProcessor.unsubscribe([this.engine, this.vuMeterEngine]);
    if(this.webrtc){
      this.webrtc.mute(false);
    }
    this.volume = 0;
    this.volumeCSS = "volume-0";
  }
}
