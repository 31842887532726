import { ActionReducerMap, ActionReducer, MetaReducer, createSelector } from '@ngrx/store';
import { environment } from '../environments/environment';
import { RouterStateUrl } from './shared/utils';
import * as fromRouter from '@ngrx/router-store';

/**
 * As mentioned, we treat each reducer like a table in a database. This means
 * our top level state interface is just a map of keys to inner state types.
 */
export interface State {
  routerReducer: fromRouter.RouterReducerState<RouterStateUrl>;
}

/**
 * Our state is composed of a map of action reducer functions.
 * These reducer functions are called with each dispatched action
 * and the current or initial state and return a new immutable state.
 */
export const reducers: ActionReducerMap<State> = {
  routerReducer: fromRouter.routerReducer
};

export const getRouterState = (state: State) => state.routerReducer;
export const getCurrentUrl = createSelector(getRouterState, (state: fromRouter.RouterReducerState<RouterStateUrl>) => state.state && state.state.url);
export const getNavigationId = createSelector(getRouterState, (state: fromRouter.RouterReducerState<RouterStateUrl>) => state.navigationId);

// console.log all actions
export function logger(reducer: ActionReducer<State>): ActionReducer<State> {
  return function(state: State, action: any): State {
    /*
    console.log(`-------------${new Date().toLocaleTimeString()}---------------`);
    console.log(`State:`, state);
    console.log(`Action:`, action);
    console.log(`-------------END STATE LOGGER----------`);;
    */
    return reducer(state, action);
  };
}

/**
 * By default, @ngrx/store uses combineReducers with the reducer map to compose
 * the root meta-reducer. To add more meta-reducers, provide an array of meta-reducers
 * that will be composed to form the root meta-reducer.
 */
export const metaReducers: MetaReducer<State>[] = !environment.production ? [logger] : [];
