
import {map, take} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { ApiService } from '../../shared/api.service';

@Injectable()
export class RolesService {

  constructor(private http: HttpClient,
              private apiService: ApiService) {}

  public getRoles() : Observable<any> {
    return this.http.get(this.apiService.getRoles()).pipe(take(1),map(value => {
      return value;
    }),);
  }

  public getRole(id) : Observable<any> {
    return this.http.get(this.apiService.getRole(id)).pipe(take(1),map(value => {
      return value;
    }),);
  }

  public addRole(role) : Observable<any> {
    return this.http.post(this.apiService.addRole(), { ...role }).pipe(take(1),map(value => {
      return value;
    }),);
  }

  public editRole(id, role) : Observable<any> {
    return this.http.put(this.apiService.editRole(id), { ...role }).pipe(take(1),map(value => {
      return value;
    }),);
  }

  public deleteRole(id) : Observable<any> {
    return this.http.delete(this.apiService.deleteRole(id)).pipe(take(1),map(value => {
      return value;
    }),);
  }
}
