import * as notifications from './actions';

export interface State {
  alerts: any;
  notifications: any;
  notificationsUpdating: boolean;
}

export const initialState: State = {
  alerts: [],
  notifications: {
    detections: {
      enabled: false,
      channelsAll: true,
      channelsList: [],
      devicesAll: true,
      devicesList: [],
      classificationAll: false,
      classificationList: [],
      timeAdvanced: false,
      timeRange1Min: 0,
      timeRange1Max: 24,
      timeRange2Min: 0,
      timeRange2Max: 24,
    },
    devices: {
      enabled: false,
      channelsAll: true,
      channelsList: [],
      devicesAll: true,
      devicesList: [],
      duration: 30
    },
    highupload: {
      enabled: false,
      channelsAll: true,
      channelsList: [],
      devicesAll: true,
      devicesList: [],
      requests: 30
    }
  },
  notificationsUpdating: false
};

export function reducer(state = initialState, action: notifications.Actions): State {
  switch (action.type) {

    case notifications.REQUEST_NOTIFICATIONS_SETTINGS: {
      return {
        ...state,
        notificationsUpdating: true
      }
    }

    case notifications.REQUEST_NOTIFICATIONS_SETTINGS_FAILURE: {
      const payload = action.payload;
      return {
        ...state,
        notificationsUpdating: false
      }
    }

    case notifications.REQUEST_NOTIFICATIONS_SETTINGS_SUCCESS: {
      const payload = action.payload;
      return {
        ...state,
        notifications: {
          detections: {
            ...initialState.notifications.detections,
            ...payload.detections
          },
          devices: {
            ...initialState.notifications.devices,
            ...payload.devices
          },
          highupload: {
            ...initialState.notifications.highupload,
            ...payload.highupload
          }
        },
        notificationsUpdating: false
      }
    }

    case notifications.UPDATE_NOTIFICATIONS_SETTINGS: {
      return {
        ...state,
        notificationsUpdating: true
      }
    }

    case notifications.UPDATE_NOTIFICATIONS_SETTINGS_FAILURE: {
      const payload = action.payload;
      return {
        ...state,
        notificationsUpdating: false
      }
    }

    case notifications.UPDATE_NOTIFICATIONS_SETTINGS_SUCCESS: {
      const payload = action.payload;
      const type = payload.type;
      const value = payload.payload;

      return {
        ...state,
        notifications: {
          ...state.notifications,
          [type]: {
            ...state.notifications[type],
            ...value
          }
        },
        notificationsUpdating: false
      }
    }

    case notifications.TOGGLE_CUSTOMALERT_SUCCESS: {
      const payload = action.payload;
      return {
        ...state,
        alerts: payload
      }
    }


    case notifications.REQUEST_CUSTOMALERT_SUCCESS: 
    case notifications.UPDATE_CUSTOMALERT_SUCCESS: 
    case notifications.CREATE_CUSTOMALERT_SUCCESS: 
    case notifications.REMOVE_CUSTOMALERT_SUCCESS: {
      const payload = action.payload;
      return {
        ...state,
        alerts: payload
      }
    }

    case notifications.RESET: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}

export const getNotifications = (state: State) => state.notifications;
export const getDetections = (state: State) => state.notifications.detections;
export const getDevices = (state: State) => state.notifications.devices;
export const getHighUpload = (state: State) => state.notifications.highupload;
export const getNotificationsUpdating = (state: State) => state.notificationsUpdating;
export const getCustomAlerts = (state: State) => state.alerts;
