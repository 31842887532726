import {Component, OnInit, OnDestroy, Input, OnChanges} from '@angular/core';
import * as svg from "../../../shared/svg.inject";

@Component({
  selector: "InfoBox",
  templateUrl: './infobox.component.html',
  styleUrls: ['./infobox.component.scss'],
})
export class InfoBox implements OnInit, OnDestroy, OnChanges  {

  @Input() type: string;
  @Input() text: string;
  @Input() link: string;
  @Input() onClose: any;
  public classes: any;

  constructor() {}

  ngOnInit(){
    this.classes = {
      info: this.type === 'info',
      error: this.type === 'error',
      success: this.type === 'success',
      pointer: this.link && this.link.length
    }
  }

  ngAfterViewInit(){
    svg.inject();
  }

  ngOnChanges(changes) {

  }

  ngOnDestroy(){
  }
}
