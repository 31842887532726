
import { map, take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { ApiService } from '../../shared/api.service';

@Injectable()
export class SitesService {

  constructor(private http: HttpClient,
              private apiService: ApiService) {}

  public getSites() : Observable<any> {
    return this.http.get(this.apiService.getSites()).pipe(take(1),map(value => {
      return value;
    }),);
  }

  public getSite(id) : Observable<any> {
    return this.http.get(this.apiService.getSite(id)).pipe(take(1),map(value => {
      return value;
    }),);
  }

  public getDevicesFromSite(id) : Observable<any> {
    return this.http.get(this.apiService.getDevicesFromSite(id)).pipe(take(1),map(value => {
      return value;
    }),);
  }


  public addSite(site) : Observable<any> {
    return this.http.post(this.apiService.addSite(), { site }).pipe(take(1),map(value => {
      return value;
    }),);
  }

  public editSite(id, site) : Observable<any> {
    return this.http.put(this.apiService.editSite(id), { site }).pipe(take(1),map(value => {
      return value;
    }),);
  }

  public deleteSite(id) : Observable<any> {
    return this.http.delete(this.apiService.deleteSite(id)).pipe(take(1),map(value => {
      return value;
    }),);
  }

  public assignDevices(id, devices) : Observable<any> {
    return this.http.put(this.apiService.assignDevices(id), { devices }).pipe(take(1),map(value => {
      return value;
    }),);
  }
}
