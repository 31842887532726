import { Action } from '@ngrx/store';

export const RESET = '[Counting] Reset Livestream properties';
export const REQUEST_LIVESTREAM = '[Counting] Requesting Livestream properties';
export const REQUEST_LIVESTREAM_SUCCESS = '[Counting] Requesting Livestream properties Success';
export const REQUEST_LIVESTREAM_FAILURE = '[Counting] Requesting Livestream properties Failure';
export const TOGGLE_SPEECH = '[Counting] Toggle Speech';
export const TOGGLE_SPEECH_SUCCESS = '[Counting] Toggle Speech Success';
export const TOGGLE_SPEECH_FAILURE = '[Counting] Toggle Speechs Failure';
export const GET_REGIONS = '[Counting] Get Regions';
export const GET_REGIONS_SUCCESS = '[Counting] Get Regions Success';
export const GET_REGIONS_FAILURE = '[Counting] Get Regions Failure';
export const ADD_REGION = '[Counting] Add Region';
export const ADD_REGION_SUCCESS = '[Counting] Add Region Success';
export const ADD_REGION_FAILURE = '[Counting] Add Region Failure';
export const UPDATE_REGION = '[Counting] Update Region';
export const UPDATE_REGION_SUCCESS = '[Counting] Update Region Success';
export const UPDATE_REGION_FAILURE = '[Counting] Update Region Failure';
export const DELETE_REGION = '[Counting] Delete Region';
export const DELETE_REGION_SUCCESS = '[Counting] Delete Region Success';
export const DELETE_REGION_FAILURE = '[Counting] Delete Region Failure';
export const GET_ANALYTICS = '[Counting] Get Analytics';
export const GET_ANALYTICS_SUCCESS = '[Counting] Get Analytics Success';
export const GET_ANALYTICS_FAILURE = '[Counting] Get Analytics Failure';
export const GET_FILTER = '[Counting] Get filter';
export const SET_FILTER = '[Counting] Set filter';

export class Reset implements Action {
  readonly type = RESET;

  constructor() {}
}

export class RequestLivestream implements Action {
  readonly type = REQUEST_LIVESTREAM;

  constructor() {}
}

export class RequestLivestreamSuccess implements Action {
  readonly type = REQUEST_LIVESTREAM_SUCCESS;

  constructor(public payload: any) {}
}

export class RequestLivestreamFailure implements Action {
  readonly type = REQUEST_LIVESTREAM_FAILURE;

  constructor(public payload?: any) {}
}

export class ToggleSpeech implements Action {
  readonly type = TOGGLE_SPEECH;

  constructor(public payload: any) {}
}

export class ToggleSpeechSuccess implements Action {
  readonly type = TOGGLE_SPEECH_SUCCESS;

  constructor(public payload: any) {}
}

export class ToggleSpeechFailure implements Action {
  readonly type = TOGGLE_SPEECH_FAILURE;

  constructor(public payload?: any) {}
}

export class GetRegions implements Action {
  readonly type = GET_REGIONS;

  constructor() {}
}

export class GetRegionsSuccess implements Action {
  readonly type = GET_REGIONS_SUCCESS;

  constructor(public payload: any) {}
}

export class GetRegionsFailure implements Action {
  readonly type = GET_REGIONS_FAILURE;

  constructor(public payload?: any) {}
}

export class AddRegion implements Action {
  readonly type = ADD_REGION;

  constructor(public device, public id, public name, public direction, public level, public zone, public payload: any) {}
}

export class AddRegionSuccess implements Action {
  readonly type = ADD_REGION_SUCCESS;

  constructor(public payload: any) {}
}

export class AddRegionFailure implements Action {
  readonly type = ADD_REGION_FAILURE;

  constructor(public payload?: any) {}
}

export class UpdateRegion implements Action {
  readonly type = UPDATE_REGION;

  constructor(public device, public id, public name, public direction, public level, public zone, public payload: any) {}
}

export class UpdateRegionSuccess implements Action {
  readonly type = UPDATE_REGION_SUCCESS;

  constructor(public payload: any) {}
}

export class UpdateRegionFailure implements Action {
  readonly type = UPDATE_REGION_FAILURE;

  constructor(public payload?: any) {}
}

export class DeleteRegion implements Action {
  readonly type = DELETE_REGION;

  constructor(public device, public id, public payload: any) {}
}

export class DeleteRegionSuccess implements Action {
  readonly type = DELETE_REGION_SUCCESS;

  constructor(public payload: any) {}
}

export class DeleteRegionFailure implements Action {
  readonly type = DELETE_REGION_FAILURE;

  constructor(public payload?: any) {}
}

export class GetAnalytics implements Action {
  readonly type = GET_ANALYTICS;

  constructor(public payload?: any) {}
}

export class GetAnalyticsSuccess implements Action {
  readonly type = GET_ANALYTICS_SUCCESS;

  constructor(public payload: any) {}
}

export class GetAnalyticsFailure implements Action {
  readonly type = GET_ANALYTICS_FAILURE;

  constructor(public payload?: any) {}
}

// --------------------
// Get and set filter
//

export class GetFilter implements Action {
  readonly type = GET_FILTER;

  constructor(public payload?: any) {}
}

export class SetFilter implements Action {
  readonly type = SET_FILTER;

  constructor(public payload?: any) {}
}


export type Actions =
  | Reset
  | RequestLivestream
  | RequestLivestreamSuccess
  | RequestLivestreamFailure
  | ToggleSpeech
  | ToggleSpeechSuccess
  | ToggleSpeechFailure
  | AddRegion
  | AddRegionSuccess
  | AddRegionFailure
  | UpdateRegion
  | UpdateRegionSuccess
  | UpdateRegionFailure
  | DeleteRegion
  | DeleteRegionSuccess
  | DeleteRegionFailure
  | GetRegions
  | GetRegionsSuccess
  | GetRegionsFailure
  | GetAnalytics
  | GetAnalyticsSuccess
  | GetAnalyticsFailure
  | GetFilter
  | SetFilter;
