import {of as observableOf,  Observable } from 'rxjs';
import {catchError, switchMap, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as Action from '../store/actions';
import { ProfileService } from '../profile.service';

@Injectable()
export class Effects {

    /* Retrieve all invoices from current user */

    @Effect()
    requestProfile: Observable<Action.RequestProfileFailure | Action.RequestProfileSuccess> = this.actions$.pipe(
      ofType(Action.REQUEST_PROFILE),
      switchMap((action: Action.RequestProfile) => {
        return this.profileService.getProfile().pipe(map((profile) => {
          return new Action.RequestProfileSuccess(profile);
        }),
        catchError(e => {
          return observableOf(new Action.RequestProfileFailure(e));
        }),);
      }));

    /* Update profile */

    @Effect()
    updateProfile: Observable<Action.UpdateProfileFailure | Action.UpdateProfileSuccess> = this.actions$.pipe(
      ofType(Action.UPDATE_PROFILE),
      switchMap((action: Action.UpdateProfile) => {
        return this.profileService.updateProfile(action.payload).pipe(map(profile => {
          //mixpanel.track("updateProfile", {profile: action.payload});
          return new Action.UpdateProfileSuccess(profile);
        }),
        catchError((e) => {
          //mixpanel.track("updateProfileFailed", {profile: action.payload, error: e});
          return observableOf(new Action.UpdateProfileFailure(e.error));
        }),);
      }));

    /* Update settings */

    @Effect()
    updateSettings: Observable<Action.UpdateSettingsFailure | Action.UpdateSettingsSuccess> = this.actions$.pipe(
      ofType(Action.UPDATE_SETTINGS),
      switchMap((action: Action.UpdateSettings) => {
        return this.profileService.updateSettings(action.payload).pipe(map(settings => {
            //mixpanel.track("updateSettings", {settings: action.payload});
            return new Action.UpdateSettingsSuccess(settings);
          }),
          catchError((e) => {
            //mixpanel.track("updateSettingsFailed", {settings: action.payload, error: e});
            return observableOf(new Action.UpdateSettingsFailure(e.error));
          }),);
      }));

    /* Update credentials */

    @Effect()
    updateCredentials: Observable<Action.UpdateCredentialsFailure | Action.UpdateCredentialsSuccess> = this.actions$.pipe(
      ofType(Action.UPDATE_CREDENTIALS),
      switchMap((action: Action.UpdateCredentials) => {
        return this.profileService.updateCredentials(action.payload).pipe(map(credentials => {
          //mixpanel.track("updateCredentials");
          return new Action.UpdateCredentialsSuccess(credentials);
        }),
        catchError((e) => {
          //mixpanel.track("updateCredentialsFailed", {error: e});
          return observableOf(new Action.UpdateCredentialsFailure(e.error));
        }),);
      }));


    /* Retrieve all invoices from current user */

    @Effect()
    requestInvoices: Observable<Action.RequestInvoicesFailure | Action.RequestInvoicesSuccess> = this.actions$.pipe(
      ofType(Action.REQUEST_INVOICES),
      switchMap((action: Action.RequestInvoices) => {
        return this.profileService.getInvoices().pipe(map((invoices) => {
          return new Action.RequestInvoicesSuccess(invoices);
        }),
        catchError(e => {
          return observableOf(new Action.RequestInvoicesFailure(e));
        }),);
      }));

    /* Add a new credit card */

    @Effect()
    updateCard: Observable<Action.UpdateCardSuccess | Action.UpdateCardFailure> = this.actions$.pipe(
      ofType(Action.UPDATE_CARD),
      switchMap((action: Action.UpdateCard) => {
        return this.profileService.updateCard(action.payload).pipe(map(card => {
          //mixpanel.track("updateCard");
          return new Action.UpdateCardSuccess(card);
        }),
        catchError((e) => {
          //mixpanel.track("updateCardFailed", {error: e});
          return observableOf(new Action.UpdateCardFailure(e.error.error));
        }),);
      }));

    /* Remove credit card */

    @Effect()
    removeCard: Observable<Action.DeleteCardSuccess | Action.DeleteCardFailure> = this.actions$.pipe(
      ofType(Action.DELETE_CARD),
      switchMap((action: Action.DeleteCard) => {
        return this.profileService.deleteCard().pipe(map(card => {
          //mixpanel.track("deleteCard");
          return new Action.DeleteCardSuccess(card);
        }),
        catchError((e) => {
          //mixpanel.track("deleteCardFailed", {error: e});
          return observableOf(new Action.DeleteCardFailure(e.error.error));
        }),);
      }));

    /* Get QR code for 2FA */

    @Effect()
    getQRCode: Observable<Action.GetQRCodeFailure | Action.GetQRCodeSuccess> = this.actions$.pipe(
      ofType(Action.GET_QRCODE),
      switchMap((action: Action.GetQRCode) => {
        return this.profileService.getQRCode().pipe(map((qrcode) => {
          return new Action.GetQRCodeSuccess(qrcode);
        }),
        catchError(e => {
          return observableOf(new Action.GetQRCodeFailure(e));
        }),);
      }));

    /* Validate QR code for 2FA */

    @Effect()
    validateQRCode: Observable<Action.ValidateQRCodeFailure | Action.ValidateQRCodeSuccess> = this.actions$.pipe(
      ofType(Action.VALIDATE_QRCODE),
      switchMap((action: Action.ValidateQRCode) => {
        return this.profileService.validateQRCode(action.payload).pipe(map((qrcode) => {
          return new Action.ValidateQRCodeSuccess(qrcode);
        }),
        catchError(e => {
          return observableOf(new Action.ValidateQRCodeFailure(e));
        }),);
      }));
        

    /* Disable 2FA */

    @Effect()
    disableMFA: Observable<Action.DisableMFAFailure | Action.DisableMFASuccess> = this.actions$.pipe(
      ofType(Action.DISABLE_MFA),
      switchMap((action: Action.DisableMFA) => {
        return this.profileService.disableMFA().pipe(map((mfa) => {
          return new Action.DisableMFASuccess(mfa);
        }),
        catchError(e => {
          return observableOf(new Action.DisableMFAFailure(e));
        }),);
      }));


    /*  Set encryption */

    @Effect()
    setEncryption: Observable<Action.SetEncryptionFailure | Action.SetEncryptionSuccess> = this.actions$.pipe(
      ofType(Action.SET_ENCRYPTION),
      switchMap((action: Action.SetEncryption) => {
        return this.profileService.setEncryption(action.payload).pipe(map((encryption) => {
          return new Action.SetEncryptionSuccess(encryption.data);
        }),
        catchError(e => {
          return observableOf(new Action.SetEncryptionFailure(e));
        }),);
      }));

    constructor(private profileService: ProfileService,
                private actions$: Actions) {}
}
