import {of as observableOf,  Observable } from 'rxjs';
import {catchError, switchMap, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as Action from '../store/actions';
import { AnalyticsService } from '../analytics.service';

@Injectable()
export class Effects {

    /* Retrieve all notifications */

    @Effect()
    requestAnalytics: Observable<Action.RequestAnalyticsFailure | Action.RequestAnalyticsSuccess> = this.actions$.pipe(
        ofType(Action.REQUEST_ANALYTICS),
        switchMap((action: Action.RequestAnalytics) => {
          return this.analyticsService.getStatistics(action.payload).pipe(map((statistics) => {
            return new Action.RequestAnalyticsSuccess(statistics);
          }),
          catchError(e => {
            return observableOf(new Action.RequestAnalyticsFailure(e));
          }),);
        }));


    constructor(private analyticsService: AnalyticsService,
                private actions$: Actions) {}
}
