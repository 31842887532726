import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { HttpClientModule } from '@angular/common/http';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { LoginRoutes } from './login.routes';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { Reducers, Effects } from './store';
import {WidgetModule} from "../shared/widgets/widget.module";


@NgModule({
  imports: [
    CommonModule,
    LoginRoutes.getAppRoutes(),
    FormsModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    WidgetModule,
    DeviceDetectorModule.forRoot(),
  ],
  declarations: LoginRoutes.getDeclarations()
})
export class LoginModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: RootLoginModule,
      providers: LoginRoutes.getProviders(),
    };
  }
}

@NgModule({
  imports: [
    HttpClientModule,
    StoreModule.forFeature('auth', Reducers),
    LoginModule,
    EffectsModule.forFeature([Effects])
  ],
})
export class RootLoginModule {}
