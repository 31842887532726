
import {of as observableOf,  Observable } from 'rxjs';

import {catchError, switchMap, take} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';

import { Store } from '@ngrx/store';
import { Authenticate } from './models/user';
import * as fromAuth from './store';
import * as Auth from './store/actions';

// https://github.com/angular/angular/issues/18224#issuecomment-329939990
// Workaround acylic loop error.

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(private store: Store<fromAuth.State>) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // We'll try to read the access token from store, and if found
    // the access token is added to the Authorization header on every request.
    return this.store.select(fromAuth.getUser).pipe(take(1),switchMap(user => {
      if(user && user.accesstoken) {
        return observableOf(user);
      }
    }),
    switchMap(user => {
      const noAuthorization = request.headers.get('X-No-Authorization');
      if(noAuthorization == null) {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${user.accesstoken}`,
          }
        });
      } else {
        request = request.clone({headers: request.headers.delete("X-No-Authorization", 'true')});
      }
      return next.handle(request);
    }),catchError(error => {
      return next.handle(request)
    }),);
  }
}
