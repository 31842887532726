import {Component, OnInit, OnDestroy, Input} from '@angular/core';

@Component({
  selector: "BlockButton",
  templateUrl: './block-button.component.html',
  styleUrls: ['./block-button.component.scss'],
})
export class BlockButton implements OnInit, OnDestroy  {

  @Input("icon") icon: any;
  @Input("title") title: any;
  @Input("disabled") disabled: boolean = false;

  constructor() {}

  ngOnInit() {

  }

  ngAfterViewInit(){
  }


  ngOnDestroy(){
  }

}
