import {map, take, filter} from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import * as fromAuth from '../login/store';

@Injectable()
export class AdminGuard implements CanActivate {

  constructor(private store: Store<fromAuth.State>) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.store.select(fromAuth.getUser).pipe(take(1),map(state => {
      return state.role === "admin" || state.role === "owner";
    }));
  }
}
