import { Action } from '@ngrx/store';

export const RESET = '[Watchlist] Reset';
export const REQUEST_WATCHLIST = '[Watchlist] Requesting notifications';
export const REQUEST_WATCHLIST_SUCCESS = '[Watchlist] Requesting notifications Success';
export const REQUEST_WATCHLIST_FAILURE = '[Watchlist] Requesting notifications Failure';
export const MARKREAD_WATCHLIST = '[Watchlist] Marking notification as read';
export const MARKREAD_WATCHLIST_SUCCESS = '[Watchlist] Marking notification Success';
export const MARKREAD_WATCHLIST_FAILURE = '[Watchlist] Marking notification Failure';
export const REQUEST_FILTERED_WATCHLIST = '[Watchlist] Requesting filtered notifications';
export const REQUEST_FILTERED_WATCHLIST_SUCCESS = '[Watchlist] Requesting filtered notifications Success';
export const REQUEST_FILTERED_WATCHLIST_FAILURE = '[Watchlist] Requesting filtered notifications Failure';
export const GET_WATCHLIST_STATISTICS = '[Watchlist] Get notifications statistics';
export const GET_WATCHLIST_STATISTICS_SUCCESS = '[Watchlist] Get notifications statistics Success';
export const GET_WATCHLIST_STATISTICS_FAILURE = '[Watchlist] Get notifications statistics Failure';
export const ADD_WATCHLIST = '[Watchlist] Add notification';
export const ADD_WATCHLIST_SUCCESS = '[Watchlist] Add notification Success';
export const ADD_WATCHLIST_FAILURE = '[Watchlist] Add notification Failure';
export const EDIT_WATCHLIST = '[Watchlist] Edit notification';
export const EDIT_WATCHLIST_SUCCESS = '[Watchlist] Edit notification Success';
export const EDIT_WATCHLIST_FAILURE = '[Watchlist] Edit notification Failure';
export const DELETE_WATCHLIST = '[Watchlist] Delete notification';
export const DELETE_WATCHLIST_SUCCESS = '[Watchlist] Delete notification Success';
export const DELETE_WATCHLIST_FAILURE = '[Watchlist] Delete notification Failure';
export const READ_ALL = '[Watchlist] Read all events';
export const READ_ALL_SUCCESS = '[Watchlist] Read all events Success';
export const READ_ALL_FAILURE = '[Watchlist] Read all events Failure';
export const RESET_MESSAGE = '[Watchlist] Hide success';

export class Reset implements Action {
  readonly type = RESET;

  constructor() {}
}

export class ResetMessage implements Action {
  readonly type = RESET_MESSAGE;

  constructor() {}
}

export class RequestWatchlists implements Action {
  readonly type = REQUEST_WATCHLIST;

  constructor() {}
}

export class RequestWatchlistsSuccess implements Action {
  readonly type = REQUEST_WATCHLIST_SUCCESS;

  constructor(public payload: any) {}
}

export class RequestWatchlistsFailure implements Action {
  readonly type = REQUEST_WATCHLIST_FAILURE;

  constructor(public payload?: any) {}
}

export class EditWatchlist implements Action {
  readonly type = EDIT_WATCHLIST;

  constructor(public id: any, public payload: any) {}
}

export class EditWatchlistSuccess implements Action {
  readonly type = EDIT_WATCHLIST_SUCCESS;

  constructor(public payload: any) {}
}

export class EditWatchlistFailure implements Action {
  readonly type = EDIT_WATCHLIST_FAILURE;

  constructor(public payload?: any) {}
}

export class RequestFilteredWatchlists implements Action {
  readonly type = REQUEST_FILTERED_WATCHLIST;

  constructor(public payload: any) {}
}

export class RequestFilteredWatchlistsSuccess implements Action {
  readonly type = REQUEST_FILTERED_WATCHLIST_SUCCESS;

  constructor(public filter: any, public payload: any) {}
}

export class RequestFilteredWatchlistsFailure implements Action {
  readonly type = REQUEST_FILTERED_WATCHLIST_FAILURE;

  constructor(public payload?: any) {}
}

export class GetWatchlistsStatistics implements Action {
  readonly type = GET_WATCHLIST_STATISTICS;

  constructor() {}
}

export class GetWatchlistsStatisticsSuccess implements Action {
  readonly type = GET_WATCHLIST_STATISTICS_SUCCESS;

  constructor(public payload: any) {}
}

export class GetWatchlistsStatisticsFailure implements Action {
  readonly type = GET_WATCHLIST_STATISTICS_FAILURE;

  constructor(public payload?: any) {}
}

export class AddWatchlist implements Action {
  readonly type = ADD_WATCHLIST;

  constructor(public payload: any, public sequenceId:string, public mediaId:number) {}
}

export class AddWatchlistSuccess implements Action {
  readonly type = ADD_WATCHLIST_SUCCESS;
  constructor(public payload: any) {}
}

export class AddWatchlistFailure implements Action {
  readonly type = ADD_WATCHLIST_FAILURE;

  constructor(public payload?: any) {}
}

export class DeleteWatchlist implements Action {
  readonly type = DELETE_WATCHLIST;

  constructor(public id: any) {}
}

export class DeleteWatchlistSuccess implements Action {
  readonly type = DELETE_WATCHLIST_SUCCESS;

  constructor(public payload: any) {}
}

export class DeleteWatchlistFailure implements Action {
  readonly type = DELETE_WATCHLIST_FAILURE;

  constructor(public payload?: any) {}
}

export class ReadAll implements Action {
  readonly type = READ_ALL;

  constructor() {}
}

export class ReadAllSuccess implements Action {
  readonly type = READ_ALL_SUCCESS;

  constructor(public payload: any) {}
}

export class ReadAllFailure implements Action {
  readonly type = READ_ALL_FAILURE;

  constructor(public payload?: any) {}
}


export type Actions =
  | Reset
  | ResetMessage
  | RequestWatchlists
  | RequestWatchlistsSuccess
  | RequestWatchlistsFailure
  | EditWatchlist
  | EditWatchlistSuccess
  | EditWatchlistFailure
  | RequestFilteredWatchlists
  | RequestFilteredWatchlistsFailure
  | RequestFilteredWatchlistsSuccess
  | GetWatchlistsStatistics
  | GetWatchlistsStatisticsSuccess
  | GetWatchlistsStatisticsFailure
  | AddWatchlist
  | AddWatchlistSuccess
  | AddWatchlistFailure
  | DeleteWatchlist
  | DeleteWatchlistSuccess
  | DeleteWatchlistFailure
  | ReadAll
  | ReadAllSuccess
  | ReadAllFailure;
