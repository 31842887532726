import { createSelector, createFeatureSelector } from '@ngrx/store';
import * as fromRoot from '../../../app.reducer';
import * as fromAccounts from './accounts';

export * from './effects';

export interface AccountsState {
  accounts: fromAccounts.State;
}

export interface State extends fromRoot.State {
  accounts: AccountsState;
}

export const Reducers = {
  accounts: fromAccounts.reducer
};

export const selectAccountsState = createFeatureSelector<AccountsState>('accounts');
export const selectAccountsAccountsState = createSelector(selectAccountsState, (state: AccountsState) => state.accounts);
export const getAccounts = createSelector(selectAccountsAccountsState, fromAccounts.getAccounts);
export const getError = createSelector(selectAccountsAccountsState, fromAccounts.getError);
//export const getSite = createSelector(selectSitesSitesState, fromSites.getSite);
