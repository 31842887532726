
import {map, take, filter} from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';

import * as fromAuth from '../login/store';

@Injectable()
export class LoggedInGuard implements CanActivate {

  constructor(private store: Store<fromAuth.State>) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.store.select(fromAuth.getLoginState).pipe(filter(state => state.requested),take(1),map(state => state.loggedIn),);
  }
}
