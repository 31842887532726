import * as auth from './actions';
import { User } from '../models/user';
import * as Raven from 'raven-js';
import { domain } from 'process';

export interface State {
  loggedIn: boolean;
  requested: boolean;
  registerPending: boolean;
  registered: boolean;
  registeredError: any;
  forgotPending: boolean;
  forgot: boolean;
  forgotError: any;
  activatePending: boolean;
  activateStatus: string;
  user: any;
  domains: any;
}

export const initialState: State = {
  loggedIn: false,
  requested: false,
  registerPending: false,
  registered: false,
  registeredError: null,
  forgotPending: false,
  forgot: false,
  forgotError: null,
  activatePending: false,
  activateStatus: "info",
  user: null,
  domains: {
    domains: [],
    forceSSO: [],
  }
};

export function reducer(state = initialState, action: auth.Actions): State {
  switch (action.type) {
    case auth.LOGIN_FAILURE: {
      return {
        ...state,
        loggedIn: false,
        requested: true
      };
    }

    case auth.GET_SSO_DOMAINS_SUCCESS: {
      const { domains } = action.payload;
      return {
        ...state,
        domains: {
          domains: domains.domains,
          forceSSO: domains.forceSSO
        }
      };
    }

    case auth.LOGIN_SUCCESS: {

      // Set Sentry.js user context
      Raven.setUserContext({
        email: action.payload.email
      });

      return {
        ...state,
        loggedIn: true,
        requested: true,
        user: action.payload,
      };
    }

    case auth.REGISTER: {
      return {
        ...state,
        registerPending: true,
        registeredError: null
      };
    }

    case auth.REGISTER_SUCCESS: {
      return {
        ...state,
        registered: true,
        registerPending: false,
        registeredError: null
      };
    }

    case auth.REGISTER_FAILURE: {
      return {
        ...state,
        registered: false,
        registerPending: false,
        registeredError: action.payload
      };
    }

    case auth.FORGOT: {
      return {
        ...state,
        forgot: false,
        forgotPending: true,
        forgotError: null
      };
    }

    case auth.FORGOT_SUCCESS: {
      return {
        ...state,
        forgot: true,
        forgotPending: false,
        forgotError: null
      };
    }

    case auth.FORGOT_FAILURE: {
      return {
        ...state,
        forgot: false,
        forgotPending: false,
        forgotError: action.payload
      };
    }

    case auth.ACTIVATE: {
      return {
        ...state,
        activatePending: true
      };
    }

    case auth.RESET_ACTIVATE: {
      return {
        ...state,
        activateStatus: "info"
      };
    }

    case auth.ACTIVATE_SUCCESS: {
      return {
        ...state,
        activatePending: false,
        activateStatus: "success"
      };
    }

    case auth.ACTIVATE_FAILURE: {
      return {
        ...state,
        activatePending: false,
        activateStatus: "failed"
      };
    }

    case auth.LOGOUT_SUCCESS: {
      return initialState;
    }

    case auth.RESET: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}

export const getLoginState = (state: State) => state;
export const getLoggedIn = (state: State) => state.loggedIn;

export const getRegistered = (state: State) => state.registered;
export const getRegisterPending = (state: State) => state.registerPending;
export const getRegisteredError = (state: State) => state.registeredError;

export const getForgot = (state: State) => state.forgot;
export const getForgotPending = (state: State) => state.forgotPending;
export const getForgotError = (state: State) => state.forgotError;

export const getActivateStatus = (state: State) => state.activateStatus;
export const getActivatePending = (state: State) => state.activatePending;
export const getUser = (state: State) => state.user;
export const getSSODomains = (state: State) => state.domains;
