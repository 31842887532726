import { createSelector, createFeatureSelector } from '@ngrx/store';
import * as fromRoot from '../../../app.reducer';
import * as fromGroups from './groups';
export * from './effects';

export interface GroupsState {
  groups: fromGroups.State;
}

export interface State extends fromRoot.State {
  groups: GroupsState;
}
export const Reducers = {
  groups: fromGroups.reducer
};

export const selectGroupsState = createFeatureSelector<GroupsState>('groups');
export const selectGroupsGroupsState = createSelector(selectGroupsState, (state: GroupsState) => state.groups);
export const getGroups = createSelector(selectGroupsGroupsState, fromGroups.getGroups);
export const getGroup = createSelector(selectGroupsGroupsState, fromGroups.getGroup);
export const getGroupDevices = createSelector(selectGroupsGroupsState, fromGroups.getGroupDevices);
