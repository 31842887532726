import {Component, OnInit, OnDestroy, Input} from '@angular/core';

@Component({
  selector: "GridToggle",
  templateUrl: './gridtoggle.component.html',
  styleUrls: ['./gridtoggle.component.scss'],
})
export class GridToggle implements OnInit, OnDestroy  {

  @Input() onChange: any;
  @Input() icon: string;
  @Input() active: string;

  public state = {
    'selected': '2'
  };

  constructor() {
    this.changeToggle = this.changeToggle.bind(this);
  }

  ngOnInit(){

  }

  ngAfterViewInit(){
  }


  ngOnDestroy(){
  }

  changeToggle(value) {
    this.state.selected = value;
    this.onChange(value);
  }
}
