import {Component, Input} from '@angular/core';
import {Md5} from "ts-md5";

@Component({
  selector: "RolesList",
  templateUrl: './roles-list.component.html',
  styleUrls: ['./roles-list.component.scss'],
})
export class RolesList {

  @Input() user: any;
  @Input() roles: any;
  @Input() editRole: any
  @Input() deleteRole: any

  public gravatarSize: number = 40;

  constructor() {}

  public trackRole(index: number, role: any) {
    return role.id;
  }
}
