import { createSelector, createFeatureSelector } from '@ngrx/store';
import * as fromRoot from '../../../app.reducer';
import * as fromAnalytics from './analytics';

export * from './effects';

export interface AnalyticsState {
  analytics: fromAnalytics.State;
}

export interface State extends fromRoot.State {
  analytics: AnalyticsState;
}
export const Reducers = {
  analytics: fromAnalytics.reducer
};

export const selectTasksState = createFeatureSelector<AnalyticsState>('analytics');
export const selectTasksTasksState = createSelector(selectTasksState, (state: AnalyticsState) => state.analytics);
export const getState = createSelector(selectTasksTasksState, fromAnalytics.getState);
export const getStatistics = createSelector(selectTasksTasksState, fromAnalytics.getStatistics);
export const isLoading = createSelector(selectTasksTasksState, fromAnalytics.isLoading);

