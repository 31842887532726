import * as media from './actions';
import * as _ from "lodash";
import * as tasks from "../../tasks/store/actions";

export interface State {
  error: any;
  loading: boolean;
  loadingVault: boolean;
  loadingSequence: boolean;
  canLoadMore: boolean;
  scrollPosition: any;
  currentMedia: any;
  currentAnalysis: any;
  media: Array<any>;
  exportMedia: Array<any>;
  exportMediaCount: number;
  currentVault: any;
  vault: Array<any>;
  days: Array<any>;
  hours: Array<any>;
  filter: any;
}

export const initialState: State = {
  error: null,
  loading: false,
  loadingVault: false,
  loadingSequence: false,
  canLoadMore: true,
  scrollPosition: 0,
  currentMedia: null,
  currentAnalysis: null,
  media: [],
  exportMedia: [],
  exportMediaCount: 0,
  currentVault: null,
  vault: [],
  days: [],
  hours: [],
  filter: {
    lastMedia: null,
    globalSearch: false,
    dates: [],
    instances: [],
    classifications: [],
    sort: 'desc',
    favourite: false,
    hasLabel: false,
    hourRange: {
      start: 0,
      end: 86400
    },
    viewStyle: 'grid', // or timeline
  }
};

export function reducer(state = initialState, action: media.Actions): State {
  switch (action.type) {

    case media.RESET_MEDIA: {
      return {
        ...state,
        filter: Object.assign({}, state.filter, {lastMedia: null}),
        media: []
      }
    }
    case media.SET_SCROLL_POSITION: {
      return {
        ...state,
        scrollPosition: action.payload
      }
    }
    case media.GET_ANALYSIS_SUCCESS: {
      return {
        ...state,
        currentAnalysis: action.payload,
      }
    }
    case media.FIND_MEDIA: {
      return {
        ...state,
        loading: true,
        currentMedia: null
      }
    }
    case media.FIND_MEDIA_FAILURE: {
      return {
        ...state,
        loading: false,
        currentMedia: null
      }
    }
    case media.FIND_MEDIA_SUCCESS: {
      return {
        ...state,
        loading: false,
        currentMedia: action.payload,
        media: (state.media.length == 0) ? [action.payload] : state.media.map(m => {
          const newMedia = action.payload;
          if(newMedia.id == m.id) {
            return newMedia;
          }
          return m;
        })
      }
    }
    case media.FIND_VAULT: {
      return {
        ...state,
        currentVault: null
      }
    }
    case media.FIND_VAULT_SUCCESS: {
      return {
        ...state,
        currentVault: action.payload
      }
    }
    case media.ADD_TO_VAULT_SUCCESS: {
      const sequenceId = action.payload.sequenceId;
      const mediaId = action.payload.mediaId - 1;

      // Find sequence in media array.
      let index = _.findIndex(state.media, (item) => item.id == sequenceId);
      let sequence = state['media'][index];

      return {
        ...state,
        loadingVault: false,
        error: null,
        media: [
          ...state.media.slice(0, index),
          Object.assign({}, sequence, {
            images: [
              ...sequence['images'].slice(0, mediaId),
              Object.assign({}, sequence['images'][mediaId], {
                vault: true
              }),
              ...sequence['images'].slice(mediaId + 1)
            ]
          }),
          ...state.media.slice(index + 1)
        ],
        currentMedia: Object.assign({}, sequence, {
          images: [
            ...sequence['images'].slice(0, mediaId),
            Object.assign({}, sequence['images'][mediaId], {
              vault: true
            }),
            ...sequence['images'].slice(mediaId + 1)
          ]
        })
      }
    }
    case media.ADD_TO_VAULT_FAILURE: {
      return {
        ...state,
        error: action.payload,
        loadingVault: false,
      }
    }

    case media.ADD_TO_VAULT:
    case media.REMOVE_FROM_VAULT: {
      return {
        ...state,
        loadingVault: true,
      }
    }
    case media.REMOVE_FROM_VAULT_SUCCESS: {
      const sequenceId = action.payload.sequenceId;
      const mediaId = action.payload.mediaId - 1;

      // Find sequence in media array.
      let index = _.findIndex(state.media, (item) => item.id == sequenceId);
      let sequence = state['media'][index];

      // Find item in vault
      let indexVault = _.findIndex(state.vault, (item) => {
        return item.metadata.sequence_id == sequenceId && item.metadata.media_id == mediaId + 1;
      });

      if(!sequence || !sequence['images'].length) {
        return {
          ...state,
          loadingVault: false,
          vault: [
            ...state.vault.slice(0, indexVault),
            ...state.vault.slice(indexVault + 1)
          ]
        };
      }

      return {
        ...state,
        loadingVault: false,
        vault: [
          ...state.vault.slice(0, indexVault),
          ...state.vault.slice(indexVault + 1)
        ],
        media: [
          ...state.media.slice(0, index),
          Object.assign({}, sequence, {
            images: [
              ...sequence['images'].slice(0, mediaId),
              Object.assign({}, sequence['images'][mediaId], {
                vault: false
              }),
              ...sequence['images'].slice(mediaId + 1)
            ]
          }),
          ...state.media.slice(index + 1)
        ],
        currentMedia: Object.assign({}, sequence, {
          images: [
            ...sequence['images'].slice(0, mediaId),
            Object.assign({}, sequence['images'][mediaId], {
              vault: false
            }),
            ...sequence['images'].slice(mediaId + 1)
          ]
        })
      }
    }
    case media.STAR_SEQUENCE:
    case media.STAR_SEQUENCE_SUCCESS: {
      let media = state.media;
      const sequenceId = action.payload.id;
      const star = action.payload.star;
      let index = _.findIndex(media, (item) => item.id == sequenceId);

      if(state.media[index].star == star){
        return state;
      }

      return {
        ...state,
        media: [
          ...state.media.slice(0, index),
          Object.assign({}, state.media[index], {
            star
          }),
          ...state.media.slice(index + 1)
        ],
        currentMedia: Object.assign({}, state.media[index], {
          star
        })
      }
    }
    case media.DELETE_SEQUENCE: {
      return {
        ...state,
        loadingSequence: true
      }
    }
    case media.DELETE_SEQUENCE_SUCCESS: {
      let media = state.media;
      const sequenceId = action.payload.id;
      let index = _.findIndex(state.media, (item) => item.id == sequenceId);

      return {
        ...state,
        loadingSequence: false,
        media: [
          ...state.media.slice(0, index),
          ...state.media.slice(index + 1)
        ]
      }
    }
    case media.ADD_LABEL_SUCCESS: {
      const sequenceId = action.payload.sequenceId;
      const mediaId = action.payload.mediaId - 1; // 1-based convert to 0-based.
      const label = action.payload.label;

      // Find sequence in media array.
      let index = _.findIndex(state.media, (item) => item.id == sequenceId);
      let sequence = state['media'][index];

      // Check if media item has labels, and add the label.
      let labels = []
      if(sequence['images'][mediaId].labels){
        labels = sequence['images'][mediaId]['labels'];
      }

      return {
        ...state,
        media: [
          ...state.media.slice(0, index),
          Object.assign({}, sequence, {
            images: [
              ...sequence['images'].slice(0, mediaId),
              Object.assign({}, sequence['images'][mediaId], {
                labels: [...labels, label]
              }),
              ...sequence['images'].slice(mediaId + 1)
            ]
          }),
          ...state.media.slice(index + 1)
        ],
        currentMedia: Object.assign({}, sequence, {
          images: [
            ...sequence['images'].slice(0, mediaId),
            Object.assign({}, sequence['images'][mediaId], {
              labels: [...labels, label]
            }),
            ...sequence['images'].slice(mediaId + 1)
          ]
        })
      }
    }
    case media.REMOVE_LABEL_SUCCESS: {
      const sequenceId = action.payload.sequenceId;
      const mediaId = action.payload.mediaId - 1; // 1-based convert to 0-based.
      const labelId = action.payload.labelId;

      // Find sequence in media array.
      let index = _.findIndex(state.media, (item) => item.id == sequenceId);
      let sequence = state['media'][index];

      return {
        ...state,
        media: [
          ...state.media.slice(0, index),
          Object.assign({}, sequence, {
            images: [
              ...sequence['images'].slice(0, mediaId),
              Object.assign({}, sequence['images'][mediaId], {
                labels: [
                  ...sequence['images'][mediaId]['labels'].slice(0, labelId),
                  ...sequence['images'][mediaId]['labels'].slice(labelId + 1)
                ]
              }),
              ...sequence['images'].slice(mediaId + 1)
            ]
          }),
          ...state.media.slice(index + 1)
        ],
        currentMedia: Object.assign({}, sequence, {
          images: [
            ...sequence['images'].slice(0, mediaId),
            Object.assign({}, sequence['images'][mediaId], {
              labels: [
                ...sequence['images'][mediaId]['labels'].slice(0, labelId),
                ...sequence['images'][mediaId]['labels'].slice(labelId + 1)
              ]
            }),
            ...sequence['images'].slice(mediaId + 1)
          ]
        })
      }
    }
    case media.GET_MEDIA: {
      return {
        ...state,
        loading: true
      }
    }
    case media.GET_MEDIA_FAILURE: {
      return {
        ...state,
        loading: false,
        canLoadMore: false
      }
    }
    case media.GET_MEDIA_SUCCESS: {
      let lastMedia = null;
      let canLoadMore = true;
      if(action.payload && action.payload.length) {
        const lastSequence = action.payload[action.payload.length-1];
        lastMedia = lastSequence.end;
      }
      else {
        canLoadMore = false;
      }

      return {
        ...state,
        canLoadMore,
        loading: false,
        filter: Object.assign({}, state.filter, {lastMedia}),
        media: [...state.media, ...action.payload]
      }
    }
    case media.GET_VAULT_SUCCESS: {
      const vault = action.payload;
      return {
        ...state,
        vault
      }
    }
    case media.SET_FILTER: {
      return {
        ...state,
        filter: Object.assign({}, state.filter, action.payload)
      }
    }
    case media.GET_DAYS_SUCCESS: {
      return {
        ...state,
        days: action.payload
      }
    }
    case media.GET_HOURS_SUCCESS: {
      return {
        ...state,
        hours: Object.assign({}, state.hours, ...action.payload)
      }
    }

    case media.TASK_CREATED_SUCCESS: {
      const sequenceId = action.sequenceId;
      const mediaId = action.mediaId - 1;

      if(mediaId < 0) {
        return state;
      }

      // Find sequence in media array.
      let index = _.findIndex(state.media, (item) => item.id == sequenceId);
      let sequence = state['media'][index];

      return {
        ...state,
        loadingVault: false,
        error: null,
        media: [
          ...state.media.slice(0, index),
          Object.assign({}, sequence, {
            images: [
              ...sequence['images'].slice(0, mediaId),
              Object.assign({}, sequence['images'][mediaId], {
                task_created: true
              }),
              ...sequence['images'].slice(mediaId + 1)
            ]
          }),
          ...state.media.slice(index + 1)
        ],
        currentMedia: Object.assign({}, sequence, {
          images: [
            ...sequence['images'].slice(0, mediaId),
            Object.assign({}, sequence['images'][mediaId], {
              task_created: true
            }),
            ...sequence['images'].slice(mediaId + 1)
          ]
        })
      }
    }

    case media.TASK_DELETED_SUCCESS: {
      const sequenceId = action.payload.sequenceId;
      const mediaId = action.payload.mediaId - 1;

      // Find sequence in media array.
      let index = _.findIndex(state.media, (item) => item.id == sequenceId);
      let sequence = state['media'][index];

      return {
        ...state,
        loadingVault: false,
        error: null,
        media: [
          ...state.media.slice(0, index),
          Object.assign({}, sequence, {
            images: [
              ...sequence['images'].slice(0, mediaId),
              Object.assign({}, sequence['images'][mediaId], {
                task_created: false
              }),
              ...sequence['images'].slice(mediaId + 1)
            ]
          }),
          ...state.media.slice(index + 1)
        ],
        currentMedia: Object.assign({}, sequence, {
          images: [
            ...sequence['images'].slice(0, mediaId),
            Object.assign({}, sequence['images'][mediaId], {
              task_created: false
            }),
            ...sequence['images'].slice(mediaId + 1)
          ]
        })
      }
    }

    case media.GET_EXPORT_MEDIA: {
      return {
        ...state,
        exportMedia: [],
        exportMediaCount: 0,
        loading: true
      }
    }

    case media.GET_EXPORT_MEDIA_SUCCESS: {
      const { data, count } = action.payload;
      return {
        ...state,
        loading: false,
        exportMedia: data,
        exportMediaCount: count
      }
    }

    case media.RESET: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}

export const getError = (state: State) => state.error;
export const getScrollPosition = (state: State) => state.scrollPosition;
export const getCurrentMedia = (state: State) => state.currentMedia;
export const getMedia = (state: State) => state.media;
export const getCurrentAnalysis = (state: State) => state.currentAnalysis;
export const getCurrentVault = (state: State) => state.currentVault;
export const getVault = (state: State) => state.vault;
export const getFilter = (state: State) => state.filter;
export const getDays = (state: State) => state.days;
export const getHours = (state: State) => state.hours;
export const canLoadMore = (state: State) => state.canLoadMore;
export const isLoading = (state: State) => state.loading;
export const isLoadingVault = (state: State) => state.loadingVault;
export const isLoadingSequence = (state: State) => state.loadingSequence;
export const getExportMedia = (state: State) => state.exportMedia;
export const getExportMediaCount = (state: State) => state.exportMediaCount;