import { Action } from '@ngrx/store';

export const RESET = '[Dashboard] Reset';
export const REQUEST_DASHBOARD = '[Dashboard] Requesting Dashboard Information';
export const REQUEST_DASHBOARD_SUCCESS = '[Dashboard] Requesting Dashboard Information Success';
export const REQUEST_DASHBOARD_FAILURE = '[Dashboard] Requesting Dashboard Information Failure';

export class Reset implements Action {
  readonly type = RESET;

  constructor() {}
}

export class RequestDashboard implements Action {
  readonly type = REQUEST_DASHBOARD;

  constructor(public payload?: any) {}
}

export class RequestDashboardSuccess implements Action {
  readonly type = REQUEST_DASHBOARD_SUCCESS;

  constructor(public payload: any) {}
}

export class RequestDashboardFailure implements Action {
  readonly type = REQUEST_DASHBOARD_FAILURE;

  constructor(public payload?: any) {}
}

export type Actions =
  | Reset
  | RequestDashboard
  | RequestDashboardSuccess
  | RequestDashboardFailure;
