import {Component, OnInit, OnDestroy, Input, OnChanges} from '@angular/core';
import {DomSanitizer} from "@angular/platform-browser";

@Component({
  selector: "SvgIcon",
  templateUrl: './svg-icon.component.html',
  styleUrls: ["./svg-icon.component.scss"],
})
export class SvgIcon implements OnInit, OnDestroy, OnChanges  {

  @Input() icon: string;
  @Input() color: string;
  public svg: any = {}

  constructor(private sanitizer: DomSanitizer) {
  }

  ngOnInit(){

    this.svg = this.sanitizer.bypassSecurityTrustHtml(window["env"]["svg"][this.icon]);
  }

  ngAfterViewInit(){
  }

  ngOnChanges(changes) {

  }

  dashboard(){

  }

  ngOnDestroy(){
  }
}
