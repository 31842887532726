import * as tasks from './actions';
import * as _ from "lodash";

export interface State {
  tasks: Array<any>;
  tasksFiltered: Array<any>;
  task: any;
  error: boolean;
  errormessage: string;
  success: boolean;
  successmessage: string;
  statistics: any;
}

export const initialState: State = {
  tasks: [],
  tasksFiltered: [],
  task: null,
  error: false,
  errormessage: "",
  success: false,
  successmessage: "",
  statistics: null,
};

export function reducer(state = initialState, action: tasks.Actions): State {

  switch (action.type) {

    case tasks.REQUEST_TASKS_SUCCESS: {
      const payload = action.payload;
      return {
        ...state,
        tasks: payload ? payload : []
      }
    }

    case tasks.REQUEST_FILTERED_TASKS_SUCCESS: {
      const payload = action.payload;
      return {
        ...state,
        tasksFiltered: payload ? payload : []
      }
    }

    case tasks.GET_TASKS_STATISTICS_SUCCESS: {
      const payload = action.payload;
      return {
        ...state,
        statistics: payload
      }
    }

    case tasks.ADD_TASK: {
      const payload = action.payload;
      return {
        ...state,
        errormessage: "",
        error: false,
        successmessage: "",
        success: false,
      }
    }

    case tasks.ADD_TASK_SUCCESS: {
      const payload = action.payload;
      return {
        ...state,
        tasks: payload.tasks,
        errormessage: "",
        error: false,
        successmessage: payload.message,
        success: true,
      }
    }

    case tasks.ADD_TASK_FAILURE: {
      const payload = action.payload;
      return {
        ...state,
        errormessage: payload.message,
        error: true,
        successmessage: "",
        success: false,
      }
    }

    case tasks.DELETE_TASK_SUCCESS: {
      const task  = action.payload;
      let index = _.findIndex(state.tasks, (item) => item.id == task.id);
      let indexFiltered = _.findIndex(state.tasksFiltered, (item) => item.id == task.id);
      return {
        ...state,
        tasks: [
          ...state.tasks.slice(0, index),
          ...state.tasks.slice(index + 1)
        ],
        tasksFiltered: [
          ...state.tasksFiltered.slice(0, index),
          ...state.tasksFiltered.slice(index + 1)
        ]
      }
    }

    case tasks.EDIT_TASK_SUCCESS: {
      const { task }  = action.payload;
      let index = _.findIndex(state.tasks, (item) => item.id == task.id);
      let indexFiltered = _.findIndex(state.tasksFiltered, (item) => item.id == task.id);
      return {
        ...state,
        tasks: [
          ...state.tasks.slice(0, index),
          Object.assign({}, state.tasks[index], {
            status: task.status
          }),
          ...state.tasks.slice(index + 1)
        ],
        tasksFiltered: [
          ...state.tasksFiltered.slice(0, indexFiltered),
          Object.assign({}, state.tasksFiltered[indexFiltered], {
            status: task.status
          }),
          ...state.tasksFiltered.slice(indexFiltered + 1)
        ],  
      }
    }

    case tasks.RESET: {
      return initialState;
    }

    case tasks.RESET_MESSAGE: {
      return {
        ...state,
        errormessage: "",
        error: false,
        successmessage: "",
        success: false,
      }
    }

    default: {
      return state;
    }
  }
}

export const getState = (state: State) => state;
export const getTasks = (state: State) => state.tasks;
export const getTasksFiltered = (state: State) => state.tasksFiltered;
export const getTasksSuccess = (state: State) => state.success;
export const getTasksStatistics = (state: State) => state.statistics;
