
import {map, take} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { ApiService } from '../../shared/api.service';

@Injectable()
export class ProfileService {

  constructor(private http: HttpClient,
              private apiService: ApiService) {}

  public getProfile() : Observable<any> {
    return this.http.get(this.apiService.getProfile()).pipe(take(1),map(value => {
      return value;
    }),);
  }

  public updateProfile(profile) : Observable<any> {
    return this.http.put(this.apiService.updateProfile(), profile).pipe(take(1),map(value => {
      return value;
    }),);
  }

  public addCustomer(customer) : Observable<any> {
    return this.http.post(this.apiService.addCustomer(), customer).pipe(take(1),map(value => {
      return value;
    }),);
  }

  public updateSettings(settings) : Observable<any> {
    return this.http.put(this.apiService.updateSettings(), settings).pipe(take(1),map(value => {
      return value;
    }),);
  }

  public updateCredentials(credentials) : Observable<any> {
    return this.http.put(this.apiService.updateCredentials(), credentials).pipe(take(1),map(value => {
      return value;
    }),);
  }

  public getInvoices() : Observable<any> {
    return this.http.get(this.apiService.getInvoices()).pipe(take(1),map(value => {
      return value;
    }),);
  }

  public updateCard(token) : Observable<any> {
    return this.http.put(this.apiService.updateCard(), { token }).pipe(take(1),map(value => {
      return value;
    }),);
  }

  public deleteCard() : Observable<any> {
    return this.http.delete(this.apiService.deleteCard()).pipe(take(1),map(value => {
      return value;
    }),);
  }

  public getQRCode() : Observable<any> {
    return this.http.get(this.apiService.getQRCode()).pipe(take(1),map(value => {
      return value;
    }),);
  }

  public validateQRCode(secret) : Observable<any> {
    return this.http.post(this.apiService.validateQRCode(), {secret}).pipe(take(1),map(value => {
      return value;
    }),);
  }

  public setEncryption(encryption) : Observable<any> {
    return this.http.post(this.apiService.setEncryption(), {...encryption}).pipe(take(1),map(value => {
      return value;
    }),);
  }

  public disableMFA() : Observable<any> {
    return this.http.post(this.apiService.disableMFA(), {}).pipe(take(1),map(value => {
      return value;
    }),);
  }

}
