
import {of as observableOf,  Observable } from 'rxjs';
import {map, switchMap, catchError} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as Action from '../store/actions';
import { LivestreamService } from '../livestream.service';

@Injectable()
export class Effects {

    /* Retrieve livestream properties */

    @Effect()
    RequestLivestreamProperties: Observable<Action.RequestLivestreamFailure | Action.RequestLivestreamSuccess> = this.actions$.pipe(
      ofType(Action.REQUEST_LIVESTREAM),
      switchMap((action: Action.RequestLivestream) => {
        return this.livestreamService.getLivestream().pipe(map((livestream) => {
          return new Action.RequestLivestreamSuccess(livestream.data);
        }),
        catchError(e => {
          return observableOf(new Action.RequestLivestreamFailure(e));
        }),);
      })
    );

    /* Toggle speech */

    @Effect()
    ToggleSpeech: Observable<Action.ToggleSpeechFailure | Action.ToggleSpeechSuccess> = this.actions$.pipe(
      ofType(Action.TOGGLE_SPEECH),
      switchMap((action: Action.ToggleSpeech) => {
        return this.livestreamService.updateSpeech(action.payload).pipe(map((livestream) => {
          const speech = livestream.data.speech;
          return new Action.ToggleSpeechSuccess(speech);
        }),
        catchError(e => {
          return observableOf(new Action.ToggleSpeechFailure(e));
        }),);
      })
    );

    constructor(private livestreamService: LivestreamService,
                private actions$: Actions) {}
}
