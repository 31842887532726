import { createSelector, createFeatureSelector } from '@ngrx/store';
import * as fromRoot from '../../../app.reducer';
import * as fromCounting from './counting';

export * from './effects';

export interface CountingState {
  counting: fromCounting.State;
}

export interface State extends fromRoot.State {
  counting: CountingState;
}
export const Reducers = {
  counting: fromCounting.reducer
};

export const selectCountingState = createFeatureSelector<CountingState>('counting');
export const selectCountingCountingState = createSelector(selectCountingState, (state: CountingState) => state.counting);
export const getLivestream = createSelector(selectCountingCountingState, fromCounting.getLivestream);
export const getRegions = createSelector(selectCountingCountingState, fromCounting.getRegions);
export const getAnalytics = createSelector(selectCountingCountingState, fromCounting.getAnalytics);
export const getFilter = createSelector(selectCountingCountingState, fromCounting.getFilter);
