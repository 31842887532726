import * as dashboard from './actions';

export interface State {
  detections: any;
}

export const initialState: State = {
  detections: null,
};

export function reducer(state = initialState, action: dashboard.Actions): State {
  switch (action.type) {
    case dashboard.REQUEST_DASHBOARD_SUCCESS: {
      const payload = action.payload;
      return {
        ...state,
        detections: payload,
      }
    }
    default: {
      return state;
    }
  }
}

export const getDashboard = (state: State) => state.detections;
