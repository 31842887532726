import { map, take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { ApiService } from '../../shared/api.service';

@Injectable()
export class CountingService {

  constructor(private http: HttpClient,
              private apiService: ApiService) {}

  public getRegions() : Observable<any> {
    return this.http.get(this.apiService.getRegions()).pipe(take(1),map(value => {
      return value;
    }),);
  }

  public addRegion(data) : Observable<any> {
    return this.http.post(this.apiService.addRegion(), {data}).pipe(take(1),map(value => {
      return value;
    }),);
  }

  public updateRegion(data) : Observable<any> {
    return this.http.put(this.apiService.updateRegion(), {data}).pipe(take(1),map(value => {
      return value;
    }),);
  }

  public deleteRegion(data) : Observable<any> {
    return this.http.delete(this.apiService.deleteRegion(data.device, data.id)).pipe(take(1),map(value => {
      return value;
    }),);
  }

  public getCountingAnalytics(filter) : Observable<any> {
    return this.http.post(this.apiService.getCountingAnalytics(), filter).pipe(take(1),map(value => {
      return value;
    }),);
  }
}
