import * as accounts from './actions';

export interface State {
  accounts: Array<any>;
  account: any;
  error: any;
}

export const initialState: State = {
  accounts: [],
  account: null,
  error: null
};

export function reducer(state = initialState, action: accounts.Actions): State {
  switch (action.type) {

    case accounts.REQUEST_ACCOUNTS_SUCCESS: {
      const payload = action.payload;
      return {
        ...state,
        accounts: payload ? payload : []
      }
    }

    case accounts.ADD_ACCOUNT_SUCCESS: {
      const payload = action.payload;
      return {
        ...state,
        accounts: payload,
        error: null
      }
    }

    case accounts.ADD_ACCOUNT_FAILURE: {
      const payload = action.payload;
      return {
        ...state,
        error: payload
      }
    }

    case accounts.EDIT_ACCOUNT_SUCCESS: {
      const payload = action.payload;
      return {
        ...state,
        accounts: payload
      }
    }

    case accounts.EDIT_ACCOUNT_FAILURE: {
      const payload = action.payload;
      return {
        ...state,
        error: payload
      }
    }

    case accounts.DELETE_ACCOUNT_SUCCESS: {
      const payload = action.payload;
      return {
        ...state,
        accounts: payload
      }
    }

    case accounts.RESET: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}

export const getAccounts = (state: State) => state.accounts;
export const getAccount = (state: State) => state.account;
export const getError = (state: State) => state.error;
