import {Component, Input} from '@angular/core';
import {FormGroup} from "@angular/forms";

@Component({
  selector: "Tags",
  templateUrl: './tags.component.html',
  styleUrls: ['./tags.component.scss'],
})
export class Tags  {

  @Input() label: any;
  @Input() placeholder: any;
  @Input() onChange: any;
  public currentValue: string = "";
  public tags: string[] = [];
  public error: boolean = false;

  constructor(){
    this.enter = this.enter.bind(this);
    this.delete = this.delete.bind(this);
    this.validateEmail = this.validateEmail.bind(this);
  }

  validateEmail(email) {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  enter(event: any){
    if(this.validateEmail(this.currentValue)) {
      this.tags.push(this.currentValue);
      this.currentValue = "";
      this.onChange(this.tags);
      this.error = false;
    } else {
      this.error = true;
    }
  }

  delete(index: any){
    this.tags = [
      ...this.tags.slice(0, index),
      ...this.tags.slice(index + 1),
    ]
    this.onChange(this.tags);
  }

  cleanup(){
    this.tags = [];
    this.currentValue = "";
  }
}
