import {Component, OnInit, OnDestroy, Input, OnChanges} from '@angular/core';

@Component({
  selector: "InlineButtonField",
  templateUrl: './inlinebutton.component.html',
  styleUrls: ['./inlinebutton.component.scss'],
})
export class InlineButtonField implements OnInit, OnDestroy  {

  @Input("icon") icon: any;
  @Input("callback") callback: any;
  @Input("title") title: any;

  constructor() {}

  ngOnInit() {
  }

  ngAfterViewInit(){
  }

  ngOnDestroy(){
  }

  executeCallback(){
    this.callback();
  }
}
