import {Component, OnInit, OnDestroy, Input} from '@angular/core';

@Component({
  selector: "Status",
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.scss'],
})
export class Status implements OnInit, OnDestroy  {

  @Input() color: string;
  @Input() title: string;

  constructor() {}

  ngOnInit(){
  }

  ngAfterViewInit(){
  }

  ngOnDestroy(){
  }
}
