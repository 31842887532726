import {of as observableOf,  Observable } from 'rxjs';
import {catchError, switchMap, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as Action from '../store/actions';
import { AccountsService } from '../accounts.service';

@Injectable()
export class Effects {

    /* Retrieve all sites */

    @Effect()
    requestAccounts: Observable<Action.RequestAccountsFailure | Action.RequestAccountsSuccess> = this.actions$.pipe(
        ofType(Action.REQUEST_ACCOUNTS),
        switchMap((action: Action.RequestAccounts) => {
          return this.accountsService.getAccounts().pipe(map((accounts) => {
            return new Action.RequestAccountsSuccess(accounts);
          }),
          catchError(e => {
            return observableOf(new Action.RequestAccountsFailure(e));
          }),);
        }));

    /* Add account */

    @Effect()
    addAccount: Observable<Action.AddAccountFailure | Action.AddAccountSuccess> = this.actions$.pipe(
        ofType(Action.ADD_ACCOUNT),
        switchMap((action: Action.AddAccount) => {
          return this.accountsService.addAccount(action.payload).pipe(map((accounts) => {
            return new Action.AddAccountSuccess(accounts);
          }),
          catchError(e => {
            return observableOf(new Action.AddAccountFailure(e.error.message));
          }),);
        }));

    /* Edit account */

    @Effect()
    editAccount: Observable<Action.EditAccountFailure | Action.EditAccountSuccess> = this.actions$.pipe(
        ofType(Action.EDIT_ACCOUNT),
        switchMap((action: Action.EditAccount) => {
          return this.accountsService.editAccount(action.id, action.payload).pipe(map((accounts) => {
            return new Action.EditAccountSuccess(accounts);
          }),
          catchError(e => {
            return observableOf(new Action.EditAccountFailure(e.error.message));
          }),);
        }));

    /* Delete account */

    @Effect()
    deleteAccount: Observable<Action.DeleteAccountFailure | Action.DeleteAccountSuccess> = this.actions$.pipe(
        ofType(Action.DELETE_ACCOUNT),
        switchMap((action: Action.DeleteAccount) => {
          return this.accountsService.deleteAccount(action.id).pipe(map((accounts) => {
            return new Action.DeleteAccountSuccess(accounts);
          }),
          catchError(e => {
            return observableOf(new Action.DeleteAccountFailure(e));
          }),);
        }));

    constructor(private accountsService: AccountsService,
                private actions$: Actions) {}
}
