import {Component, OnInit, OnDestroy, Input} from '@angular/core';

@Component({
  selector: "GroupBadge",
  templateUrl: './groupbadge.component.html',
  styleUrls: ['./groupbadge.component.scss'],
})
export class GroupBadge implements OnInit, OnDestroy  {

  @Input("color") color: string;
  @Input("initials") initials: string;

  constructor() {}

  ngOnInit(){

  }

  ngAfterViewInit(){
  }


  ngOnDestroy(){
  }

}
