import { Action } from '@ngrx/store';

export const RESET = '[Subscription] Reset ';
export const PURCHASE_PLAN = '[Subscription] Purchase plan';
export const PURCHASE_PLAN_SUCCESS = '[Subscription] Purchase plan Success';
export const PURCHASE_PLAN_FAILURE = '[Subscription] Purchase plan Failure';
export const DELETE_PLAN = '[Subscription] Delete plan';
export const DELETE_PLAN_SUCCESS = '[Subscription] Delete plan Success';
export const DELETE_PLAN_FAILURE = '[Subscription] Delete plan Failure';
export const CANCEL_PLAN = '[Subscription] Cancel plan';
export const CANCEL_PLAN_SUCCESS = '[Subscription] Cancel plan Success';
export const CANCEL_PLAN_FAILURE = '[Subscription] Cancel plan Failure';
export const RESUME_PLAN = '[Subscription] Resume plan';
export const RESUME_PLAN_SUCCESS = '[Subscription] Resume plan Success';
export const RESUME_PLAN_FAILURE = '[Subscription] Resume plan Failure';
export const REQUEST_PLAN = '[Subscription] Request plan';
export const REQUEST_PLAN_SUCCESS = '[Subscription] Request plan Success';
export const REQUEST_PLAN_FAILURE = '[Subscription] Request plan Failure';
export const REQUEST_ACTIVITY = '[Subscription] Request activity';
export const REQUEST_ACTIVITY_SUCCESS = '[Subscription] Request activity Success';
export const REQUEST_ACTIVITY_FAILURE = '[Subscription] Request activity Failure';
export const REQUEST_SETTINGS = '[Subscription] Request settings';
export const REQUEST_SETTINGS_SUCCESS = '[Subscription] Request settings Success';
export const REQUEST_SETTINGS_FAILURE = '[Subscription] Request settings Failure';
export const CHANGE_REGION = '[Subscription] Change region';
export const CHANGE_REGION_SUCCESS = '[Subscription] Change region Success';
export const CHANGE_REGION_FAILURE = '[Subscription] Change region Failure';
export const REQUEST_STORAGE = '[Subscription] Request storage';
export const REQUEST_STORAGE_SUCCESS = '[Subscription] Request storage Success';
export const REQUEST_STORAGE_FAILURE = '[Subscription] Request storage Failure';
export const UPDATE_STORAGE = '[Subscription] Update storage';
export const UPDATE_STORAGE_SUCCESS = '[Subscription] Update storage Success';
export const UPDATE_STORAGE_FAILURE = '[Subscription] Update storage Failure';
export const VALIDATE_COUPON = '[Subscription] Validate Coupon';
export const VALIDATE_COUPON_SUCCESS = '[Subscription] Validate Coupon Success';
export const VALIDATE_COUPON_FAILURE = '[Subscription] Validate Coupone Failure';

export class Reset implements Action {
  readonly type = RESET;

  constructor() {}
}

export class PurchasePlan implements Action {
  readonly type = PURCHASE_PLAN;

  constructor(public payload: any, public coupon: string) {}
}

export class PurchasePlanSuccess implements Action {
  readonly type = PURCHASE_PLAN_SUCCESS;

  constructor(public payload: any) {}
}

export class PurchasePlanFailure implements Action {
  readonly type = PURCHASE_PLAN_FAILURE;

  constructor(public payload?: any) {}
}

export class DeletePlan implements Action {
  readonly type = DELETE_PLAN;

  constructor(public payload: any) {}
}

export class DeletePlanSuccess implements Action {
  readonly type = DELETE_PLAN_SUCCESS;

  constructor(public payload: any) {}
}

export class DeletePlanFailure implements Action {
  readonly type = DELETE_PLAN_FAILURE;

  constructor(public payload?: any) {}
}

export class CancelPlan implements Action {
  readonly type = CANCEL_PLAN;

  constructor(public payload: any) {}
}

export class CancelPlanSuccess implements Action {
  readonly type = CANCEL_PLAN_SUCCESS;

  constructor(public payload: any) {}
}

export class CancelPlanFailure implements Action {
  readonly type = CANCEL_PLAN_FAILURE;

  constructor(public payload?: any) {}
}

export class ResumePlan implements Action {
  readonly type = RESUME_PLAN;

  constructor() {}
}

export class ResumePlanSuccess implements Action {
  readonly type = RESUME_PLAN_SUCCESS;

  constructor(public payload: any) {}
}

export class ResumePlanFailure implements Action {
  readonly type = RESUME_PLAN_FAILURE;

  constructor(public payload?: any) {}
}

export class RequestPlan implements Action {
  readonly type = REQUEST_PLAN;

  constructor() {}
}

export class RequestPlanSuccess implements Action {
  readonly type = REQUEST_PLAN_SUCCESS;

  constructor(public payload: any) {}
}

export class RequestPlanFailure implements Action {
  readonly type = REQUEST_PLAN_FAILURE;

  constructor(public payload?: any) {}
}

export class RequestActivity implements Action {
  readonly type = REQUEST_ACTIVITY;

  constructor() {}
}

export class RequestActivitySuccess implements Action {
  readonly type = REQUEST_ACTIVITY_SUCCESS;

  constructor(public payload: any) {}
}

export class RequestActivityFailure implements Action {
  readonly type = REQUEST_ACTIVITY_FAILURE;

  constructor(public payload?: any) {}
}

export class RequestSettings implements Action {
  readonly type = REQUEST_SETTINGS;

  constructor() {}
}

export class RequestSettingsSuccess implements Action {
  readonly type = REQUEST_SETTINGS_SUCCESS;

  constructor(public payload: any) {}
}

export class RequestSettingsFailure implements Action {
  readonly type = REQUEST_SETTINGS_FAILURE;

  constructor(public payload?: any) {}
}

export class ChangeRegion implements Action {
  readonly type = CHANGE_REGION;

  constructor(public payload: any) {}
}

export class ChangeRegionSuccess implements Action {
  readonly type = CHANGE_REGION_SUCCESS;

  constructor(public payload: any) {}
}

export class ChangeRegionFailure implements Action {
  readonly type = CHANGE_REGION_FAILURE;

  constructor(public payload?: any) {}
}

export class RequestStorage implements Action {
  readonly type = REQUEST_STORAGE;

  constructor() {}
}

export class RequestStorageSuccess implements Action {
  readonly type = REQUEST_STORAGE_SUCCESS;

  constructor(public payload: any) {}
}

export class RequestStorageFailure implements Action {
  readonly type = REQUEST_STORAGE_FAILURE;

  constructor(public payload?: any) {}
}

export class UpdateStorage implements Action {
  readonly type = UPDATE_STORAGE;

  constructor(public payload: any) {}
}

export class UpdateStorageSuccess implements Action {
  readonly type = UPDATE_STORAGE_SUCCESS;

  constructor(public payload: any) {}
}

export class UpdateStorageFailure implements Action {
  readonly type = UPDATE_STORAGE_FAILURE;

  constructor(public payload?: any) {}
}

export class ValidateCoupon implements Action {
  readonly type = VALIDATE_COUPON;

  constructor(public payload: any) {}
}

export class ValidateCouponSuccess implements Action {
  readonly type = VALIDATE_COUPON_SUCCESS;

  constructor(public payload: any) {}
}

export class ValidateCouponFailure implements Action {
  readonly type = VALIDATE_COUPON_FAILURE;

  constructor(public payload?: any) {}
}

export type Actions =
  | Reset
  | PurchasePlan
  | PurchasePlanSuccess
  | PurchasePlanFailure
  | DeletePlan
  | DeletePlanSuccess
  | DeletePlanFailure
  | CancelPlan
  | CancelPlanSuccess
  | CancelPlanFailure
  | ResumePlan
  | ResumePlanSuccess
  | ResumePlanFailure
  | RequestPlan
  | RequestPlanSuccess
  | RequestPlanFailure
  | RequestActivity
  | RequestActivitySuccess
  | RequestActivityFailure
  | RequestSettings
  | RequestSettingsSuccess
  | RequestSettingsFailure
  | ChangeRegion
  | ChangeRegionSuccess
  | ChangeRegionFailure
  | RequestStorage
  | RequestStorageSuccess
  | RequestStorageFailure
  | UpdateStorage
  | UpdateStorageSuccess
  | UpdateStorageFailure
  | ValidateCoupon
  | ValidateCouponSuccess
  | ValidateCouponFailure;
