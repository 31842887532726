import * as auth from './actions';

export interface State {
  error: string | null;
  pending: boolean;
  mfaRequired: boolean;
  forceMFA: boolean;
  mfa: {
    qrcode: string;
    user: string;
    secret: string;
    success: boolean;
    failed: boolean;
  }
}

export const initialState: State = {
  error: null,
  pending: false,
  mfaRequired: false,
  forceMFA: false,
  mfa: {
    qrcode: "",
    user: "",
    secret: "",
    success: false,
    failed: false
  }
};

export function reducer(state = initialState, action: auth.Actions): State {
  switch (action.type) {
    case auth.LOGIN: {
      return {
        ...state,
        error: null,
        pending: true,
      };
    }

    case auth.LOGIN_SUCCESS: {
      return {
        ...state,
        error: null,
        pending: false,
        mfaRequired: false,
        forceMFA: false,
      };
    }

    case auth.LOGIN_FAILURE: {
      return {
        ...state,
        error: action.payload,
        pending: false,
      };
    }

    case auth.REQUIRE_MFA: {
      return {
        ...state,
        mfaRequired: true
      };
    }

    case auth.FORCE_MFA: {
      const {qrcode, user, secret} = action.payload;
      return {
        ...state,
        forceMFA: true,
        mfa: {
          qrcode: qrcode,
          user: user,
          secret: secret,
          success: false,
          failed: false
        }
      };
    }


    case auth.VALIDATE_MFA: {
      return {
        ...state,
        mfa: {
          ...state.mfa,
          success: false,
          failed: false
        }
      };
    }

    case auth.VALIDATE_MFA_SUCCESS: {
      return {
        ...state,
        mfa: {
          ...state.mfa,
          success: true,
          failed: false
        }
      };
    }

    case auth.VALIDATE_MFA_FAILURE: {
      return {
        ...state,
        mfa: {
          ...state.mfa,
          failed: true
        }
      };
    }

    case auth.RESET_MFA: {
      return {
        ...state,
        mfaRequired: false,
        forceMFA: false,
        mfa: {
          qrcode: "",
          user: "",
          secret: "",
          success: false,
          failed: false
        }
      };
    }

    default: {
      return state;
    }
  }
}

export const getError = (state: State) => state.error;
export const getPending = (state: State) => state.pending;
export const getMFARequired = (state: State) => state.mfaRequired;
export const getForceMFA = (state: State) => state.forceMFA;
export const getMFARequest = (state: State) => state.mfa;
