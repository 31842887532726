import { createSelector, createFeatureSelector } from '@ngrx/store';
import * as fromRoot from '../../../app.reducer';
import * as fromDevice from './device';

export * from './effects';

export interface DeviceState {
  cameras: fromDevice.State;
}

export interface State extends fromRoot.State {
  devices: DeviceState;
}
export const Reducers = {
  cameras: fromDevice.reducer
};

export const selectDeviceState = createFeatureSelector<DeviceState>('devices');
export const selectDeviceCameraState = createSelector(selectDeviceState, (state: DeviceState) => state.cameras);
export const getDevices = createSelector(selectDeviceCameraState, fromDevice.getDevices);
export const getDevicesMedia = createSelector(selectDeviceCameraState, fromDevice.getDevicesMedia);
export const getCurrentDevice = createSelector(selectDeviceCameraState, fromDevice.getCurrentDevice);
export const getDeviceUpdated = createSelector(selectDeviceCameraState, fromDevice.getDeviceUpdated);
export const getDeviceUpdating = createSelector(selectDeviceCameraState, fromDevice.getDeviceUpdating);
export const getCameras = createSelector(selectDeviceCameraState, fromDevice.getCameras);
export const getAnalytics = createSelector(selectDeviceCameraState, fromDevice.getAnalytics);
export const getLatestVersion = createSelector(selectDeviceCameraState, fromDevice.getLatestVersion);
export const getLatestMedia = createSelector(selectDeviceCameraState, fromDevice.getLatestMedia);
export const getStatistics = createSelector(selectDeviceCameraState, fromDevice.getStatistics);
export const getStatus = createSelector(selectDeviceCameraState, fromDevice.getStatus);
export const getConfig = createSelector(selectDeviceCameraState, fromDevice.getConfig);
export const getConfigUpdating = createSelector(selectDeviceCameraState, fromDevice.getConfigUpdating);
export const getConfigUpdated = createSelector(selectDeviceCameraState, fromDevice.getConfigUpdated);
export const getEvents = createSelector(selectDeviceCameraState, fromDevice.getEvents);
export const getMotion = createSelector(selectDeviceCameraState, fromDevice.getMotion);
export const getAnalyticsFromCamera = key => {
  return createSelector(getAnalytics, (state: fromDevice.State) => fromDevice.getAnalyticsFromCamera(state, key));
}
