import * as roles from './actions';

export interface State {
  roles: Array<any>;
  role: any;
  error: any;
}

export const initialState: State = {
  roles: [],
  role: null,
  error: null
};

export function reducer(state = initialState, action: roles.Actions): State {
  switch (action.type) {

    case roles.REQUEST_ROLES_SUCCESS: {
      const payload = action.payload;
      return {
        ...state,
        roles: payload ? payload : []
      }
    }

    case roles.ADD_ROLE_SUCCESS: {
      const payload = action.payload;
      return {
        ...state,
        roles: payload,
        error: null
      }
    }

    case roles.ADD_ROLE_FAILURE: {
      const payload = action.payload;
      return {
        ...state,
        error: payload
      }
    }

    case roles.EDIT_ROLE_SUCCESS: {
      const payload = action.payload;
      return {
        ...state,
        roles: payload
      }
    }

    case roles.EDIT_ROLE_FAILURE: {
      const payload = action.payload;
      return {
        ...state,
        error: payload
      }
    }

    case roles.DELETE_ROLE_SUCCESS: {
      const payload = action.payload;
      return {
        ...state,
        roles: payload
      }
    }

    case roles.RESET: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}

export const getRoles = (state: State) => state.roles;
export const getRole = (state: State) => state.role;
export const getError = (state: State) => state.error;
