import { createSelector, createFeatureSelector } from '@ngrx/store';
import * as fromRoot from '../../../app.reducer';
import * as fromSites from './sites';
export * from './effects';

export interface SitesState {
  sites: fromSites.State;
}

export interface State extends fromRoot.State {
  sites: SitesState;
}
export const Reducers = {
  sites: fromSites.reducer
};

export const selectSitesState = createFeatureSelector<SitesState>('sites');
export const selectSitesSitesState = createSelector(selectSitesState, (state: SitesState) => state.sites);
export const getSitesState = createSelector(selectSitesSitesState, fromSites.getSitesState);
export const getSites = createSelector(selectSitesSitesState, fromSites.getSites);
export const getSite = createSelector(selectSitesSitesState, fromSites.getSite);
export const getSiteDevices = createSelector(selectSitesSitesState, fromSites.getSiteDevices);
