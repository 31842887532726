import {map, take} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '../../shared/api.service';

@Injectable()
export class DashboardService {

  constructor(private http: HttpClient,
              private apiService: ApiService) {}

  public getDashboard() : Observable<any> {
    return this.http.get(this.apiService.getDashboard()).pipe(take(1),map(value => {
      return value;
    }),);
  }
}
