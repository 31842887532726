import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'Oval',
  templateUrl: './oval.component.html',
  styleUrls: ['./oval.component.scss'],

})
export class OvalComponent {
  @Input('movement') movement: Observable<any>;
  constructor() {}
}
