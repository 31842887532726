import { Component, OnInit, Input, ViewEncapsulation, ViewChild } from '@angular/core';
import { Md5 } from 'ts-md5/dist/md5';
import {combineLatest as observableCombineLatest} from "rxjs";
import { Store } from '@ngrx/store';
import * as fromAuth from '../../../login/store';
import * as Auth from '../../../login/store/actions';
import * as fromDevices from '../../devices/store';
import * as Devices from '../../devices/store/actions';
import * as fromMedia from '../../media/store';
import * as Media from '../../media/store/actions';
import * as Profile from '../../profile/store/actions';
import * as Sites from '../../sites/store/actions';
import * as fromSites from '../../sites/store';
import * as Groups from '../../groups/store/actions';
import * as fromGroups from '../../groups/store';
import * as Subscription from '../../subscription/store/actions';
import * as fromSubscription from '../../subscription/store';
import * as Home from '../../store/actions';
import * as SubscriptionInfo from '../../../shared/subscription.info';
import * as fromTasks from "../../tasks/store";
import * as Tasks from "../../tasks/store/actions";
import { kerberosConfig } from '../../../../environments/environment';
import * as WatchList from '../../watchlist/store/actions';
import * as fromWatchList from '../../watchlist/store';
import { Submenu } from './blocks/submenu/submenu.component';
import { NavigationEnd, Router } from '@angular/router';
declare var Pusher: any;

@Component({
  selector: 'navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
  encapsulation: ViewEncapsulation.None // @TODO: fix layout and remove View Encapsulation
})
export class NavigationComponent implements OnInit {

  @Input() public closeMenu: any;
  @ViewChild('mediaSubmenu', { static: false }) mediaSubmenu: Submenu;
  @ViewChild('daySubmenu', { static: false }) daySubmenu: Submenu;

  public config: any = {};
  public isPrivate: boolean = false;

  public user$ = this.store.select(fromAuth.getUser);
  public user: any;
  public pagePermissions: string[] = [];
  public gravatarSize: any;
  public gravatar: any;
  public usage: any;
  public usagePercent: any;
  public maxUsage: any = 3000;

  public cameras: Array<any> = [];
  public cameras$ = this.store.select(fromDevices.getCameras);
  public cameraSubscription: any;

  public sites$ = this.store.select(fromSites.getSites);
  public sitesSubscription: any;
  public sites = [];

  public groups$ = this.store.select(fromGroups.getGroups);
  public groupsSubscription: any;
  public groups = [];

  public tasks$ = this.store.select(fromTasks.getTasks);
  public tasksSubscription;
  public tasks = [];

  public watchlist$ = this.store.select(fromWatchList.getStatistics);
  public watchlistSubscription;
  public watchlistStatistics = {};

  public cameraAnalytics: any;
  public cameraAnalytics$ = this.store.select(fromDevices.getAnalytics);
  public cameraAnalyticsSubscription: any;

  public subscriptionSubscription;
  public subscription$ = this.store.select(fromSubscription.getSubscription);
  public subscription;

  public days: any;
  public daysSubscription: any;
  public days$ = this.store.select(fromMedia.getDays);
  public activeCameras: number = 0;
  public totalCameras: number = 0;
  public activeGroups: number = 0;
  public totalGroups: number = 0;

  public subscriptionStatus: string = "no-plan"; // no-plan, active, cancelled, reached-limit
  public levels = SubscriptionInfo.Levels;
  public routerSubscription: any;

  constructor(private store: Store<fromAuth.State>, private router: Router) {}

  ngOnInit() {

    this.config = kerberosConfig;
    this.isPrivate = kerberosConfig.privateEdition;

    this.routerSubscription = this.router.events.subscribe((route: NavigationEnd) => {
      if(route instanceof NavigationEnd && route.url.indexOf("/media") === -1 && this.mediaSubmenu) {
        if(this.mediaSubmenu) {
          this.mediaSubmenu.collapse();
        }
      }
      if(route instanceof NavigationEnd && route.url.indexOf("/day") === -1 && this.mediaSubmenu) {
        if(this.daySubmenu) {
          this.daySubmenu.collapse();
        }
      }
   });

    this.store.dispatch(new Sites.RequestSites());
    this.sitesSubscription = this.sites$.subscribe(sites => {
      this.sites = sites;
    });

    this.store.dispatch(new Groups.RequestGroups());
    this.groupsSubscription = this.groups$.subscribe(groups => {
      this.groups = groups;
    });

    this.store.dispatch(new Tasks.RequestTasks());
    this.tasksSubscription = this.tasks$.subscribe(tasks => {
      this.tasks = tasks;
    })

    this.store.dispatch(new WatchList.GetWatchlistsStatistics());
    this.watchlistSubscription = this.watchlist$.subscribe(watchlistStatistics => {
      this.watchlistStatistics = watchlistStatistics;
    })

    this.daysSubscription = this.days$.subscribe(days => {
      this.days = days;
    });

    this.cameraSubscription = this.cameras$.subscribe(cameras => {
        this.cameras = cameras;
    });

    this.cameraAnalyticsSubscription = this.cameraAnalytics$.subscribe(analytics => {
        this.cameraAnalytics = analytics;
        let activeCameras = 0;
        for (const analytic of Object.keys(this.cameraAnalytics)) {
          const item = this.cameraAnalytics[analytic];
          const mostRecent = item[item.length-1];
          const lastUpdated = mostRecent.timestamp;
          const secondsAgo = Math.floor(Date.now() / 1000) - lastUpdated;
          const active = (secondsAgo < 180);
          activeCameras = (active) ? activeCameras + 1 : activeCameras;
          this.totalCameras += 1;
        }
        this.activeCameras = activeCameras;
    });

    const subscriptionUserArray = [this.user$,  this.subscription$];
    this.subscriptionSubscription = observableCombineLatest(subscriptionUserArray).subscribe(results => {
      const user = results[0];
      if(user) {
        this.user = user;
        this.pagePermissions = this.user.permissions.pages;
        // Get rid of the domain name in the username.
        if(this.user.domain !== ""){
          this.user.username = this.user.username.replace(this.user.domain + "@", "")
        }
        this.user.emailShort = this.user.email.split('@')[0];
        this.usage = 0;
        this.usagePercent = 0;
        this.maxUsage = 1000;
        this.gravatarSize = 200;
        this.gravatar = 'https://www.gravatar.com/avatar/' + Md5.hashStr(this.user.email) + '?s=' + this.gravatarSize + '&d=mm';
      }

      const subscription = results[1];
      if(subscription) {
        this.subscription = subscription;
        const planSettings = subscription.settings;

        // Calculate Subscription status
        if(subscription.plan == null) {
          this.subscriptionStatus = "no-plan";
        } else if(subscription.plan && subscription.plan.ends_at == null && subscription.reachedLimit == false) {
          this.subscriptionStatus = "active";
        } else if (subscription.plan && subscription.plan.ends_at != null) {
          this.subscriptionStatus = "cancelled";
        } else if (subscription.plan && subscription.plan.ends_at == null  && subscription.reachedLimit == true) {
          this.subscriptionStatus = "reached-limit";
        }

        if(this.subscription && planSettings) {
          // If a valid plan, we'll search the appropriate settings.
          if(this.subscription.plan) {
            const settings = planSettings[this.subscription.plan.stripe_plan];
            if(this.user.custom_usage_limit > 0) {
              this.maxUsage = this.user.custom_usage_limit / 1000;
            } else if (settings) {
              this.maxUsage = settings.usage / 1000;
            }
          }
        }

        if(this.subscription.activity) {
          const activity = this.subscription.activity;
          if(activity && activity.length > 0) {
            const lastActivity = activity[activity.length - 1];
            this.usage = (lastActivity.usage / (1000 * 1000 * 1000)).toFixed(2);
            this.usagePercent = Math.floor(this.usage / this.maxUsage * 100);
          }
        }
      }
    });
  }

  ngOnDestroy() {
    this.cameraSubscription.unsubscribe();
    this.cameraAnalyticsSubscription.unsubscribe();
    this.subscriptionSubscription.unsubscribe();
    this.sitesSubscription.unsubscribe();
    this.tasksSubscription.unsubscribe();
    this.groupsSubscription.unsubscribe();
    this.routerSubscription.unsubscribe();
  }

  public logout() {
    this.store.dispatch(new Auth.Logout());
    this.store.dispatch(new Devices.Reset());
    this.store.dispatch(new Media.Reset());
    this.store.dispatch(new Home.Reset());
    this.store.dispatch(new Profile.Reset());
    this.store.dispatch(new Subscription.Reset());
    this.store.dispatch(new Sites.Reset());
  }
}
