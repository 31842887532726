import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, } from '@angular/core';

@Component({
  selector: "ItemPicker",
  templateUrl: './itempicker.component.html',
  styleUrls: ['./itempicker.component.scss'],
})
export class ItemPicker implements OnInit, OnDestroy, OnChanges  {

  @Input() icon: string;
  @Input() text: string;
  @Input() all: boolean;
  @Input() items: any; // [{text: ..., value: ...}, ...s]
  @Input() selected: any[] = [];
  @Input() locked: any[] = [];
  @Input() disabled: boolean = false;
  @Input() placeholder: string = "Search..";
  @Input() onChange: any;
  public open: boolean = false;
  public selectedAll: boolean = false;
  public search: string = "";
  public filteredItems: any[] = [];
  public selectedItems: string[] = [];
  public selectedValues: string[] = [];
  public lockedItems: any[] = [];

  constructor() {
    this.changeSelect = this.changeSelect.bind(this);
    this.toggleDropdown = this.toggleDropdown.bind(this);
  }

  ngOnInit(){
  }

  ngAfterViewInit(){
  }

  ngOnDestroy(){
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.items){
      this.filteredItems = this.filterByName(changes.items.currentValue, this.search);
    }
    if(changes.selected) {
      if(changes.selected.currentValue) {
        this.selectedItems = changes.selected.currentValue;
      } else {
        this.selectedItems = [];
      }
    }

    if(changes.all) {
      this.selectedAll = changes.all.currentValue;
    }

    if(changes.locked) {
      if(changes.locked.currentValue) {
        this.lockedItems = changes.locked.currentValue;
      } else {
        this.lockedItems = [];
      }
    }
  }

  filterByName(items, search){
    if(search == "") {
      return items;
    }
    return items.filter(i => i.text.includes(search));
  }

  changeSearch(event) {
    const { value } = event.target;
    this.search = value;
    this.filteredItems = this.filterByName(this.items, this.search);
  }

  isSelected(value){
    return this.selectedItems.filter(s => s === value).length > 0;
  }

  isLocked(value){
    return this.lockedItems.filter(s => s.value === value).length > 0;
  }

  isLockedName(value){
    const item = this.lockedItems.find(s => s.value === value);
    if(item){
      return item.text;
    }
    return "";
  }

  changeSelect(value, event) {
    const { checked } = event.target;
    if (checked) {
      // add it
      this.selectedItems.push(value);
      // make unique
      this.selectedItems = this.selectedItems.filter((item, i, ar) => ar.indexOf(item) === i);
    } else {
      //remove it
      this.removeOccurrences(this.selectedItems, value);
    }
    // Reiterate over selectedValues.
    this.selectedValues = this.selectedItems.map(i => {
      const item = this.items.find(i2 => i2.value === i)
      if(item){
        return item.text;
      }
    })
    this.onChange(this.selectedItems);
  }

  toggleDropdown(){
    this.open = !this.open;
  }

  identify(index, item){
    return item.value;
  }

  removeOccurrences(array, item) {
    var i = array.length;
    while (i--) {
      if (array[i] === item) {
        array.splice(array.indexOf(item), 1);
      }
    }
  }
}
