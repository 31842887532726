import {Component, OnInit, OnDestroy, Input} from '@angular/core';

@Component({
  selector: "SiteBadge",
  templateUrl: './sitebadge.component.html',
  styleUrls: ['./sitebadge.component.scss'],
})
export class SiteBadge implements OnInit, OnDestroy  {

  @Input() color: string;
  @Input() initials: string;
  @Input() transparent: boolean = true;

  constructor() {}

  ngOnInit(){

  }

  ngAfterViewInit(){
  }


  ngOnDestroy(){
  }

}
