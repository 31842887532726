import {of as observableOf,  Observable } from 'rxjs';
import {switchMap, catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as Action from '../store/actions';
import { HomeService } from '../home.service';

@Injectable()
export class Effects {

    /* Retrieve all notifications from current user */

    @Effect()
    requestNotifications: Observable<Action.RequestNotificationsFailure | Action.RequestNotificationsSuccess> = this.actions$.pipe(
        ofType(Action.REQUEST_NOTIFICATIONS),
        switchMap((action: Action.RequestNotifications) => {
          return this.homeService.getNotifications().pipe(map((devices) => {
            return new Action.RequestNotificationsSuccess(devices);
          }),
          catchError(e => {
            return observableOf(new Action.RequestNotificationsFailure(e));
          }),);
        }));


    /* Retrieve all notifications from current user */

    @Effect()
    sendFeedback: Observable<Action.SendFeedbackFailure | Action.SendFeedbackSuccess> = this.actions$.pipe(
      ofType(Action.SEND_FEEDBACK),
      switchMap((action: Action.SendFeedback) => {
        return this.homeService.sendFeedback(action.payload).pipe(map((feedback) => {
          //mixpanel.track("sendFeedback", {feedback: action.payload});
          return new Action.SendFeedbackSuccess(feedback);
        }),
        catchError(e => {
          //mixpanel.track("sendFeedbackFailed", {feedback: action.payload, error: e});
          return observableOf(new Action.SendFeedbackFailure(e));
        })
      );
    }));

    /* Mark a notification as read */

    @Effect()
    markNotification: Observable<Action.MarkReadNotificationFailure | Action.MarkReadNotificationSuccess> = this.actions$.pipe(
      ofType(Action.MARK_READ_NOTIFICATION),
      switchMap((action: Action.MarkReadNotification) => {
        return this.homeService.markReadNotification(action.payload).pipe(map((notification) => {
          //mixpanel.track("markNotification", {notification: action.payload});
          return new Action.MarkReadNotificationSuccess(notification);
        }),
        catchError(e => {
          //mixpanel.track("markNotificationFailed", {notification: action.payload, error: e});
          return observableOf(new Action.MarkReadNotificationFailure(e));
        }),);
      }));

    /* Mark a notification as read */

    @Effect()
    markReadAll: Observable<Action.MarkReadAllFailure | Action.MarkReadAllSuccess> = this.actions$.pipe(
      ofType(Action.MARK_READ_ALL),
      switchMap((action: Action.MarkReadAll) => {
        return this.homeService.markReadAllNotification().pipe(map((notification) => {
          //mixpanel.track("markNotificationAll");
          return new Action.MarkReadAllSuccess(notification);
        }),
        catchError(e => {
          //mixpanel.track("markNotificationAllFailed", {error: e});
          return observableOf(new Action.MarkReadAllFailure(e));
        }),);
      }));

    /* Get all releases */

    @Effect()
    requestReleaseNotes: Observable<Action.RequestReleaseNotesFailure | Action.RequestReleaseNotesSuccess> = this.actions$.pipe(
      ofType(Action.REQUEST_RELEASE_NOTES),
      switchMap((action: Action.RequestReleaseNotes) => {
        return this.homeService.getReleases().pipe(map((releases) => {
          //mixpanel.track("requestReleaseNotes");
          return new Action.RequestReleaseNotesSuccess(releases);
        }),
        catchError(e => {
          //mixpanel.track("requestReleaseNotesFailed", {error: e});
          return observableOf(new Action.RequestReleaseNotesFailure(e));
        }),);
      }));

    constructor(private homeService: HomeService,
                private actions$: Actions,) {}
}
