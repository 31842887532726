import { Inject, Injectable, OnDestroy } from '@angular/core';
import { MqttService as NgxMqtt } from 'ngx-mqtt';
import { Store } from '@ngrx/store';
import * as fromProfile from '../home/profile/store';
import { v4 as uuidv4 } from "uuid";
import JSEncrypt from 'jsencrypt';  // RSA encryption, asymmetric
import * as CryptoJS from 'crypto-js';  // AES encryption, symmetric

@Injectable()
export class MqttService implements OnDestroy {

  public profile$ = this.store.select(fromProfile.getProfile);
  public profileSubscription;
  public profile;

  constructor(@Inject('mqttNew') private mqttService: NgxMqtt,
              private store: Store<fromProfile.State>) {
    this.profileSubscription = this.profile$.subscribe(profile => {
      this.profile = profile;
    });
  }

  public subscribe(topic, callback) {
    // We might need to decrypt using a key before it's able to be read.
    return this.mqttService.observe(topic).subscribe((message) => {
      let msg = JSON.parse(message.payload.toString());
      if(msg.hidden && this.profile.user){
        const { amazon_secret_access_key } = this.profile.user;
        if (amazon_secret_access_key !== "") {
          const { hidden_value } = msg.payload;
          if (hidden_value && hidden_value !== "") {
            const decrypted = CryptoJS.AES.decrypt(hidden_value, amazon_secret_access_key).toString(CryptoJS.enc.Utf8);
            msg.payload = JSON.parse(decrypted);
          }
        }
      }


      if(msg.encrypted){
        let public_key = "";
        let symmetric_key = ""
        let fingerprint = "";
        const jsencrypt = new JSEncrypt();

        if(this.profile && this.profile.encryption && this.profile.encryption.enabled){
          fingerprint = this.profile.encryption.fingerprint;
          if(this.profile.encryption.has_passphrase){
            public_key = this.profile.public_key ? this.profile.public_key : "";
            symmetric_key = this.profile.symmetric_key ? this.profile.symmetric_key : "";
          } else {
            public_key = this.profile.encryption.public_key;
            symmetric_key = this.profile.encryption.symmetric_key;
          }
          jsencrypt.setPublicKey(public_key);
        }

        if(!public_key || !symmetric_key || public_key == "" || symmetric_key == ""){
          return;
        } else {
          // Encrypted value
          const { encrypted_value, signature } = msg.payload;
          // Validate the signature.. tidi
          const verify = jsencrypt.verify(encrypted_value, signature, CryptoJS.SHA256);
          if(verify){
            // Decrypt the key with AES
            const decrypt = CryptoJS.AES.decrypt(encrypted_value, symmetric_key).toString(CryptoJS.enc.Utf8);
            // Convert to json
            msg.payload = JSON.parse(decrypt);
          } else {
            console.log("Signature not valid");
            return;
          }
        }
      }

      callback(msg)
    });
  }

  public publish(topic, payload, hidden = false, p = null) {

    if(p) {
      this.profile = p;
    }

    // We might need to encrypt using a key before it's send over..
    let public_key = "";
    let symmetric_key = "";
    let fingerprint = "";
    const jsencrypt = new JSEncrypt();

    if(this.profile && this.profile.encryption){
      fingerprint = this.profile.encryption.fingerprint;
      if(this.profile.public_key !== ""){
        public_key = this.profile.public_key;
        symmetric_key = this.profile.symmetric_key;
      } else {
        public_key = this.profile.encryption.public_key;
        symmetric_key = this.profile.encryption.symmetric_key;
      }
      jsencrypt.setPublicKey(public_key);
    }

    // We'll generate a hash of the payload to use as a fingerprint.
    const payload_hash = CryptoJS.SHA256(JSON.stringify(payload)).toString(CryptoJS.enc.Hex);

    // We'll add some metadata first.
    const message = {
      mid: uuidv4(),
      timestamp: Math.floor(Date.now() / 1000),
      hidden: false,
      encrypted: public_key !== "",
      fingerprint,
      device_id: payload.device_id,
      payload,
      payload_hash,
    }

    // We might need to encrypt using a key before it's send over..
    if(message.encrypted){
      // Set time in payload as well, so we can verify afterwards!
      message.timestamp = Math.floor(Date.now() / 1000);

      // Generate a random key for AES encryption
      const key = symmetric_key;

      // Encrypt the key with RSA
      const encrypted_key = jsencrypt.encrypt(key.toString());

      // Encrypt payload
      const encrypted = CryptoJS.AES.encrypt(
        JSON.stringify(message.payload),
        key.toString()).toString();

      // Set the new value
      message.payload = {};
      message.payload.encrypted_value = encrypted_key + ":::" + encrypted;
    }

    // If we need to hide the value, we'll encrypt it with the hub private key.
    if(hidden && this.profile.user){
      const { amazon_secret_access_key } = this.profile.user;
      if (amazon_secret_access_key !== "") {
        const encrypted = CryptoJS.AES.encrypt(
          JSON.stringify(message.payload),
          amazon_secret_access_key).toString();

        message.payload = {};
        message.hidden = true;
        message.payload.hidden_value = encrypted;
      }
    }

    this.mqttService.unsafePublish(topic, JSON.stringify(message), { qos: 1, retain: false });
  }

  ngOnDestroy(){
    this.profileSubscription.unsubscribe();
  }
}
