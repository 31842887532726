import { createSelector, createFeatureSelector } from '@ngrx/store';
import * as fromRoot from '../../../app.reducer';
import * as fromChannels from './channels';

export * from './effects';

export interface ChannelsState {
  channels: fromChannels.State;
}

export interface State extends fromRoot.State {
  channels: ChannelsState;
}
export const Reducers = {
  channels: fromChannels.reducer
};

export const selectChannelsState = createFeatureSelector<ChannelsState>('channels');
export const selectChannelsChannelsState = createSelector(selectChannelsState, (state: ChannelsState) => state.channels);
export const getChannels = createSelector(selectChannelsChannelsState, fromChannels.getChannels);
export const getBrowserChannel = createSelector(selectChannelsChannelsState, fromChannels.getBrowserChannel);
export const getEmailChannel = createSelector(selectChannelsChannelsState, fromChannels.getEmailChannel);
export const getSlackChannel = createSelector(selectChannelsChannelsState, fromChannels.getSlackChannel);
export const getAlexaChannel = createSelector(selectChannelsChannelsState, fromChannels.getAlexaChannel);
export const getTelegramChannel = createSelector(selectChannelsChannelsState, fromChannels.getTelegramChannel);
export const getPushbulletChannel = createSelector(selectChannelsChannelsState, fromChannels.getPushbulletChannel);
export const getWebhookChannel = createSelector(selectChannelsChannelsState, fromChannels.getWebhookChannel);
export const getIftttChannel = createSelector(selectChannelsChannelsState, fromChannels.getIftttChannel);
export const getSmsChannel = createSelector(selectChannelsChannelsState, fromChannels.getSmsChannel);
export const getPushoverChannel = createSelector(selectChannelsChannelsState, fromChannels.getPushoverChannel);
export const getChannelsUpdating = createSelector(selectChannelsChannelsState, fromChannels.getChannelsUpdating);
export const getChannelTesting = createSelector(selectChannelsChannelsState, fromChannels.getChannelTesting);
