import {of as observableOf,  Observable } from 'rxjs';
import {catchError, switchMap, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as Action from '../store/actions';
import {DashboardService} from "../dashboard.service";

@Injectable()
export class Effects {

    /* Retrieve all dashboard figures from current user */

    @Effect()
    requestDashboard: Observable<Action.RequestDashboardFailure | Action.RequestDashboardSuccess> = this.actions$.pipe(
      ofType(Action.REQUEST_DASHBOARD),
      switchMap((action: Action.RequestDashboard) => {
        return this.dashboardService.getDashboard().pipe(map((dashboard) => {
          return new Action.RequestDashboardSuccess(dashboard);
        }),
        catchError(e => {
          return observableOf(new Action.RequestDashboardFailure(e));
        }),);
      })
    );

    constructor(private dashboardService: DashboardService,
                private actions$: Actions) {}
}