import {map, take} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { ApiService } from '../shared/api.service';

@Injectable()
export class WallService {

  constructor(private http: HttpClient,
              private apiService: ApiService) {}
  public decryptVideowall(payload: string) : Observable<any> {
    return this.http.post(this.apiService.decryptVideowall(), payload).pipe(take(1),map(domains => {
        return domains;
    }),);
  }
}
