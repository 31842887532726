
import {map, take} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { ApiService } from '../../shared/api.service';

@Injectable()
export class AlertsService {

  constructor(private http: HttpClient,
              private apiService: ApiService) {}

  public getNotificationSettings() : Observable<any> {
    return this.http.get(this.apiService.getNotificationSettings()).pipe(take(1),map(value => {
      return value;
    }),);
  }

  public updateNotificationSettings(notificationSettings: any) : Observable<any> {
    return this.http.put(this.apiService.updateNotificationSettings(), {notificationSettings}).pipe(take(1),map(value => {
      return value;
    }),);
  }

  public getCustomAlerts() : Observable<any> {
    return this.http.get(this.apiService.getCustomAlerts()).pipe(take(1),map(value => {
      return value;
    }),);
  }

  public createCustomAlert(customAlert: any) : Observable<any> {
    return this.http.post(this.apiService.createCustomAlert(), customAlert).pipe(take(1),map(value => {
      return value;
    }),);
  }

  public updateCustomAlert(customAlert: any, alertId: string) : Observable<any> {
    return this.http.put(this.apiService.updateCustomAlert(alertId), customAlert).pipe(take(1),map(value => {
      return value;
    }),);
  }

  public deleteCustomAlert(alertId: string) : Observable<any> {
    return this.http.delete(this.apiService.deleteCustomAlert(alertId)).pipe(take(1),map(value => {
      return value;
    }),);
  }
  public toggleCustomAlert(enabled: any, alertId: string) : Observable<any> {
    return this.http.put(this.apiService.updateCustomAlert(alertId), {enabled}).pipe(take(1),map(value => {
      return value;
    }),);
  }
}
