import { createSelector, createFeatureSelector } from '@ngrx/store';
import * as fromRoot from '../../../app.reducer';
import * as fromDashboard from './dashboard';
export * from './effects';

export interface DashboardState {
  dashboard: fromDashboard.State;
}

export interface State extends fromRoot.State {
  dashboard: DashboardState;
}
export const Reducers = {
  dashboard: fromDashboard.reducer
};

export const selectDashboardState = createFeatureSelector<DashboardState>('dashboard');
export const selectDashboardDashboardState = createSelector(selectDashboardState, (state: DashboardState) => state.dashboard);
export const getDashboard = createSelector(selectDashboardDashboardState, fromDashboard.getDashboard);
