import { Action } from '@ngrx/store';

export const RESET = '[Recordings] Reset';
export const REQUEST_RECORDINGS = '[Recordings] Requesting recordings';
export const REQUEST_RECORDINGS_SUCCESS = '[Recordings] Requesting recordings Success';
export const REQUEST_RECORDINGS_FAILURE = '[Recordings] Requesting recordings Failure';

export class Reset implements Action {
  readonly type = RESET;

  constructor() {}
}

export class RequestRecordings implements Action {
  readonly type = REQUEST_RECORDINGS;

  constructor() {}
}

export class RequestRecordingsSuccess implements Action {
  readonly type = REQUEST_RECORDINGS_SUCCESS;

  constructor(public payload: any) {}
}

export class RequestRecordingsFailure implements Action {
  readonly type = REQUEST_RECORDINGS_FAILURE;

  constructor(public payload?: any) {}
}

export type Actions =
  | Reset
  | RequestRecordings
  | RequestRecordingsSuccess
  | RequestRecordingsFailure;
