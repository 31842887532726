import {Component, OnInit, OnDestroy, Input} from '@angular/core';
import * as SubscriptionInfo from "../../subscription.info";

@Component({
  selector: "DatasetList",
  templateUrl: './dataset-list.component.html',
  styleUrls: ['./dataset-list.component.scss'],
})
export class DatasetList implements OnInit, OnDestroy  {

  @Input() devices: any;
  @Input() datasets: any;
  @Input() cloudKey: any;
  @Input() plan: any;
  @Input() user: any;
  @Input() removeDevice: any;
  @Input() hideSites: boolean = false;
  @Input() layout: string = 'list';
  public levels = SubscriptionInfo.Levels;
  public showStream: boolean = false;

  constructor() {}

  ngOnInit(){
  }

  ngAfterViewInit(){
  }

  ngOnDestroy(){
  }
}
