import * as counting from './actions';

export interface State {
  speech: boolean;
  regions: any;
  analytics: any;
  filter: any;
}

export const initialState: State = {
  speech: true,
  regions: [],
  analytics: null,
  filter: {
    dates: [],
    instances: [],
    classifications: [],
    mode: "all",
  }
};

export function reducer(state = initialState, action: counting.Actions): State {
  switch (action.type) {

    case counting.TOGGLE_SPEECH_SUCCESS: {
      const speech = action.payload;
      return {
        ...state,
        speech
      };
    }

    case counting.REQUEST_LIVESTREAM_SUCCESS: {
      const properties = action.payload;
      return {
        ...state,
        ...properties
      };
    }

    case counting.GET_REGIONS_SUCCESS: {
      const regions = action.payload;
      return {
        ...state,
        regions
      };
    }

    case counting.ADD_REGION_SUCCESS: {
      const data = action.payload;
      return {
        ...state,
        regions: state.regions.map((r, i) => r.key === data.device ? {
          ...r,
          regions: [
            ...r.regions,
            {
              id: data.id,
              name: data.name,
              direction: data.direction,
              level: data.level,
              coordinates: data.region.points,
            }
          ]
        } : r)
      };
    }

    case counting.UPDATE_REGION_SUCCESS: {
      const data = action.payload;
      return {
        ...state,
        regions: state.regions.map((r, i) => r.key === data.device ? {
          ...r,
          regions: r.regions.map((r2, i) => r2.id === data.id ? {
            id: data.id,
            name: data.name,
            direction: data.direction,
            level: data.level,
            coordinates: data.region.points,
          } : r2),
        } : r)
      };
    }

    case counting.DELETE_REGION_SUCCESS: {
      const data = action.payload;
      let deviceIndex = state.regions.findIndex(r => r.key === data.device);
      let regionIndex = state.regions[deviceIndex].regions.findIndex(r => r.id === data.id);
      return {
        ...state,
        regions: state.regions.map((r, i) => r.key === data.device ? {
          ...r,
          regions: [
            ...r.regions.slice(0, regionIndex),
            ...r.regions.slice(regionIndex + 1),
          ]
        } : r)
      };
    }

    case counting.GET_ANALYTICS_SUCCESS: {
      const analytics = action.payload;
      return {
        ...state,
        analytics
      };
    }

    case counting.SET_FILTER: {
      return {
        ...state,
        filter: Object.assign({}, state.filter, action.payload)
      }
    }

    case counting.RESET: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}

export const getLivestream = (state: State) => state;
export const getRegions = (state: State) => state.regions;
export const getAnalytics = (state: State) => state.analytics;
export const getFilter = (state: State) => state.filter;
