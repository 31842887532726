import {Component, OnInit, OnDestroy, Input} from '@angular/core';

@Component({
  selector: "BlockStatus",
  templateUrl: './block-status.component.html',
  styleUrls: ['./block-status.component.scss'],
})
export class BlockStatus implements OnInit, OnDestroy  {

  @Input() type: string;
  @Input() text: string;
  @Input() link: string;
  @Input() onClose: any;
  public classes: any;

  ngOnInit(){
    this.classes = {
      success: this.type === 'success',
      info: this.type === 'info',
      error: this.type === 'error',
      loading: this.type === 'loading',
      pointer: this.link && this.link.length
    }
  }

  ngAfterViewInit(){
  }


  ngOnDestroy(){
  }
}
