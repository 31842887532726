import {of as observableOf,  Observable } from 'rxjs';
import {map, catchError, switchMap} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as Action from '../store/actions';
import { DevicesService } from '../devices.service';

@Injectable()
export class Effects {

    /* Retrieve latest version of machinery */

    @Effect()
    requestLatestVersion: Observable<Action.RequestLatestVersionFailure | Action.RequestLatestVersionSuccess> = this.actions$.pipe(
      ofType(Action.REQUEST_LATEST_VERSION),
      switchMap((action: Action.RequestLatestVersion) => {
        return this.devicesService.getLatestVersion().pipe(map((version) => {
          return new Action.RequestLatestVersionSuccess(version);
        }),
        catchError(e => {
          return observableOf(new Action.RequestLatestVersionFailure(e));
        }),);
      })
    );

    /* Retrieve all devices from current user */

    @Effect()
    requestDevices: Observable<Action.RequestDevicesFailure | Action.RequestDevicesSuccess> = this.actions$.pipe(
      ofType(Action.REQUEST_DEVICES),
      switchMap((action: Action.RequestDevices) => {
        return this.devicesService.getDevices().pipe(map((devices) => {
          return new Action.RequestDevicesSuccess(devices);
        }),
        catchError(e => {
          return observableOf(new Action.RequestDevicesFailure(e));
        }),);
      })
    );

    /* Retrieve all latest media devices from current user */
    
    @Effect()
    requestDevicesMedia: Observable<Action.RequestMediaDevicesFailure | Action.RequestMediaDevicesSuccess> = this.actions$.pipe(
      ofType(Action.REQUEST_MEDIA_DEVICES),
      switchMap((action: Action.RequestMediaDevices) => {
        return this.devicesService.getMediaDevices().pipe(map((devices) => {
          return new Action.RequestMediaDevicesSuccess(devices);
        }),
        catchError(e => {
          return observableOf(new Action.RequestMediaDevicesFailure(e));
        }),);
      })
    );

    /* Get some statistics of all devices from current user */

    @Effect()
    getDeviceStatistics: Observable<Action.GetDevicesStatisticsFailure | Action.GetDevicesStatisticsSuccess> = this.actions$.pipe(
      ofType(Action.GET_DEVICES_STATISTICS),
      switchMap((action: Action.GetDevicesStatistics) => {
        return this.devicesService.getDeviceStatistics().pipe(map((count) => {
            return new Action.GetDevicesStatisticsSuccess(count.data);
          }),
          catchError(e => {
            return observableOf(new Action.GetDevicesStatisticsFailure(e));
          }),);
      })
    );

    /* Remove device */

    @Effect()
    removeDevice: Observable<Action.RemoveDeviceFailure | Action.RemoveDeviceSuccess> = this.actions$.pipe(
      ofType(Action.REMOVE_DEVICE),
      switchMap((action: Action.RemoveDevice) => {
        return this.devicesService.removeDevice(action.payload).pipe(map((device) => {
          //mixpanel.track("removeDevice", {device: action.payload});
          return new Action.RemoveDeviceSuccess(device);
        }),
        catchError(e => {
          //mixpanel.track("removeDeviceFailed", {device: action.payload, error: e});
          return observableOf(new Action.RemoveDeviceFailure(e));
        }),);
      })
    );

    /* Mute device */

    @Effect()
    muteDevice: Observable<Action.MuteDeviceFailure | Action.MuteDeviceSuccess> = this.actions$.pipe(
      ofType(Action.MUTE_DEVICE),
      switchMap((action: Action.MuteDevice) => {
        const { device, mute }= action.payload;
        return this.devicesService.muteDevice(device, mute).pipe(map((device) => {
          //mixpanel.track("muteDevice", {payload: action.payload});
          return new Action.MuteDeviceSuccess(device);
        }),
        catchError(e => {
          //mixpanel.track("muteDeviceFailed", {payload: action.payload, error: e});
          return observableOf(new Action.MuteDeviceFailure(e));
        }),);
      })
    );

    /* Update device */

    @Effect()
    updateDevice: Observable<Action.UpdateDeviceFailure | Action.UpdateDeviceSuccess> = this.actions$.pipe(
      ofType(Action.UPDATE_DEVICE),
      switchMap((action: Action.UpdateDevice) => {
        const { device, payload }= action.payload;
        return this.devicesService.updateDevice(device, payload).pipe(map((data) => {
          //mixpanel.track("updateDevice", {payload: action.payload});
          return new Action.UpdateDeviceSuccess(data);
        }),
        catchError(e => {
          //mixpanel.track("updateDeviceFailed", {payload: action.payload, error: e});
          return observableOf(new Action.UpdateDeviceFailure(e));
        }),);
      })
    );
        

    /* Find a device */

    @Effect()
    findDevice: Observable<Action.FindDeviceFailure | Action.FindDeviceSuccess> = this.actions$.pipe(
      ofType(Action.FIND_DEVICE),
      switchMap((action: Action.FindDevice) => {
        return this.devicesService.findDevice(action.payload).pipe(map((device) => {
          //mixpanel.track("findDevice", {payload: action.payload});
          return new Action.FindDeviceSuccess(device);
        }),
        catchError(e => {
          //mixpanel.track("findDeviceFailed", {payload: action.payload, error: e});
          return observableOf(new Action.FindDeviceFailure(e));
        }),);
      })
    );
  

    /* Add a preset */

    @Effect()
    addPreset: Observable<Action.AddPresetFailure | Action.AddPresetSuccess> = this.actions$.pipe(
      ofType(Action.ADD_PRESET),
      switchMap((action: Action.AddPreset) => {
        const { device, name, x, y, z }= action.payload;
        return this.devicesService.addPreset(device, {name, x, y, z}).pipe(map((data) => {
          //mixpanel.track("addPreset", {payload: action.payload});
          return new Action.AddPresetSuccess(data);
        }),
        catchError(e => {
          //mixpanel.track("addPresetFailed", {payload: action.payload, error: e});
          return observableOf(new Action.AddPresetFailure(e));
        }),);
      })
    );

    /* Remove a preset */
    
    @Effect()
    removePreset: Observable<Action.RemovePresetFailure | Action.RemovePresetSuccess> = this.actions$.pipe(
      ofType(Action.REMOVE_PRESET),
      switchMap((action: Action.RemovePreset) => {
        const { device, preset }= action.payload;
        return this.devicesService.removePreset(device, preset).pipe(map((data) => {
          //mixpanel.track("removePreset", {payload: action.payload});
          return new Action.RemovePresetSuccess(data);
        }),
        catchError(e => {
          //mixpanel.track("removePresetFailed", {payload: action.payload, error: e});
          return observableOf(new Action.RemovePresetFailure(e));
        }),);
      })
    );
    
    constructor(private devicesService: DevicesService,
                private actions$: Actions) {}
}
