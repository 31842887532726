import { createSelector, createFeatureSelector } from '@ngrx/store';
import * as fromRoot from '../../../app.reducer';
import * as fromDevice from './device';

export * from './effects';

export interface DeviceState {
  cameras: fromDevice.State;
}

export interface State extends fromRoot.State {
  devices: DeviceState;
}
export const Reducers = {
  cameras: fromDevice.reducer
};

export const selectDeviceState = createFeatureSelector<DeviceState>('devices');
export const selectDeviceCameraState = createSelector(selectDeviceState, (state: DeviceState) => state.cameras);
export const getDevices = createSelector(selectDeviceCameraState, fromDevice.getDevices);
export const getCurrentDevice = createSelector(selectDeviceCameraState, fromDevice.getCurrentDevice);
export const getCameras = createSelector(selectDeviceCameraState, fromDevice.getCameras);
export const getAnalytics = createSelector(selectDeviceCameraState, fromDevice.getAnalytics);
export const getLatestVersion = createSelector(selectDeviceCameraState, fromDevice.getLatestVersion);
export const getLatestMedia = createSelector(selectDeviceCameraState, fromDevice.getLatestMedia);
export const getAnalyticsFromCamera = key => {
  return createSelector(getAnalytics, (state: fromDevice.State) => fromDevice.getAnalyticsFromCamera(state, key));
}
