import { Injectable } from '@angular/core';
import { kerberosConfig } from '../../environments/environment';
import {ToastrService} from "ngx-toastr";
import {Router} from "@angular/router";

@Injectable()
export class ToastService {

  constructor(private toastr: ToastrService,
              private router: Router) {}

  public show(title: string, message: string, duration: number, action: any) {
    let toast = this.toastr.show(title, message, {
      extendedTimeOut: duration,
      timeOut: duration,
      positionClass: 'toast-bottom-right'
    });
    toast.onAction.subscribe((event) => {
      if(action) {
        this.router.navigate(action);
      }
    });
  }

  public success(title: string, message: string, duration: number, action: any) {
    let toast = this.toastr.success(title, message, {
      extendedTimeOut: duration,
      timeOut: duration,
      positionClass: 'toast-bottom-right'
    });
    toast.onAction.subscribe((event) => {
      if(action) {
        this.router.navigate(action);
      }
    });
  }

  public error(title: string, message: string, duration: number, action: any) {
    let toast = this.toastr.error(title, message, {
      extendedTimeOut: duration,
      timeOut: duration,
      positionClass: 'toast-bottom-right'
    });
    toast.onAction.subscribe((event) => {
      if(action) {
        this.router.navigate(action);
      }
    });
  }
}
