import {Component, OnInit, OnDestroy, Input, OnChanges} from '@angular/core';

@Component({
  selector: "ModalHeader",
  templateUrl: './modalheader.component.html',
  styleUrls: ['./modalheader.component.scss'],
})
export class ModalHeader implements OnInit, OnDestroy, OnChanges  {

  @Input("close") close: any;

  constructor() {}

  ngOnInit(){
  }

  onClose(){
    this.close();
  }

  ngAfterViewInit(){
  }

  ngOnChanges(changes) {

  }

  ngOnDestroy(){
  }
}
