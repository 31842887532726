import { Action } from '@ngrx/store';

export const ADD_NOTIFICATION = '[Home] Add a Notification';
export const MARK_READ_ALL = '[Home] Mark Read All';
export const MARK_READ_ALL_FAILURE = '[Home] Mark Read All Failure';
export const MARK_READ_ALL_SUCCESS = '[Home] Mark Read All Success';
export const MARK_READ_NOTIFICATION = '[Home] Mark Notification As Read';
export const MARK_READ_NOTIFICATION_FAILURE = '[Home] Mark Notification As Read Failure';
export const MARK_READ_NOTIFICATION_SUCCESS = '[Home] Mark Notification As Read Success';
export const REQUEST_NOTIFICATIONS = '[Home] Requesting Notifications';
export const REQUEST_NOTIFICATIONS_SUCCESS = '[Home] Requesting Notifications Success';
export const REQUEST_NOTIFICATIONS_FAILURE = '[Home] Requesting Notifications Failure';
export const REQUEST_RELEASE_NOTES = '[Home] Requesting Release notes';
export const REQUEST_RELEASE_NOTES_SUCCESS = '[Home] Requesting Release notes Success';
export const REQUEST_RELEASE_NOTES_FAILURE = '[Home] Requesting Release notes Failure';
export const SEND_FEEDBACK = '[Home] Send feedback';
export const SEND_FEEDBACK_SUCCESS = '[Home] Send feedback Success';
export const SEND_FEEDBACK_FAILURE = '[Home] Send feedback Failure';
export const RESET = '[Home] Reset Notifications';

export const ADD_MESSAGE = '[MQTT] Add message';

export class AddNotification implements Action {
  readonly type = ADD_NOTIFICATION;

  constructor(public payload: any) {}
}

export class MarkReadAll implements Action {
  readonly type = MARK_READ_ALL;

  constructor() {}
}

export class MarkReadAllFailure implements Action {
  readonly type = MARK_READ_ALL_FAILURE;

  constructor(public payload: any) {}
}

export class MarkReadAllSuccess implements Action {
  readonly type = MARK_READ_ALL_SUCCESS;

  constructor(public payload: any) {}
}

export class MarkReadNotification implements Action {
  readonly type = MARK_READ_NOTIFICATION;

  constructor(public payload: any) {}
}

export class MarkReadNotificationFailure implements Action {
  readonly type = MARK_READ_NOTIFICATION_FAILURE;

  constructor(public payload: any) {}
}

export class MarkReadNotificationSuccess implements Action {
  readonly type = MARK_READ_NOTIFICATION_SUCCESS;

  constructor(public payload: any) {}
}

export class RequestNotifications implements Action {
  readonly type = REQUEST_NOTIFICATIONS;

  constructor() {}
}

export class RequestNotificationsSuccess implements Action {
  readonly type = REQUEST_NOTIFICATIONS_SUCCESS;

  constructor(public payload: any) {}
}

export class RequestNotificationsFailure implements Action {
  readonly type = REQUEST_NOTIFICATIONS_FAILURE;

  constructor(public payload?: any) {}
}

export class RequestReleaseNotes implements Action {
  readonly type = REQUEST_RELEASE_NOTES;

  constructor() {}
}

export class RequestReleaseNotesSuccess implements Action {
  readonly type = REQUEST_RELEASE_NOTES_SUCCESS;

  constructor(public payload: any) {}
}

export class RequestReleaseNotesFailure implements Action {
  readonly type = REQUEST_RELEASE_NOTES_FAILURE;

  constructor(public payload?: any) {}
}

export class SendFeedback implements Action {
  readonly type = SEND_FEEDBACK;

  constructor(public payload: any) {}
}

export class SendFeedbackSuccess implements Action {
  readonly type = SEND_FEEDBACK_SUCCESS;

  constructor(public payload: any) {}
}

export class SendFeedbackFailure implements Action {
  readonly type = SEND_FEEDBACK_FAILURE;

  constructor(public payload?: any) {}
}

export class Reset implements Action {
  readonly type = RESET;

  constructor() {}
}

export class AddMessage implements Action {
  readonly type = ADD_MESSAGE;

  constructor(public payload: any) {}
}

export type Actions =
  | AddNotification
  | MarkReadAll
  | MarkReadAllFailure
  | MarkReadAllSuccess
  | MarkReadNotification
  | MarkReadNotificationFailure
  | MarkReadNotificationSuccess
  | RequestNotifications
  | RequestNotificationsSuccess
  | RequestNotificationsFailure
  | RequestReleaseNotes
  | RequestReleaseNotesSuccess
  | RequestReleaseNotesFailure
  | SendFeedback
  | SendFeedbackSuccess
  | SendFeedbackFailure
  | Reset
  | AddMessage;
