import * as recordings from './actions';

export interface State {
  recordings: Array<any>;
}

export const initialState: State = {
  recordings: [],
};

export function reducer(state = initialState, action: recordings.Actions): State {
  switch (action.type) {

    case recordings.REQUEST_RECORDINGS_SUCCESS: {
      const payload = action.payload;
      return {
        ...state,
        recordings: payload ? payload : []
      }
    }

    case recordings.RESET: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}

export const getRecordings = (state: State) => state.recordings;
