import {Component, OnInit, OnDestroy, Input, } from '@angular/core';

@Component({
  selector: "AddressField",
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss'],
})
export class AddressField implements OnInit, OnDestroy  {

  @Input() autoComplete: any;
  @Input() geoSettings: any;
  @Input() error: boolean = false;

  constructor() {}

  ngOnInit(){

  }

  ngAfterViewInit(){
  }


  ngOnDestroy(){
  }

  autoCompleteCallback(selectedData: any) {
    if(this.autoComplete) {
      // We will do some preprocessing ;)
      if(selectedData && selectedData.data) {
        const {address_components} = selectedData.data;
        for (let i = 0; i < address_components.length; i++){
          let c: any = address_components[i];
          if(c.types) {
            if (c.types.indexOf("street_number") >= 0) {
              selectedData.data.street = c.street_number;
            }
            if (c.types.indexOf("route") >= 0) {
              selectedData.data.street = c.long_name;
            }
            if (c.types.indexOf("postal_code") >= 0) {
              selectedData.data.postal_code = c.long_name;
            }
            if (c.types.indexOf("locality") >= 0) {
              selectedData.data.city = c.long_name;
            }
            if (c.types.indexOf("administrative_area_level_2") >= 0) {
              selectedData.data.region = c.long_name;
            }
            if (c.types.indexOf("country") >= 0) {
              selectedData.data.country = c.long_name;
              selectedData.data.country_short = c.short_name;
            }
          }
        }
      }
      this.autoComplete(selectedData);
    }
  }
}
