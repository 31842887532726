import { Component } from '@angular/core';
import { kerberosConfig } from '../environments/environment';
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'App',
  templateUrl: './app.component.html',
})
export class AppComponent{

  public projectName = "";
  public projectTitle = "";

  constructor(private titleService: Title) {
    this.projectName = kerberosConfig.logo;
    this.projectTitle = kerberosConfig.title;
    this.setTitle = this.setTitle.bind(this);
  }

  ngOnInit(){
    if(this.projectTitle !== "") {
      this.setTitle(this.projectTitle)
    }
  }

  public setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }
}
