import {Component, OnInit, OnDestroy, Input} from '@angular/core';
import * as SubscriptionInfo from "../../../subscription.info";

@Component({
  selector: "SampleDetail",
  templateUrl: './sample-detail.component.html',
  styleUrls: ['./sample-detail.component.scss'],
})
export class SampleDetail implements OnInit, OnDestroy  {

  @Input() camera: any;
  @Input() dataset: any;
  @Input() removeDevice: any;
  @Input() openSample: any;
  @Input() cloudKey: any;
  @Input() plan: any;
  @Input() user: any;
  @Input() hideSites: boolean = false;
  @Input() layout: string = 'list';
  public levels = SubscriptionInfo.Levels;
  public showStream: boolean = false;

  constructor() {}

  ngOnInit(){
  }

  ngAfterViewInit(){
  }

  ngOnDestroy(){
  }

}
